import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n      border-color: ",
        ";\n      border-radius: ",
        ";\n\n      &::placeholder {\n        opacity: 0;\n      }\n\n      &:focus + span,\n      &:not(:placeholder-shown) + span {\n        ",
        "\n        color: ",
        ";\n        transform: translate3d(0, ",
        ", 0);\n      }\n    "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n      border-color: ",
        ";\n      border-radius: ",
        ";\n      ",
        "\n\n      &::placeholder {\n        opacity: 0;\n      }\n\n      &:focus {\n        border-color: ",
        ";\n        box-shadow: ",
        " ",
        ";\n      }\n\n      &:focus + span,\n      &:not(:placeholder-shown) + span {\n        ",
        "\n        color: ",
        ";\n        transform: translate3d(0, ",
        ", 0);\n      }\n    "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n      border-color: ",
        ";\n      border-radius: ",
        ";\n      ",
        "\n\n      &::placeholder {\n        color: ",
        ";\n        opacity: 1;\n      }\n\n      &:focus {\n        border-color: ",
        ";\n        box-shadow: ",
        " ",
        ";\n      }\n    "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n      padding-top: ",
        ";\n      padding-bottom: ",
        ";\n    "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n      padding-top: ",
        ";\n      padding-bottom: ",
        ";\n    "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n      padding-top: ",
        ";\n      padding-bottom: ",
        ";\n    "
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      height: ",
        ";\n      padding: ",
        ";\n    "
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      height: ",
        ";\n      padding: ",
        ";\n    "
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      height: ",
        ";\n      padding: ",
        ";\n    "
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      border-radius: ",
        ";\n      color: ",
        ";\n      left: ",
        ";\n      padding: ",
        ";\n      top: ",
        ";\n    "
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      border-radius: ",
        ";\n      color: ",
        ";\n      left: ",
        ";\n      padding: ",
        ";\n      top: ",
        ";\n    "
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      color: ",
        ";\n      background-color: transparent;\n      left: 0;\n      padding: ",
        ";\n      top: ",
        ";\n    "
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  border-width: 1px;\n  border-style: solid;\n  color: ",
        ";\n  outline: 0; // TODO This is a bad practice\n  transition: border-color 0.3s ease, box-shadow 0.3s ease;\n  width: 100%;\n\n  ::-ms-reveal,\n  ::-ms-clear {\n    display: none;\n  }\n\n  &::file-selector-button {\n    display: none;\n  }\n\n  ",
        ";\n  ",
        "\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  appearance: none;\n  border: 1px solid ",
        ";\n  border-radius: ",
        ";\n  display: inline-flex;\n  flex-shrink: 0;\n  height: ",
        ";\n  justify-content: center;\n  padding: 0;\n  transition: background-color 0.3s ease;\n  width: ",
        ";\n\n  &::before {\n    background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 9'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M11 1 4.158 8 1 4.77'/%3E%3C/svg%3E\");\n    content: '';\n    display: block;\n    height: ",
        ";\n    width: ",
        ";\n  }\n\n  &:checked {\n    background-color: ",
        ";\n    border-color: ",
        ";\n  }\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  appearance: none;\n  border: 1px solid ",
        ";\n  border-radius: 50%;\n  display: inline-flex;\n  flex-shrink: 0;\n  height: ",
        ";\n  justify-content: center;\n  padding: 0;\n  transition: background-color 0.3s ease;\n  width: ",
        ";\n\n  &::before {\n    border-color: ",
        ";\n    border-radius: 50%;\n    content: '';\n    display: block;\n    height: ",
        ";\n    transition: background-color 0.3s ease;\n    width: ",
        ";\n  }\n\n  &:checked::before {\n    background-color: ",
        ";\n    border-color: ",
        ";\n  }\n\n  &:checked {\n    border-color: ",
        ";\n  }\n"
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  position: relative;\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
function _templateObject16() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject16 = function _templateObject16() {
        return data;
    };
    return data;
}
function _templateObject17() {
    var data = _tagged_template_literal([
        "\n  display: block;\n  position: relative;\n  width: 100%;\n  z-index: 1;\n"
    ]);
    _templateObject17 = function _templateObject17() {
        return data;
    };
    return data;
}
function _templateObject18() {
    var data = _tagged_template_literal([
        "\n  pointer-events: none;\n  position: absolute;\n  transition: all 0.3s ease;\n  ",
        ";\n  ",
        "\n"
    ]);
    _templateObject18 = function _templateObject18() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { colors, cssprops, getTypography, pxToRem } from "@vfit/shared/themes";
export var inputsVariants = {
    inputOrTextarea: {
        animated: css(_templateObject(), function(param) {
            var error = param.error;
            return !error ? colors.$bebebe : colors.$bd0000;
        }, pxToRem(5), getTypography("body3.regular"), function(param) {
            var disabled = param.disabled;
            return !disabled ? colors.$262626 : colors.$7e7e7e;
        }, pxToRem(-27)),
        "mva:animated": css(_templateObject1(), function(param) {
            var error = param.error;
            return !error ? colors.$bebebe : colors.$bd0000;
        }, pxToRem(6), function(param) {
            var error = param.error;
            return error ? "box-shadow: ".concat(pxToRem(0, 0, 0, 1), " ").concat(colors.$bd0000, ";") : "";
        }, colors.$0096AD, pxToRem(0, 0, 0, 1), colors.$0096AD, getTypography("body3.regular"), function(param) {
            var disabled = param.disabled;
            return !disabled ? colors.$262626 : colors.$7e7e7e;
        }, pxToRem(-19)),
        "mva:static": css(_templateObject2(), function(param) {
            var error = param.error;
            return !error ? colors.$bebebe : colors.$bd0000;
        }, pxToRem(6), function(param) {
            var error = param.error;
            return error ? "box-shadow: ".concat(pxToRem(0, 0, 0, 1), " ").concat(colors.$bd0000, ";") : "";
        }, colors.$7e7e7e, colors.$0096AD, pxToRem(0, 0, 0, 1), colors.$0096AD)
    }
};
var variants = {
    container: {
        animated: css(_templateObject3(), pxToRem(9), pxToRem(24)),
        "mva:animated": css(_templateObject4(), pxToRem(9), pxToRem(27)),
        "mva:static": css(_templateObject5(), pxToRem(36), pxToRem(27))
    },
    input: {
        animated: css(_templateObject6(), inputsVariants.inputOrTextarea.animated, pxToRem(60), function(param) {
            var svg = param.svg;
            return !svg ? pxToRem(18, 20) : pxToRem(18, 56, 18, 20);
        }),
        "mva:animated": css(_templateObject7(), inputsVariants.inputOrTextarea["mva:animated"], pxToRem(44), function(param) {
            var svg = param.svg;
            return !svg ? pxToRem(9, 15) : pxToRem(9, 54, 9, 15);
        }),
        "mva:static": css(_templateObject8(), inputsVariants.inputOrTextarea["mva:static"], pxToRem(44), function(param) {
            var svg = param.svg;
            return !svg ? pxToRem(9, 15) : pxToRem(9, 54, 9, 15);
        })
    },
    labelPlaceholder: {
        animated: css(_templateObject9(), getTypography("body1.regular"), pxToRem(5), colors.$7e7e7e, pxToRem(13), pxToRem(0, 8), pxToRem(18)),
        "mva:animated": css(_templateObject10(), getTypography("body1.regular"), pxToRem(6), colors.$7e7e7e, pxToRem(12), pxToRem(0, 4), pxToRem(10)),
        "mva:static": css(_templateObject11(), getTypography("body1.regular"), function(param) {
            var disabled = param.disabled;
            return !disabled ? colors.$262626 : colors.$7e7e7e;
        }, pxToRem(2, 0), pxToRem(-36))
    }
};
export var input = css(_templateObject12(), getTypography("body1.regular"), function(param) {
    var disabled = param.disabled;
    return !disabled ? colors.$262626 : colors.$7e7e7e;
}, function(param) {
    var bg = param.bg;
    return bg ? "background-color: ".concat(bg, ";") : "";
}, function(param) {
    var variant = param.variant;
    return variant ? variants.input[variant] : "";
});
export var checkbox = css(_templateObject13(), colors.$bebebe, pxToRem(3), pxToRem(20), pxToRem(20), pxToRem(9), pxToRem(12), colors.$00697c, colors.$00697c);
export var radio = css(_templateObject14(), colors.$bebebe, pxToRem(20), pxToRem(20), colors.$00697c, pxToRem(12), pxToRem(12), colors.$00697c, colors.$00697c, colors.$00697c);
export var container = css(_templateObject15(), function(param) {
    var hidden = param.hidden;
    return hidden ? "visibility: hidden;" : "";
}, function(param) {
    var variant = param.variant;
    return variant ? variants.container[variant] : "";
}, cssprops.styled);
export var Container = styled.div.withConfig({
    componentId: "sc-db17008c-0"
})(_templateObject16(), container);
export var Label = styled.label.withConfig({
    componentId: "sc-db17008c-1"
})(_templateObject17());
export var LabelPlaceholder = styled.span.withConfig({
    componentId: "sc-db17008c-2"
})(_templateObject18(), function(param) {
    var bg = param.bg;
    return bg ? "background-color: ".concat(bg, ";") : "";
}, function(param) {
    var variant = param.variant;
    return variant ? variants.labelPlaceholder[variant] : "";
});
