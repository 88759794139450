import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n  margin-top: 0;\n  margin-bottom: 64px;\n\n  img {\n    max-width: ",
        ";\n    max-height: 150px;\n    ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 80px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 120px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var ImageDividerContainer = styled.div.withConfig({
    componentId: "sc-84ab9b8d-0"
})(_templateObject(), function(param) {
    var enableMaxWidth = param.enableMaxWidth;
    return enableMaxWidth ? "100%" : "inherit";
}, function(param) {
    var enableMaxWidth = param.enableMaxWidth;
    return enableMaxWidth ? "object-fit: contain !important" : "";
}, breakpoints.tablet, breakpoints.desktop);
