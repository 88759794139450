import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n\n  .all-thumbnail-swiper .swiper-slide {\n    border: 1px solid ",
        ";\n  }\n\n  .all-thumbnail-swiper .swiper-slide-thumb-active {\n    border: 1px solid ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n  }\n\n  @media (min-width: ",
        ") {\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { breakpoints, colors } from "@vfit/shared/themes";
import styled from "styled-components";
export var ThumbnailSlider = styled.div.withConfig({
    componentId: "sc-df2381e1-0"
})(_templateObject(), colors.$bebebe, colors.$0096AD, breakpoints.tablet, breakpoints.desktop);
