import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
export var createSyntheticEvent = function(el, type) {
    if (el) {
        // Workaround for react-hook-form getValues and watch
        var nativeEvent = new InputEvent(type === "change" ? "input" : type, {
            bubbles: true,
            cancelable: false,
            composed: true
        });
        el.dispatchEvent(nativeEvent);
        /**
     * This defaultPrevented code is directly copied from reactDOM sourcecode:
     * The using of returnValue (deprecated) is thinked to handle old browsers
     */ var defaultPrevented = nativeEvent.defaultPrevented != null ? nativeEvent.defaultPrevented : nativeEvent.returnValue === false;
        return _object_spread_props(_object_spread({}, nativeEvent), {
            currentTarget: nativeEvent.target,
            isDefaultPrevented: defaultPrevented ? function() {
                return true;
            } : function() {
                return false;
            },
            isPropagationStopped: function() {
                return false;
            },
            nativeEvent: _object_spread_props(_object_spread({}, nativeEvent), {
                relatedTarget: null
            }),
            persist: function() {},
            relatedTarget: null,
            target: nativeEvent.target,
            type: type,
            _reactName: "on".concat(type.slice(0, 1).toUpperCase()).concat(type.slice(1)),
            _targetInst: null
        });
    }
    return null;
};
