import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useEffect, useState } from "react";
export function useIntersectionObserver(elementRef, param) {
    var _threshold = param.threshold, threshold = _threshold === void 0 ? 0 : _threshold, _root = param.root, root = _root === void 0 ? null : _root, _rootMargin = param.rootMargin, rootMargin = _rootMargin === void 0 ? "0%" : _rootMargin, _freezeOnceVisible = param.freezeOnceVisible, freezeOnceVisible = _freezeOnceVisible === void 0 ? false : _freezeOnceVisible;
    var ref = useState(), entry = ref[0], setEntry = ref[1];
    var frozen = (entry === null || entry === void 0 ? void 0 : entry.isIntersecting) && freezeOnceVisible;
    var updateEntry = function(param) {
        var _param = _sliced_to_array(param, 1), ent = _param[0];
        setEntry(ent);
    };
    useEffect(function() {
        var node = elementRef === null || elementRef === void 0 ? void 0 : elementRef.current; // DOM Ref
        var hasIOSupport = !!window.IntersectionObserver;
        if (!hasIOSupport || frozen || !node) return null;
        var observerParams = {
            threshold: threshold,
            root: root,
            rootMargin: rootMargin
        };
        var observer = new IntersectionObserver(updateEntry, observerParams);
        observer.observe(node);
        // eslint-disable-next-line consistent-return
        return function() {
            return observer.disconnect();
        };
    }, [
        elementRef,
        JSON.stringify(threshold),
        root,
        rootMargin,
        frozen
    ]);
    return entry;
}
