import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  margin-top: 0;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    font-family: ",
        ";\n    font-weight: 400;\n    text-align: left;\n\n    @media (min-width: ",
        ") {\n      ",
        "\n      margin-bottom: 32px;\n    }\n\n    @media (min-width: ",
        ") {\n      margin-bottom: 24px;\n    }\n\n    @media (min-width: ",
        ") {\n      ",
        "\n      margin-bottom: 32px;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    font-weight: 700;\n    font-family: ",
        ";\n    text-align: left;\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  img {\n    width: 100% !important;\n    height: 150px !important;\n    margin: 16px 0 !important;\n    border-radius: 15px !important;\n    object-fit: contain !important;\n  }\n\n  @media (min-width: ",
        ") {\n    img {\n      margin: 16px 0 !important;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    img {\n      margin: 32px 0 !important;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n\n    img {\n      display: none;\n    }\n\n    ul {\n      margin: 0;\n      padding-left: 24px;\n    }\n\n    li {\n      margin: 0;\n    }\n\n    p {\n      margin: 0;\n    }\n\n    font-weight: 400;\n    font-family: ",
        ";\n    text-align: left;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  padding: 0;\n  max-height: 85vh;\n\n  @media (min-width: ",
        ") {\n    max-height: 50vh;\n    margin-left: 0;\n    margin-right: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-left: 0;\n    margin-right: 0;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin: 24px 0;\n\n  @media (min-width: ",
        ") {\n    margin: 47px 0;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, pxToCssFont } from "@vfit/shared/themes";
export var DetailsContainer = styled.div.withConfig({
    componentId: "sc-92f7d84b-0"
})(_templateObject());
export var Title = styled.div.withConfig({
    componentId: "sc-92f7d84b-1"
})(_templateObject1(), fonts.exbold, breakpoints.mobile, pxToCssFont(30, 38), breakpoints.tablet, breakpoints.desktop, pxToCssFont(36, 45));
export var P = styled.div.withConfig({
    componentId: "sc-92f7d84b-2"
})(_templateObject2(), fonts.regular, breakpoints.mobile, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30));
export var Img = styled.div.withConfig({
    componentId: "sc-92f7d84b-3"
})(_templateObject3(), breakpoints.mobile, breakpoints.tablet);
export var Description = styled.div.withConfig({
    componentId: "sc-92f7d84b-4"
})(_templateObject4(), fonts.regular, pxToCssFont(16, 22), breakpoints.mobile, pxToCssFont(18, 22));
export var Content = styled.div.withConfig({
    componentId: "sc-92f7d84b-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.desktop);
export var AccordionWrap = styled.div.withConfig({
    componentId: "sc-92f7d84b-6"
})(_templateObject6(), breakpoints.desktop);
