import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  from {\n    opacity: 0;\n    transform: translate3d(0, -100%, 0);\n  }\n\n  to {\n    opacity: 1;\n    transform: translate3d(0, 0, 0);\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { keyframes } from "styled-components";
/**
 * Extract from https://github.com/animate-css/animate.css/blob/main/animate.css
 * Version - 4.1.1
 */ export var fadeInDown = keyframes(_templateObject());
