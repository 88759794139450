/**
 * Round number with a specific number of decimals
 * Prevent by arithmetic errors
 * @param n number
 * @param decimals number
 * @returns
 * Example: 1.525 - 1.5 -> 0.02499999999999991
 * but mathRound(1.525 - 1.5) -> 0.025
 */ export var mathRound = function(n) {
    var decimals = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 6;
    return Number(n.toFixed(decimals));
};
