import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { fontSizes, lineHeights } from "../sizes";
import { mathRound } from "./mathRound";
/**
 * Convert pixel to rem as a string with suffix
 * @param px number(pixel) or string
 * @param rootFontSize (optional, default = 16)
 * @returns string
 *
 * example: _pxToRem(16) -> '1rem'
 */ var _pxToRem = function(px) {
    var rootFontSize = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 16;
    return typeof px === "number" ? "".concat(px ? "".concat(mathRound(px / rootFontSize), "rem") : 0) : px;
};
/**
 * Convert any values of pixel to rem as a string with suffix
 * assuming root font size as 16px
 * @param px one or more number(pixel) or string
 * @returns string
 *
 * example: pxToRem(16, 'auto', 22) -> '1rem auto 1.375rem'
 */ export var pxToRem = function() {
    for(var _len = arguments.length, px = new Array(_len), _key = 0; _key < _len; _key++){
        px[_key] = arguments[_key];
    }
    return px.map(function(p) {
        return _pxToRem(p);
    }).join(" ");
};
/**
 * Convert any values of pixel to rem as a string with suffix
 * assuming root font size as 16px
 * @param px array of numbers(pixel) or string
 * @returns string
 *
 * example: pxToRemJoin([16, 22], ',') -> '1rem, 1.375rem'
 */ export var pxToRemJoin = function(px, separator) {
    return px.map(function(p) {
        return _pxToRem(p);
    }).join(separator);
};
/**
 * Normalize a numeric value to the closer value allowed in an object of numbers
 * @param value
 * @param obj
 * @returns
 */ var normalize = function(value, obj) {
    if (value === 0) {
        return 0;
    }
    var sortedValues = _to_consumable_array(Object.values(obj)).sort(function(a, b) {
        return a - b;
    });
    if (value <= sortedValues[0]) {
        return sortedValues[0];
    }
    if (value >= sortedValues[sortedValues.length - 1]) {
        return sortedValues[sortedValues.length - 1];
    }
    var index = 0;
    while(index < sortedValues.length - 1 && value > sortedValues[index]){
        if (value > sortedValues[index + 1]) {
            // eslint-disable-next-line no-plusplus
            index++;
            continue;
        }
        break;
    }
    return mathRound(value - sortedValues[index]) < mathRound(sortedValues[index + 1] - value) ? sortedValues[index] : sortedValues[index + 1];
};
/**
 * Normalize a font size (in pixel) to the closer value
 * allowed in the theme's fontSizes
 * @param fontSize (pixel)
 * @returns
 * Example: normalizeFontSize(23) -> 24
 */ export var normalizeFontSize = function(fontSize) {
    return normalize(fontSize, fontSizes);
};
/**
 * Normalize a line height to the closer value
 * allowed in the theme's lineHeights
 * @param lineHeight (ratio)
 * @returns
 * Example: normalizeLineHeight(1.23) -> 1.25
 */ export var normalizeLineHeight = function(lineHeight) {
    return normalize(lineHeight, lineHeights);
};
/**
 * Compute and normalize a font-size (to rem)
 * @param fontSize (pixel)
 * @returns
 *
 * example: pxToFontSize(16.7) -> '1rem'
 */ export var pxToFontSize = function(fontSize) {
    return pxToRem(normalizeFontSize(fontSize));
};
/**
 * Compute and normalize a font size (to rem) and create the CSS rule in rem
 * @param fontSize (pixel)
 * @returns
 * Example: pxToCssFontSize(16) -> 'font-size: 1rem;'
 */ export var pxToCssFontSize = function(fontSize) {
    return "font-size: ".concat(pxToFontSize(fontSize), ";");
};
/**
 * Compute line-height (ratio)
 * @param fontSize (pixel)
 * @param lineHeight (pixel)
 * @returns
 *
 * example: _pxToLineHeight(16, 22) -> 1.375
 */ var _pxToLineHeight = function(fontSize, lineHeight) {
    return mathRound(lineHeight / fontSize);
};
/**
 * Compute and normalize line-height (ratio)
 * @param fontSize (pixel)
 * @param lineHeight (pixel)
 * @returns
 *
 * example: pxToLineHeight(16, 22) -> 1.4
 */ export var pxToLineHeight = function(fontSize, lineHeight) {
    return normalizeLineHeight(_pxToLineHeight(normalize(fontSize, fontSizes), lineHeight));
};
/**
 * Compute and normalize line-height (rem)
 * @param fontSize (pixel)
 * @param lineHeight (pixel)
 * @returns
 *
 * example: pxToLineHeightRem(18, 22) -> 1.4rem
 */ export var pxToLineHeightRem = function(fontSize, lineHeight) {
    return "".concat(pxToRem(mathRound(fontSize * pxToLineHeight(fontSize, lineHeight))));
};
/**
 * Convert a line height from pixel to ratio,
 * then normalize it and create the CSS rule
 * @param fontSize (pixel)
 * @param lineHeight (pixel)
 * @returns
 * Example: pxToCssLineHeight(16, 22) -> 'line-height: 1.4;'
 */ export var pxToCssLineHeight = function(fontSize, lineHeight) {
    return "line-height: ".concat(pxToLineHeight(fontSize, lineHeight), ";");
};
/**
 * Compute CSS rules for font-size (rem) and line-height (ratio)
 * @param fontSize (pixel)
 * @param lineHeight (pixel)
 * @returns
 *
 * example: pxToCssFont(16, 22) -> `font-size: 1rem; line-height: 1.4;`
 */ export var pxToCssFont = function(fontSize, lineHeight) {
    return "\n  ".concat(pxToCssFontSize(fontSize), "\n  ").concat(pxToCssLineHeight(fontSize, lineHeight), "\n");
};
