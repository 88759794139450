import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n  ",
        ";\n  border-radius: 20px;\n  padding: 4px 10px;\n  ",
        ";\n\n  span {\n    font-family: ",
        ";\n    font-weight: 700;\n    ",
        "\n    text-transform: uppercase;\n    color: ",
        ";\n    letter-spacing: 0.5px;\n    white-space: nowrap;\n    ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 6px 12px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var PillContainer = styled.div.withConfig({
    componentId: "sc-dbdddee1-0"
})(_templateObject(), function(param) {
    var pillsColor = param.pillsColor;
    return "background: ".concat(pillsColor || colors.$ffffff);
}, function(param) {
    var opacity = param.opacity;
    return "opacity: ".concat(opacity);
}, function(param) {
    var borderColor = param.borderColor;
    return "border: ".concat(borderColor ? "1px solid ".concat(borderColor) : "none");
}, fonts.regular, pxToCssFont(12, 16), colors.$0d0d0d, function(param) {
    var pillsColor = param.pillsColor;
    return "color: ".concat(pillsColor === colors.$262626 ? colors.$ffffff : colors.$262626);
}, breakpoints.desktop);
