import { IFrameService } from "./lib/iframeManager";
import { ToastService } from "./lib/toastManager";
import { ILeadModalService } from "./lib/leadModalManager";
import { IVolaModalService } from "./lib/volaModalManager";
import { IHtmlModalService } from "./lib/htmlModalManager";
import { IDeactivationModalService } from "./lib/deactivationModalmanager";
import { handleEnv } from "./lib/handleEnv";
import { LoggerInstance } from "./lib/logger";
import { cmsClient, HttpClient, nextClient, serverlessClient, fwvClient, awsConsumerClient, headersNext, prefixUrl, mode, credentials, dxlClientJourneyApp, dxlClient, dxlGatewayCloud } from "./lib/fetcher";
import { DxlError, errorMessages, delay, NEXT_ERR_MSG, NEXT_ERR_TRANSACTION_ID } from "./lib/fetcher/utils";
import { ModalIframeWrapper } from "./lib/iframeManager/context";
import { ToastWrapper } from "./lib/toastManager/context";
import { ModalLeadWrapper } from "./lib/leadModalManager/context";
import { ModalDeactivationWrapper } from "./lib/deactivationModalmanager/context";
import { ModalVolaWrapper } from "./lib/volaModalManager/context";
import { ModalHtmlWrapper } from "./lib/htmlModalManager/context";
import { handleUseQuery, handleUseQueryes, resetData } from "./lib/ReactQuery";
import QueryProvider from "./lib/query";
import OneTrustCookieConsents from "./lib/tracking/oneTrustCookieConsents";
import { ErrorService } from "./lib/errorManager";
import { ModalErrorWrapper } from "./lib/errorManager/context";
import { TrackingContextProvider, useTrackingProvider } from "./lib/tracking/provider";
export var iFrameManager = new IFrameService();
export var toastManager = new ToastService();
export var leadModalManager = new ILeadModalService();
export var volaModalManager = new IVolaModalService();
export var errorManager = new ErrorService();
export var deactivationModalmanager = new IDeactivationModalService();
export var htmlModalManager = new IHtmlModalService();
/** tracking folder */ export { default as useTracking } from "./lib/tracking/use-tracking";
export { tracking } from "./lib/tracking/use-tracking";
export * from "./lib/tracking/models";
export * from "./lib/tracking/utils";
export * from "./lib/contexts/tabsContext";
export default QueryProvider;
export { TrackingContextProvider, LoggerInstance, cmsClient, nextClient, dxlClientJourneyApp, dxlClient, dxlGatewayCloud, serverlessClient, fwvClient, awsConsumerClient, HttpClient, handleEnv, ToastWrapper, ModalIframeWrapper, ModalLeadWrapper, ModalDeactivationWrapper, ModalVolaWrapper, ModalHtmlWrapper, headersNext, prefixUrl, mode, credentials, ModalErrorWrapper, ErrorService, handleUseQuery, handleUseQueryes, resetData, OneTrustCookieConsents, DxlError, errorMessages, delay, NEXT_ERR_MSG, NEXT_ERR_TRANSACTION_ID, useTrackingProvider };
