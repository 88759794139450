import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n\n  ",
        "\n  ",
        "\n  ",
        "\n  background-color: ",
        ";\n  height: ",
        ";\n  width: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors } from "@vfit/shared/themes";
export var Divider = styled.div.withConfig({
    componentId: "sc-333f6351-0"
})(_templateObject(), function(props) {
    return props.marginBottom && "margin: 0 0 ".concat(props.marginBottom, "rem 0;");
}, function(param) {
    var space = param.space, vertical = param.vertical;
    return space && !vertical && "margin: ".concat(space, "rem 0;");
}, function(param) {
    var space = param.space, vertical = param.vertical;
    return space && vertical && "margin: 0 ".concat(space, "rem;");
}, function(props) {
    return props.isHidden ? "display: none;" : "";
}, function(props) {
    return props.color ? colors[props.color] : "";
}, function(props) {
    return props.size && !props.vertical ? "".concat(props.size, "px") : "";
}, function(props) {
    return props.size && props.vertical ? "".concat(props.size, "px") : "";
});
export default Divider;
