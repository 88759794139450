import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  background-color: ",
        ";\n  min-height: 60px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  font-family: ",
        ";\n  ",
        ";\n  color: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-fdb9ae13-0"
})(_templateObject(), function(param) {
    var color = param.color;
    return color || colors.$00697c;
}, fonts.regular, pxToCssFont(20, 28), function(param) {
    var textColor = param.textColor;
    return textColor || colors.$ffffff;
});
