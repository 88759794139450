import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: ",
        ";\n\n  @media (max-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin-bottom: ",
        "px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { breakpoints, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var Wrapper = styled.div.withConfig({
    componentId: "sc-4b93b97c-0"
})(_templateObject(), function(param) {
    var margins = param.margins;
    return margins != undefined ? pxToRem.apply(void 0, _to_consumable_array(margins)) : "0";
}, breakpoints.tablet, function(param) {
    var tabletMargins = param.tabletMargins;
    return tabletMargins != undefined ? "margin: ".concat(pxToRem.apply(void 0, _to_consumable_array(tabletMargins))) : null;
});
export var Container = styled.div.withConfig({
    componentId: "sc-4b93b97c-1"
})(_templateObject1(), function(param) {
    var itemsDistance = param.itemsDistance;
    return itemsDistance != undefined ? "".concat(itemsDistance) : "35";
});
