import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  cursor: pointer;\n  ",
        "\n\n  &:disabled {\n    ",
        "\n  }\n\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { cssprops } from "@vfit/shared/themes";
import * as S from "../../button.style";
export var Button = styled.button.withConfig({
    componentId: "sc-e5a9d0c6-0"
})(_templateObject(), S.common, S.disabled, cssprops.styled);
