import _object_spread from "@swc/helpers/src/_object_spread.mjs";
// export const errorMock = (
//   apiCall: string,
//   error?: INextError,
//   errorType?: string,
//   errorCode?: string,
//   errorMessage?: string
// ): ITrackError => ({
//   error: {
//     url:
//       error?.url?.substring(error.url.lastIndexOf('/') + 1, error?.url?.length)?.split('?')?.[0] ||
//       apiCall ||
//       '',
//     status: error?.status || 'timeout',
//     statusText: error?.statusText || 'timeout',
//   },
//   errorMessage: errorMessage || `Error occurred in ${apiCall} API service`,
//   errorType,
//   errorCode,
// });
export var errorMock = function(apiCall, error, errorType, errorCode, errorMessage, isBlocking, otherInfo) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15;
    return _object_spread({
        error: {
            url: ((ref3 = (ref2 = error === null || error === void 0 ? void 0 : (ref = error.url) === null || ref === void 0 ? void 0 : ref.substring(error.url.lastIndexOf("/") + 1, error === null || error === void 0 ? void 0 : (ref1 = error.url) === null || ref1 === void 0 ? void 0 : ref1.length)) === null || ref2 === void 0 ? void 0 : ref2.split("?")) === null || ref3 === void 0 ? void 0 : ref3[0]) || apiCall || "",
            status: (error === null || error === void 0 ? void 0 : error.status) || "timeout",
            statusText: (error === null || error === void 0 ? void 0 : error.statusText) || "timeout"
        },
        errorMessage: errorMessage || "Error occurred in ".concat(apiCall, " API service"),
        errorType: errorType,
        errorCode: errorCode
    }, (error === null || error === void 0 ? void 0 : (ref4 = error.headers) === null || ref4 === void 0 ? void 0 : ref4.get("dex-transaction-id")) && {
        transaction_id: (error === null || error === void 0 ? void 0 : (ref5 = error.headers) === null || ref5 === void 0 ? void 0 : ref5.get("dex-transaction-id")) || ""
    }, (error === null || error === void 0 ? void 0 : (ref6 = error.headers) === null || ref6 === void 0 ? void 0 : ref6.get("err-backenderrorcode")) && {
        err_backendErrorCode: (error === null || error === void 0 ? void 0 : (ref7 = error.headers) === null || ref7 === void 0 ? void 0 : ref7.get("err-backenderrorcode")) || ""
    }, (error === null || error === void 0 ? void 0 : (ref8 = error.headers) === null || ref8 === void 0 ? void 0 : ref8.get("err-backenderrormessage")) && {
        err_backendErrorMessage: (error === null || error === void 0 ? void 0 : (ref9 = error.headers) === null || ref9 === void 0 ? void 0 : ref9.get("err-backenderrormessage")) || ""
    }, (error === null || error === void 0 ? void 0 : (ref10 = error.headers) === null || ref10 === void 0 ? void 0 : ref10.get("err-backendsource")) && {
        err_backendSource: (error === null || error === void 0 ? void 0 : (ref11 = error.headers) === null || ref11 === void 0 ? void 0 : ref11.get("err-backendsource")) || ""
    }, (error === null || error === void 0 ? void 0 : (ref12 = error.headers) === null || ref12 === void 0 ? void 0 : ref12.get("err-errorcode")) && {
        err_errorCode: (error === null || error === void 0 ? void 0 : (ref13 = error.headers) === null || ref13 === void 0 ? void 0 : ref13.get("err-errorcode")) || ""
    }, (error === null || error === void 0 ? void 0 : (ref14 = error.headers) === null || ref14 === void 0 ? void 0 : ref14.get("err-errormessage")) && {
        err_errorMessage: (error === null || error === void 0 ? void 0 : (ref15 = error.headers) === null || ref15 === void 0 ? void 0 : ref15.get("err-errormessage")) || ""
    }, isBlocking && {
        isBlocking: isBlocking
    }, otherInfo && {
        otherInfo: otherInfo
    });
};
