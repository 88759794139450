import * as yup from "yup";
export var schema = function(cmsConfig) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14;
    return yup.object({
        city: yup.string().min(1, (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref = cmsConfig.city) === null || ref === void 0 ? void 0 : ref.min) || "Inserire una via valida").matches(/^[a-zA-Z'àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ.\s-]*$/g, (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref1 = cmsConfig.city) === null || ref1 === void 0 ? void 0 : ref1.matchRegex) || "Inserire una via valida").required((cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref2 = cmsConfig.city) === null || ref2 === void 0 ? void 0 : ref2.required) || "Tutti i campi sono obbligatori"),
        postalCode: yup.string().min(1, (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref3 = cmsConfig.postalCode) === null || ref3 === void 0 ? void 0 : ref3.min) || "Inserire un codice postale valido").matches(/^\d{5}$/, (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref4 = cmsConfig.postalCode) === null || ref4 === void 0 ? void 0 : ref4.matchRegex) || "Inserire un codice postale valido").required((cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref5 = cmsConfig.postalCode) === null || ref5 === void 0 ? void 0 : ref5.required) || "Tutti i campi sono obbligatori"),
        stateOrProvince: yup.string().min(1, (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref6 = cmsConfig.stateOrProvince) === null || ref6 === void 0 ? void 0 : ref6.min) || "Inserire una regione valida").matches(/^[a-zA-Z'àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ\s-]*$/g, (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref7 = cmsConfig.stateOrProvince) === null || ref7 === void 0 ? void 0 : ref7.matchRegex) || "Inserire una regione valida").required((cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref8 = cmsConfig.stateOrProvince) === null || ref8 === void 0 ? void 0 : ref8.required) || "Tutti i campi sono obbligatori"),
        street: yup.string().min(1, (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref9 = cmsConfig.stateOrProvince) === null || ref9 === void 0 ? void 0 : ref9.min) || "").test("rangeTest", (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref10 = cmsConfig.street) === null || ref10 === void 0 ? void 0 : ref10.matchRegex) || " ", function(value) {
            return !(value === undefined || value.trim().length === 0);
        }).matches(/^[a-zA-Z'àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ.\s-]*$/g, (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref11 = cmsConfig.street) === null || ref11 === void 0 ? void 0 : ref11.matchRegex) || "").required((cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref12 = cmsConfig.street) === null || ref12 === void 0 ? void 0 : ref12.required) || "Tutti i campi sono obbligatori"),
        streetNumber: yup.string().test("rangeTest", (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref13 = cmsConfig.streetNumber) === null || ref13 === void 0 ? void 0 : ref13.matchRegex) || " ", function(value) {
            if (value === null || value === void 0 ? void 0 : value.match(/^[1-9]([0-9]{0,5})(\/?)[a-zA-Z]?$/)) {
                return true;
            }
            return false;
        }).required((cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref14 = cmsConfig.streetNumber) === null || ref14 === void 0 ? void 0 : ref14.required) || "Tutti i campi sono obbligatori")
    }).required();
};
