import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n      border-radius: ",
        ";\n      padding: ",
        ";\n    "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n      border-radius: ",
        ";\n      padding: ",
        ";\n    "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n      border-radius: ",
        ";\n      padding: ",
        ";\n    "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n      margin-right: ",
        ";\n    "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n      margin-right: ",
        ";\n    "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n      margin-right: ",
        ";\n    "
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  border: 1px solid ",
        ";\n  cursor: ",
        ";\n  display: flex;\n  ",
        "\n\n  &.checked {\n    border-color: ",
        ";\n    box-shadow: 0 0 0 1px ",
        ";\n  }\n\n  ",
        "\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n  cursor: ",
        ";\n  ",
        "\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { colors, cssprops, pxToRem } from "@vfit/shared/themes";
import { checkbox, radio } from "../../inputs.style";
var variants = {
    label: {
        primary: css(_templateObject(), pxToRem(6), pxToRem(20, 16)),
        secondary: css(_templateObject1(), pxToRem(10), pxToRem(16, 24)),
        tertiary: css(_templateObject2(), pxToRem(20), pxToRem(16, 24))
    },
    input: {
        primary: css(_templateObject3(), pxToRem(8)),
        secondary: css(_templateObject4(), pxToRem(24)),
        tertiary: css(_templateObject5(), pxToRem(24))
    }
};
export var Label = styled.label.withConfig({
    componentId: "sc-81b47d9f-0"
})(_templateObject6(), colors.$bebebe, function(param) {
    var disabled = param.disabled;
    return !disabled ? "pointer" : "not-allowed";
}, function(param) {
    var variant = param.variant;
    return variant ? variants.label[variant] : "";
}, colors.$00697c, colors.$00697c, cssprops.styled);
export var Input = styled.input.withConfig({
    componentId: "sc-81b47d9f-1"
})(_templateObject7(), function(param) {
    var type = param.type;
    return type === "radio" ? radio : checkbox;
}, function(param) {
    var control = param.control;
    return control ? "" : "display: none;";
}, function(param) {
    var disabled = param.disabled;
    return !disabled ? "pointer" : "not-allowed";
}, function(param) {
    var variant = param.variant;
    return variant ? variants.input[variant] : "";
});
