import { checkWindow } from "@vfit/shared/data-access";
var DEFAULT_HEIGHT = {
    tablet: "608.5px",
    desktop: "633.5px"
};
var updateVisibility = function(index) {
    if (!checkWindow()) return;
    var visibilityFunction = function() {
        var stepperElements = document === null || document === void 0 ? void 0 : document.getElementsByClassName("stepper-children");
        if ((stepperElements === null || stepperElements === void 0 ? void 0 : stepperElements.length) > 0) {
            Array.from(stepperElements).forEach(function(el) {
                if (el) {
                    // eslint-disable-next-line no-param-reassign
                    el.style.display = "none";
                }
            });
            stepperElements[index].style.display = "block";
        }
    };
    setTimeout(function() {
        visibilityFunction();
    }, 0);
};
export { DEFAULT_HEIGHT, updateVisibility };
