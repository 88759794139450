import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n\n  div {\n    svg {\n      width: 20px;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var LoadingContent = styled.div.withConfig({
    componentId: "sc-93383785-0"
})(_templateObject());
