import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import * as Yup from "yup";
import { IActionStyle } from "@vfit/shared/models";
import { errorManager, ErrorService } from "@vfit/shared/providers";
import { getButtonActionByActionType } from "./actions";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var MOCK_FORM = {
    config: {
        textAlign: "left",
        title: "Vuoi maggiori <b>informazioni?</b>",
        description: "<b>Lascia</b> i tuoi dati e ti contatteremo al pi\xf9 presto.",
        footer: '<p>Autorizzo Vodafone al contatto telefonico richiesto. <a href="https://www.vodafone.it/portal/Privati/Area-Privacy/La-nostra-informativa" target="_blank">Informativa Privacy</a></p>'
    },
    submit: {
        label: "Ti chiamiamo gratis",
        variant: IActionStyle.SECONDARY,
        url: "https://www.vodafone-api.it/lead_innovation/api/saveLeadSito.php",
        splitHiddenValues: false,
        bodyServiceInputKey: "",
        method: "POST",
        headers: [],
        success: {
            title: "Ok",
            message: "Tutto ok, nessun problema",
            action: {
                title: "Chiudi"
            }
        },
        error: {
            title: "Ops",
            message: "Si \xe8 verificato un problema",
            action: {
                title: "Riprova"
            }
        }
    },
    forms: [
        {
            label: "Inserisci il source",
            type: "text",
            name: "source",
            value: "source value",
            isColMiddle: false,
            isHidden: true,
            validations: []
        },
        {
            label: "Inserisci la partita iva",
            type: "text",
            name: "piva",
            value: "",
            isColMiddle: false,
            validations: [
                {
                    type: "minLength",
                    value: 3,
                    message: "Min. 3 caratteri"
                },
                {
                    type: "maxLength",
                    value: 9,
                    message: "Max 9 caratteri"
                },
                {
                    type: "required",
                    message: "La partita iva \xe8 obbligatoria"
                }, 
            ]
        },
        {
            label: "Azienda",
            type: "text",
            name: "azienda",
            value: "",
            isColMiddle: false,
            validations: [
                {
                    type: "minLength",
                    value: 3,
                    message: "Min. 3 caratteri"
                },
                {
                    type: "maxLength",
                    value: 9,
                    message: "Max 9 caratteri"
                },
                {
                    type: "required",
                    message: "L'azienda \xe8 obbligatoria"
                }, 
            ]
        },
        {
            label: "Data",
            type: "date",
            name: "Data di nascita",
            // format is aaaa-mm-dd
            value: "2018-07-22",
            minDate: "2018-01-01",
            maxDate: "2018-12-31",
            isColMiddle: false,
            validations: [
                {
                    type: "required",
                    message: "L'azienda \xe8 obbligatoria"
                }, 
            ]
        },
        {
            type: "select",
            name: "Provincia",
            label: "--- Seleziona provincia ---",
            value: "",
            isColMiddle: true,
            options: [
                {
                    value: "1",
                    desc: "Ancona"
                },
                {
                    value: "2",
                    desc: "Macerata"
                },
                {
                    value: "3",
                    desc: "Pesaro Urbino"
                },
                {
                    value: "4",
                    desc: "Ascoli Piceno"
                },
                {
                    value: "5",
                    desc: "Alessandria"
                },
                {
                    value: "6",
                    desc: "Asti"
                },
                {
                    value: "7",
                    desc: "Biella"
                },
                {
                    value: "8",
                    desc: "Cuneo"
                },
                {
                    value: "9",
                    desc: "Novara"
                },
                {
                    value: "10",
                    desc: "Vercelli"
                },
                {
                    value: "11",
                    desc: "Torino"
                },
                {
                    value: "12",
                    desc: "Agrigento"
                },
                {
                    value: "13",
                    desc: "Caltanissetta"
                },
                {
                    value: "14",
                    desc: "Catania"
                },
                {
                    value: "15",
                    desc: "Enna"
                },
                {
                    value: "16",
                    desc: "Messina"
                },
                {
                    value: "17",
                    desc: "Palermo"
                },
                {
                    value: "18",
                    desc: "Ragusa"
                },
                {
                    value: "19",
                    desc: "Siracusa"
                },
                {
                    value: "20",
                    desc: "Trapani"
                },
                {
                    value: "21",
                    desc: "Catanzaro"
                },
                {
                    value: "22",
                    desc: "Cosenza"
                },
                {
                    value: "23",
                    desc: "Crotone"
                },
                {
                    value: "24",
                    desc: "Reggio Calabria"
                },
                {
                    value: "25",
                    desc: "Vibo Valentia"
                },
                {
                    value: "27",
                    desc: "Matera"
                },
                {
                    value: "28",
                    desc: "Potenza"
                },
                {
                    value: "29",
                    desc: "Bari"
                },
                {
                    value: "30",
                    desc: "Brindisi"
                },
                {
                    value: "31",
                    desc: "Foggia"
                },
                {
                    value: "32",
                    desc: "Lecce"
                },
                {
                    value: "33",
                    desc: "Taranto"
                },
                {
                    value: "34",
                    desc: "Avellino"
                },
                {
                    value: "35",
                    desc: "Benevento"
                },
                {
                    value: "36",
                    desc: "Caserta"
                },
                {
                    value: "37",
                    desc: "Napoli"
                },
                {
                    value: "38",
                    desc: "Salerno"
                },
                {
                    value: "39",
                    desc: "Frosinone"
                },
                {
                    value: "40",
                    desc: "Latina"
                },
                {
                    value: "41",
                    desc: "Rieti"
                },
                {
                    value: "42",
                    desc: "Roma"
                },
                {
                    value: "43",
                    desc: "Viterbo"
                },
                {
                    value: "44",
                    desc: "Chieti"
                },
                {
                    value: "45",
                    desc: "L'Aquila"
                },
                {
                    value: "46",
                    desc: "Pescara"
                },
                {
                    value: "47",
                    desc: "Teramo"
                },
                {
                    value: "48",
                    desc: "Arezzo"
                },
                {
                    value: "49",
                    desc: "Firenze"
                },
                {
                    value: "50",
                    desc: "Grosseto"
                },
                {
                    value: "51",
                    desc: "Livorno"
                },
                {
                    value: "52",
                    desc: "Lucca"
                },
                {
                    value: "53",
                    desc: "Massa Carrara"
                },
                {
                    value: "54",
                    desc: "Pisa"
                },
                {
                    value: "55",
                    desc: "Pistoia"
                },
                {
                    value: "56",
                    desc: "Siena"
                },
                {
                    value: "57",
                    desc: "Bologna"
                },
                {
                    value: "58",
                    desc: "Ferrara"
                },
                {
                    value: "59",
                    desc: "Forl\xec-Cesena"
                },
                {
                    value: "60",
                    desc: "Modena"
                },
                {
                    value: "61",
                    desc: "Parma"
                },
                {
                    value: "62",
                    desc: "Piacenza"
                },
                {
                    value: "63",
                    desc: "Ravenna"
                },
                {
                    value: "64",
                    desc: "Reggio Emilia"
                },
                {
                    value: "65",
                    desc: "Rimini"
                },
                {
                    value: "66",
                    desc: "Belluno"
                },
                {
                    value: "67",
                    desc: "Padova"
                },
                {
                    value: "68",
                    desc: "Rovigo"
                },
                {
                    value: "69",
                    desc: "Treviso"
                },
                {
                    value: "70",
                    desc: "Venezia"
                },
                {
                    value: "71",
                    desc: "Verona"
                },
                {
                    value: "72",
                    desc: "Vicenza"
                },
                {
                    value: "73",
                    desc: "Gorizia"
                },
                {
                    value: "74",
                    desc: "Pordenone"
                },
                {
                    value: "75",
                    desc: "Udine"
                },
                {
                    value: "76",
                    desc: "Trieste"
                },
                {
                    value: "77",
                    desc: "Aosta"
                },
                {
                    value: "78",
                    desc: "Cagliari"
                },
                {
                    value: "79",
                    desc: "Nuoro"
                },
                {
                    value: "80",
                    desc: "Oristano"
                },
                {
                    value: "81",
                    desc: "Sassari"
                },
                {
                    value: "82",
                    desc: "Genova"
                },
                {
                    value: "83",
                    desc: "Imperia"
                },
                {
                    value: "84",
                    desc: "Savona"
                },
                {
                    value: "85",
                    desc: "La Spezia"
                },
                {
                    value: "86",
                    desc: "Isernia"
                },
                {
                    value: "87",
                    desc: "Campobasso"
                },
                {
                    value: "88",
                    desc: "Perugia"
                },
                {
                    value: "89",
                    desc: "Terni"
                },
                {
                    value: "90",
                    desc: "Bergamo"
                },
                {
                    value: "91",
                    desc: "Brescia"
                },
                {
                    value: "92",
                    desc: "Como"
                },
                {
                    value: "93",
                    desc: "Cremona"
                },
                {
                    value: "94",
                    desc: "Lecco"
                },
                {
                    value: "95",
                    desc: "Lodi"
                },
                {
                    value: "96",
                    desc: "Mantova"
                },
                {
                    value: "97",
                    desc: "Milano"
                },
                {
                    value: "98",
                    desc: "Pavia"
                },
                {
                    value: "99",
                    desc: "Sondrio"
                },
                {
                    value: "100",
                    desc: "Varese"
                },
                {
                    value: "101",
                    desc: "Trento"
                },
                {
                    value: "102",
                    desc: "Bolzano"
                },
                {
                    value: "103",
                    desc: "Prato"
                },
                {
                    value: "104",
                    desc: "Verbano Cusio Ossola"
                },
                {
                    value: "105",
                    desc: "Carbonia Iglesias"
                },
                {
                    value: "106",
                    desc: "Medio Campidano"
                },
                {
                    value: "107",
                    desc: "Ogliastra"
                },
                {
                    value: "108",
                    desc: "Olbia Tempio"
                },
                {
                    value: "109",
                    desc: "Monza e Brianza"
                },
                {
                    value: "110",
                    desc: "Fermo"
                },
                {
                    value: "111",
                    desc: "Barletta Andria Trani"
                }, 
            ],
            validations: [
                {
                    type: "required",
                    message: "La provincia \xe8 obbligatoria"
                }, 
            ]
        },
        {
            label: "Numero di telefono",
            type: "text",
            name: "cell",
            value: "",
            isColMiddle: true,
            validations: [
                {
                    type: "minLength",
                    value: 3,
                    message: "Min. 3 caratteri"
                },
                {
                    type: "maxLength",
                    value: 9,
                    message: "Max 9 caratteri"
                },
                {
                    type: "required",
                    message: "Il cellulare \xe8 obbligatorio"
                },
                {
                    type: "regex",
                    message: "Inserire un cellulare valido",
                    regex: /^(3)+[0-9]*$/
                }, 
            ]
        },
        {
            type: "radio",
            name: "clienteVodafone",
            label: "Sei gi\xe0 cliente Vodafone",
            value: "",
            isColMiddle: false,
            options: [
                {
                    value: "yes",
                    desc: "Si"
                },
                {
                    value: "no",
                    desc: "No"
                }, 
            ],
            validations: [
                {
                    type: "required",
                    message: "il campo \xe8 obbligatorio"
                }, 
            ]
        },
        {
            label: "New Password",
            type: "password",
            name: "password",
            value: "",
            isColMiddle: false,
            validations: [
                {
                    type: "required",
                    message: "Password is required"
                },
                {
                    type: "minLength",
                    value: 5,
                    message: "Min. 5 characters"
                }, 
            ]
        },
        {
            label: "Repeat your password",
            type: "password",
            name: "repeat_password",
            value: "",
            isColMiddle: false,
            validations: [
                {
                    type: "required",
                    message: "Repeat password is required"
                },
                {
                    type: "minLength",
                    value: 5,
                    message: "Min. 5 characters"
                },
                {
                    type: "oneOf",
                    message: "Passwords must match",
                    ref: "password"
                }, 
            ]
        },
        {
            label: "E-mail address",
            type: "email",
            name: "email",
            value: "",
            isColMiddle: false,
            validations: [
                {
                    type: "required",
                    message: "Email is required"
                },
                {
                    type: "isEmail",
                    message: "Email no valid"
                }, 
            ]
        },
        {
            type: "select",
            name: "rol",
            label: "Select an option: ",
            value: "",
            isColMiddle: false,
            options: [
                {
                    value: "admin",
                    desc: "Admin"
                },
                {
                    value: "user",
                    desc: "User"
                },
                {
                    value: "super-admin",
                    desc: "Super Admin"
                }, 
            ],
            validations: [
                {
                    type: "required",
                    message: "Rol is required"
                }, 
            ]
        },
        {
            type: "radio",
            name: "gender",
            label: "Gender: ",
            value: "",
            isColMiddle: false,
            options: [
                {
                    value: "man",
                    desc: "Man"
                },
                {
                    value: "woman",
                    desc: "Woman"
                },
                {
                    value: "other",
                    desc: "Other"
                }, 
            ],
            validations: [
                {
                    type: "required",
                    message: "Gender is required"
                }, 
            ]
        },
        {
            type: "checkbox",
            name: "terms",
            typeValue: "boolean",
            label: "Terms and Conditions",
            value: false,
            isColMiddle: false,
            validations: [
                {
                    type: "isTrue",
                    message: "Accept the terms!"
                }, 
            ]
        }, 
    ]
};
var manageErrorLeadPlatform = function(submitOutput, errorCmsApi, push, queryClient) {
    var ref, ref1, ref2;
    var DEFAULT_LEAD_ERROR = {
        title: "Ops",
        message: "Si \xe8 verificato un problema. Riprovare in seguito",
        action: {}
    };
    var errorCode = errorCmsApi === null || errorCmsApi === void 0 ? void 0 : (ref = errorCmsApi.data) === null || ref === void 0 ? void 0 : ref["subCode"];
    var foundedErrors = submitOutput === null || submitOutput === void 0 ? void 0 : submitOutput.filter(function(el) {
        var ref, ref1;
        return ((ref = el.statusCode) === null || ref === void 0 ? void 0 : ref.toString()) == ((ref1 = errorCmsApi.status) === null || ref1 === void 0 ? void 0 : ref1.toString());
    });
    var foundedError = (foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors[0]) || DEFAULT_LEAD_ERROR;
    if (errorCode) {
        foundedError = (foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors.find(function(el) {
            var ref;
            return ((ref = el.errorCode) === null || ref === void 0 ? void 0 : ref.toString()) == (errorCode === null || errorCode === void 0 ? void 0 : errorCode.toString());
        })) || (foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors[0]) || DEFAULT_LEAD_ERROR;
    }
    errorManager.handleError(ErrorService.getSeverityErrorMedium(), _object_spread({
        disableTrack: true,
        title: (foundedError === null || foundedError === void 0 ? void 0 : foundedError.title) || "",
        message: (foundedError === null || foundedError === void 0 ? void 0 : foundedError.message) || "",
        actionText: (foundedError === null || foundedError === void 0 ? void 0 : (ref1 = foundedError.action) === null || ref1 === void 0 ? void 0 : ref1.title) || ""
    }, (foundedError === null || foundedError === void 0 ? void 0 : (ref2 = foundedError.action) === null || ref2 === void 0 ? void 0 : ref2.type) && {
        actionEvent: function() {
            return getButtonActionByActionType(foundedError === null || foundedError === void 0 ? void 0 : foundedError.action, push, queryClient);
        }
    }));
};
var manageSuccessLeadPlatform = function(submitOutput, push, queryClient) {
    var ref, ref1;
    errorManager.handleError(ErrorService.getSeverityErrorMedium(), _object_spread({
        disableTrack: true,
        title: (submitOutput === null || submitOutput === void 0 ? void 0 : submitOutput.title) || "",
        message: (submitOutput === null || submitOutput === void 0 ? void 0 : submitOutput.message) || "",
        actionText: (submitOutput === null || submitOutput === void 0 ? void 0 : (ref = submitOutput.action) === null || ref === void 0 ? void 0 : ref.title) || ""
    }, (submitOutput === null || submitOutput === void 0 ? void 0 : (ref1 = submitOutput.action) === null || ref1 === void 0 ? void 0 : ref1.type) && {
        actionEvent: function() {
            return getButtonActionByActionType(submitOutput === null || submitOutput === void 0 ? void 0 : submitOutput.action, push, queryClient);
        }
    }));
};
var organizeForm = function(cmsForm) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    if (!cmsForm || Object.keys(cmsForm).length === 0) return {
        config: {},
        input: []
    };
    return _object_spread_props(_object_spread({}, cmsForm), {
        submit: _object_spread({}, cmsForm.submit, (cmsForm === null || cmsForm === void 0 ? void 0 : (ref = cmsForm.submit) === null || ref === void 0 ? void 0 : ref.variant) && {
            variant: parseInt(cmsForm === null || cmsForm === void 0 ? void 0 : (ref1 = cmsForm.submit) === null || ref1 === void 0 ? void 0 : ref1.variant, 10)
        }, ((ref2 = cmsForm.submit) === null || ref2 === void 0 ? void 0 : ref2.splitHiddenValues) && {
            splitHiddenValues: cmsForm.submit.splitHiddenValues === "true"
        }, ((ref3 = cmsForm.submit) === null || ref3 === void 0 ? void 0 : ref3.enableQueryParamsInHidden) && {
            enableQueryParamsInHidden: cmsForm.submit.enableQueryParamsInHidden === "true"
        }, ((ref4 = cmsForm.submit) === null || ref4 === void 0 ? void 0 : ref4.alwaysEnabledSubmitButton) && {
            alwaysEnabledSubmitButton: cmsForm.submit.alwaysEnabledSubmitButton === "true"
        }),
        input: cmsForm === null || cmsForm === void 0 ? void 0 : (ref5 = cmsForm.input) === null || ref5 === void 0 ? void 0 : ref5.map(function(form) {
            var ref;
            return _object_spread_props(_object_spread({}, form, form.isHidden && {
                isHidden: (form === null || form === void 0 ? void 0 : form.isHidden) === "true"
            }, form.isColMiddle && {
                isColMiddle: (form === null || form === void 0 ? void 0 : form.isColMiddle) === "true"
            }, form.isDisabled && {
                isDisabled: (form === null || form === void 0 ? void 0 : form.isDisabled) === "true"
            }), {
                value: form.typeValue === "boolean" ? !!form.value : form.value || "",
                validations: ((ref = form.validations) === null || ref === void 0 ? void 0 : ref.map(function(validation) {
                    return _object_spread({}, validation, validation.regex && {
                        regex: new RegExp(validation.regex)
                    });
                })) || []
            });
        })
    });
};
var generateValidations = function(field) {
    if (!field.validations) return null;
    var schema = Yup[field.typeValue || "string"]();
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        // eslint-disable-next-line no-restricted-syntax
        for(var _iterator = field.validations[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var rule = _step.value;
            switch(rule.type){
                case "isTrue":
                    schema = schema.isTrue(rule.message);
                    break;
                case "isEmail":
                    schema = schema.email(rule.message);
                    break;
                case "minLength":
                    schema = schema.min(rule.value, rule.message);
                    break;
                case "maxLength":
                    schema = schema.max(rule.value, rule.message);
                    break;
                case "regex":
                    schema = schema.matches(rule.regex, rule.message);
                    break;
                case "oneOf":
                    schema = schema.oneOf([
                        Yup.ref(rule.ref)
                    ], rule.message);
                    break;
                default:
                    schema = schema.required(rule.message);
                    break;
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return schema;
};
var getInputs = function(form) {
    var initialValues = {};
    var validationsFields = {};
    form.forEach(function(field) {
        initialValues[field.name] = field.value;
        if (field.validations) {
            validationsFields[field.name] = generateValidations(field);
        }
    });
    return {
        validationSchema: Yup.object(_object_spread({}, validationsFields)),
        initialValues: initialValues,
        inputs: form
    };
};
export { manageErrorLeadPlatform, manageSuccessLeadPlatform, organizeForm, generateValidations, getInputs };
