import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 24px 32px;\n  min-height: 337px;\n  width: 327px;\n  background: ",
        ";\n  border: 1px solid ",
        ";\n  border-radius: 20px;\n  margin: 0 auto 64px;\n\n  @media (min-width: ",
        ") {\n    margin: 0 80px 80px;\n    padding: 32px 64px;\n    min-height: 232px;\n    width: auto;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0 0 16px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0 0 32px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: auto;\n\n  button {\n    padding: 10px 24px;\n    min-height: 45px;\n    border-radius: 30px;\n\n    @media (min-width: ",
        ") {\n      min-width: 189px;\n      width: auto;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import { breakpoints, colors } from "@vfit/shared/themes";
import styled from "styled-components";
export var CTCBoxContainer = styled.div.withConfig({
    componentId: "sc-a35ca5ff-0"
})(_templateObject(), colors.$ffffff, colors.$bebebe, breakpoints.desktop);
export var CustomTextTitleWrapper = styled.div.withConfig({
    componentId: "sc-a35ca5ff-1"
})(_templateObject1());
export var CustomTextDescriptionWrapper = styled.div.withConfig({
    componentId: "sc-a35ca5ff-2"
})(_templateObject2());
export var ButtonWrapper = styled.div.withConfig({
    componentId: "sc-a35ca5ff-3"
})(_templateObject3(), breakpoints.desktop);
