import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    font-size: 0.875rem; /* 14px */\n    line-height: 1.3; /* 14/18 */\n  "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
import { cssfonts } from "../cssfonts";
export var footnote1 = {
    regular: css(_templateObject(), cssfonts.regular)
};
