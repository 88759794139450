import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    text-decoration: underline;\n    text-underline-position: from-font;\n  "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    text-decoration: ;\n    text-underline-position: ;\n  "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  font-family: ",
        ";\n  ",
        ";\n  justify-content: center;\n  color: #007c92;\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n  text-decoration: none;\n  cursor: ",
        ";\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-weight: 700;\n  ",
        ";\n  color: ",
        ";\n  display: flex;\n  align-items: center;\n  margin: 0;\n\n  &:hover {\n    color: ",
        ";\n  }\n\n  &:active {\n    color: ",
        ";\n  }\n\n  ",
        ":visited & {\n    color: #007c92;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  ",
        "\n\n  & svg {\n    align-self: center;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { breakpoints, colors, fonts, pxToFontSize, pxToLineHeight } from "@vfit/shared/themes";
var variants = {
    sunrise: css(_templateObject()),
    vr: css(_templateObject1())
};
export var StyledDiv = styled.div.attrs(function(props) {
    return {
        size: props.size,
        fontMobile: props.size !== "2" ? {
            fontSize: pxToFontSize(14),
            lineHeight: pxToLineHeight(14, 18)
        } : {
            fontSize: pxToFontSize(16),
            lineHeight: pxToLineHeight(16, 22)
        },
        fontTabletDesktop: props.size !== "2" ? {
            fontSize: pxToFontSize(16),
            lineHeight: pxToLineHeight(16, 22)
        } : {
            fontSize: pxToFontSize(18),
            lineHeight: pxToLineHeight(18, 24)
        }
    };
}).withConfig({
    componentId: "sc-626774f0-0"
})(_templateObject2(), fonts.regular, function(props) {
    return props.fontMobile;
}, breakpoints.tablet, function(props) {
    return props.fontTabletDesktop;
});
export var StyledAnchor = styled.a.withConfig({
    componentId: "sc-626774f0-1"
})(_templateObject3(), function(props) {
    return props.disabled ? "pointer-events: none" : "";
}, function(props) {
    return props.disabled ? "default" : "pointer";
});
export var StyledLinkText = styled.div.withConfig({
    componentId: "sc-626774f0-2"
})(_templateObject4(), function(param) {
    var variant = param.variant;
    return variants[variant || "sunrise"];
}, function(props) {
    return props.color ? props.color : colors.$262626;
}, colors.$e60000, colors.$e60000, StyledAnchor);
export var StyledIconContainer = styled.div.withConfig({
    componentId: "sc-626774f0-3"
})(_templateObject5(), function(param) {
    var variant = param.variant;
    return variant === "vr" ? "width: 20px; height: 20px;" : "";
});
