var DEFAULT_SIZE_EXPERIENCES = {
    default: {
        width: 296,
        height: 320
    },
    mobile: {
        width: 269,
        height: 350
    }
};
var DEFAULT_SIZE = {
    default: {
        width: 405,
        height: 600
    },
    mobile: {
        width: 327,
        height: 480
    },
    large: {
        width: 625,
        height: 480
    },
    large_mobile: {
        height: 480
    },
    tablet: {
        width: 445,
        height: 480
    },
    masked: {
        width: 296,
        height: 480
    },
    maskedMobile: {
        width: 269,
        height: 350
    }
};
/**
 * Return the size of slide
 * @param slideIndex
 * @param isCovered
 * @param isExperiences
 * @param isPromo
 * @param isMobile
 * @param isTablet
 */ var getSlideSize = function(slideIndex, isCovered, isExperiences, isPromo, isMobile, isTablet) {
    if (!isCovered && !isExperiences && !isPromo) {
        return isMobile || isTablet ? DEFAULT_SIZE.mobile : DEFAULT_SIZE.default;
    }
    if (isCovered || isPromo) {
        if (isPromo) {
            if (isTablet) {
                return DEFAULT_SIZE.tablet;
            }
            return !isMobile ? DEFAULT_SIZE.large : DEFAULT_SIZE.large_mobile;
        }
        if (slideIndex === 0 && !isMobile) {
            if (isTablet) {
                return DEFAULT_SIZE.tablet;
            }
            return DEFAULT_SIZE.large;
        }
        return !isMobile ? DEFAULT_SIZE.masked : DEFAULT_SIZE.maskedMobile;
    }
    return !isMobile ? DEFAULT_SIZE_EXPERIENCES.default : DEFAULT_SIZE_EXPERIENCES.mobile;
};
export { getSlideSize, DEFAULT_SIZE };
