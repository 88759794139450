/* eslint-disable no-nested-ternary */ import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  height: ",
        ";\n  width: 100%;\n  flex-direction: column;\n  position: relative;\n  margin-top: ",
        "px;\n  border-radius: 0 0 20px 20px;\n  overflow: hidden;\n\n  @media (min-width: ",
        ") {\n    border-radius: 0 0 30px 30px;\n  }\n\n  &:hover {\n    cursor: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  top: 0;\n  background-color: ",
        ";\n  display: flex;\n  opacity: 0;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100vw;\n  height: ",
        ";\n  border-radius: 0 0 20px 20px;\n  overflow: hidden;\n\n  &.slide-animation {\n    z-index: 2;\n    opacity: 1;\n    animation-fill-mode: forwards;\n    animation-duration: 1s;\n  }\n\n  &.slide-up {\n    animation-name: slide-up;\n  }\n\n  &.slide-down {\n    animation-name: slide-down;\n  }\n\n  &.show {\n    z-index: 1;\n    opacity: 1;\n  }\n\n  @media (min-width: ",
        ") {\n    border-radius: 0 0 30px 30px;\n  }\n\n  @keyframes slide-up {\n    from {\n      transform: translateY(100%);\n    }\n\n    to {\n      transform: translateY(0);\n    }\n  }\n\n  @keyframes slide-down {\n    from {\n      transform: translateY(-100%);\n    }\n\n    to {\n      transform: translateY(0);\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  bottom: 23px;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n\n  @media (min-width: ",
        ") {\n    bottom: 52px;\n  }\n\n  @media (min-width: ",
        ") {\n    bottom: 64px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  justify-content: center;\n  display: flex;\n  transform: scale(0.7);\n\n  @media (min-width: ",
        ") {\n    transform: scale(1);\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  bottom: 35px;\n  right: 21px;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n\n  @media (min-width: ",
        ") {\n    bottom: 64px;\n    right: 27px;\n  }\n\n  @media (min-width: ",
        ") {\n    right: 35px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  html, body {\n    overflow: hidden;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled, { createGlobalStyle } from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var HeroContainer = styled.div.withConfig({
    componentId: "sc-48fa1168-0"
})(_templateObject(), function(param) {
    var viewportHeight = param.viewportHeight, headerHeight = param.headerHeight;
    return viewportHeight ? "".concat(viewportHeight - headerHeight, "px") : "calc(100vh - ".concat(headerHeight, "px)");
}, function(param) {
    var headerHeight = param.headerHeight;
    return headerHeight;
}, breakpoints.tablet, function(param) {
    var isMouseDown = param.isMouseDown, scrollPosition = param.scrollPosition, enableAnimation = param.enableAnimation;
    return enableAnimation ? "progress" : scrollPosition > 0 ? "inherit" : isMouseDown ? "grabbing" : "grab";
});
export var SliderWrapper = styled.div.withConfig({
    componentId: "sc-48fa1168-1"
})(_templateObject1(), function(param) {
    var color = param.color;
    return color || "#4a4d4e";
}, function(param) {
    var viewportHeight = param.viewportHeight, headerHeight = param.headerHeight;
    return viewportHeight ? "".concat(viewportHeight - headerHeight, "px") : "calc(100vh - ".concat(headerHeight, "px)");
}, breakpoints.tablet);
export var ScrollButtonContainer = styled.div.withConfig({
    componentId: "sc-48fa1168-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.desktop);
export var ButtonScroll = styled.div.withConfig({
    componentId: "sc-48fa1168-3"
})(_templateObject3(), breakpoints.desktop);
export var ScrollStickContainer = styled.div.withConfig({
    componentId: "sc-48fa1168-4"
})(_templateObject4(), breakpoints.tablet, breakpoints.desktop);
export var OverScrollDisabled = createGlobalStyle(_templateObject5());
