import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  overflow-y: auto;\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  z-index: 50;\n  width: 100vw;\n  height: 100%;\n  background-color: ",
        ";\n  top: 0;\n  left: 0;\n  opacity: 0;\n  transform: translate3d(-100%, 0, 0);\n\n  &.fade-in {\n    @keyframes fade-in-left {\n      from {\n        opacity: 0;\n        transform: translate3d(-100%, 0, 0);\n      }\n\n      to {\n        opacity: 1;\n        transform: translate3d(0, 0, 0);\n      }\n    }\n\n    animation-name: fade-in-left;\n    animation-duration: 0.5s;\n    animation-fill-mode: forwards;\n  }\n\n  &.fade-out {\n    @keyframes fade-out {\n      from {\n        opacity: 1;\n        transform: translate3d(0, 0, 0);\n      }\n\n      to {\n        opacity: 0;\n        transform: translate3d(-100%, 0, 0);\n      }\n    }\n\n    animation-name: fade-out;\n    animation-duration: 0.5s;\n    animation-fill-mode: forwards;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin: 8px 16px;\n  width: 48px;\n  height: 48px;\n\n  svg {\n    width: 48px;\n  }\n\n  a {\n    display: flex;\n    height: 100%;\n    width: 100%;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: 64px;\n  height: 64px;\n  background-color: ",
        ";\n  border: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 21px;\n\n  svg {\n    width: 21px;\n    fill: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin-top: 16px;\n\n  .accordionNavgationInnerContainer,\n  .defaultLinkWrapper {\n    padding: 0 20px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { colors } from "@vfit/shared/themes";
import styled from "styled-components";
export var OverlayContainer = styled.div.withConfig({
    componentId: "sc-c520fa38-0"
})(_templateObject(), colors.$ffffff);
export var Top = styled.div.withConfig({
    componentId: "sc-c520fa38-1"
})(_templateObject1());
export var LogoOverlay = styled.div.withConfig({
    componentId: "sc-c520fa38-2"
})(_templateObject2());
export var CloseBtn = styled.button.withConfig({
    componentId: "sc-c520fa38-3"
})(_templateObject3(), colors.$e60000, colors.$ffffff);
export var AccordionContainer = styled.div.withConfig({
    componentId: "sc-c520fa38-4"
})(_templateObject4());
