import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  display: flex;\n  margin-top: ",
        " !important;\n\n  span {\n    font-family: ",
        ";\n    ",
        "\n    font-style: normal;\n    font-weight: 400;\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  }\n\n  .tooltip {\n    margin-top: ",
        ";\n    margin-left: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  display: flex;\n  margin-top: 0 !important;\n\n  span {\n    font-family: ",
        ";\n    ",
        "\n    font-style: normal;\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  border: none;\n  padding: 0;\n  margin: 0 0 0 16px !important;\n  font: inherit;\n  cursor: pointer;\n  outline: inherit;\n  background: ",
        ";\n  border-radius: 100px;\n  color: white;\n  width: 26px;\n  height: 26px;\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n  ",
        "\n  font-family: ",
        ";\n\n  &:hover {\n    background: ",
        ";\n  }\n\n  &:active {\n    background: ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-style: normal;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  font-style: normal;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from "@vfit/shared/themes";
export var InflationContainer = styled.div.withConfig({
    componentId: "sc-49895ac7-0"
})(_templateObject(), function(props) {
    return props.compact ? "0" : "8px";
}, fonts.regular, pxToCssFont(12, 16), breakpoints.desktop, pxToCssFont(14, 18), function(props) {
    return props.compact ? "0 !important" : "";
}, function(props) {
    return props.compact ? "0 !important" : "";
});
export var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-49895ac7-1"
})(_templateObject1(), fonts.regular, pxToCssFont(12, 16), breakpoints.desktop, pxToCssFont(14, 18));
export var ProductCardSpecItemAction = styled.button.withConfig({
    componentId: "sc-49895ac7-2"
})(_templateObject2(), colors.$e60000, pxToCssFontSize(26), fonts.light, colors.$bd0000, colors.$a10000);
export var ModalTitle = styled.div.withConfig({
    componentId: "sc-49895ac7-3"
})(_templateObject3(), pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var ModalContent = styled.div.withConfig({
    componentId: "sc-49895ac7-4"
})(_templateObject4());
export var Title = styled.span.withConfig({
    componentId: "sc-49895ac7-5"
})(_templateObject5(), fonts.regular, function(isGloballPromo) {
    return isGloballPromo ? "\n        ".concat(pxToCssFont(17, 20), "\n        font-weight: 700;\n      ") : "\n        ".concat(pxToCssFont(12, 16), "\n        font-weight: 400;\n    ");
}, breakpoints.desktop, function(isGlobalPromo) {
    return isGlobalPromo ? "" : pxToCssFont(14, 18);
});
export var Disclaimer = styled.span.withConfig({
    componentId: "sc-49895ac7-6"
})(_templateObject6(), fonts.regular, pxToCssFont(12, 16), breakpoints.desktop, pxToCssFont(14, 18));
