import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  body {\n    overflow: hidden;\n    width: 100%;\n    height: 100%;\n    position: fixed;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 4;\n  background-color: #0000008f;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  border-top-left-radius: ",
        "px;\n  border-top-right-radius: ",
        "px;\n  background-color: ",
        ";\n  overflow: hidden;\n\n  .closing-icon {\n    float: right;\n    margin-top: 11.5px;\n\n    @media (min-width: ",
        ") {\n      margin-top: 21.5px;\n      margin-right: 21.5px;\n    }\n\n    &:hover {\n      cursor: pointer;\n    }\n  }\n\n  a {\n    color: ",
        " !important;\n    font-weight: 700 !important;\n\n    :visited {\n      color: ",
        " !important;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    min-height: inherit;\n    width: fit-content;\n    height: fit-content;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    min-height: inherit;\n    left: 50%;\n    top: 50%;\n    // border-radius: 6px;\n    transform: translate(-50%, -50%);\n    min-width: fit-content;\n    height: fit-content;\n    max-width: .closing-icon {\n      margin-top: 29.5px;\n      margin-right: 29.5px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    min-height: inherit;\n    max-width: fit-content;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  overflow: auto;\n  scrollbar-width: 3px;\n\n  ::-webkit-scrollbar {\n    width: 3px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  background-color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    width: 62.2%;\n  }\n\n  .closing-icon:hover {\n    cursor: pointer;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: space-between;\n  padding: 25px;\n  border-bottom: 1px solid #5e5e5e;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: space-between;\n  padding: 30px 25px 25px;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  margin-top: auto;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 25px;\n\n  button {\n    text-transform: uppercase;\n    max-width: 300px;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  right: 24px;\n  path {\n    stroke: ",
        ";\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
import styled, { createGlobalStyle } from "styled-components";
import { breakpoints, colors } from "@vfit/shared/themes";
export var OverlayGlobalStyle = createGlobalStyle(_templateObject());
export var Background = styled.div.withConfig({
    componentId: "sc-f183b46c-0"
})(_templateObject1());
export var Container = styled.div.withConfig({
    componentId: "sc-f183b46c-1"
})(_templateObject2(), function(props) {
    return props.withBorderRadius ? "20" : "0";
}, function(props) {
    return props.withBorderRadius ? "20" : "0";
}, colors.$ffffff, breakpoints.tablet, colors.$262626, colors.$262626, breakpoints.tablet, function(props) {
    return !props.tabletMode ? "\n          left: 50%;\n          top: 50%;\n          transform: translate(-50%, -50%);\n          width: 62.2%;\n          border-radius: 20px;\n           .closing-icon {\n            margin-top: 29.5px;\n            margin-right: 21.5px;\n          }\n        " : "\n         left: 50%;\n          top: 50%;\n          border-radius: 6px;\n          transform: translate(-50%, -50%);";
}, breakpoints.desktop, breakpoints.bigDesktop);
export var ModalContent = styled.div.withConfig({
    componentId: "sc-f183b46c-2"
})(_templateObject3());
// TODO: wip FIGMA modal error styles
export var ContainerModalError = styled.div.withConfig({
    componentId: "sc-f183b46c-3"
})(_templateObject4(), colors.$ffffff, breakpoints.tablet);
export var ModalErrorHeader = styled.div.withConfig({
    componentId: "sc-f183b46c-4"
})(_templateObject5());
export var ModalErrorContent = styled.div.withConfig({
    componentId: "sc-f183b46c-5"
})(_templateObject6());
export var ModalErrorFooter = styled.div.withConfig({
    componentId: "sc-f183b46c-6"
})(_templateObject7());
export var IconButton = styled.div.withConfig({
    componentId: "sc-f183b46c-7"
})(_templateObject8(), function(props) {
    return props.color ? props.color : colors.$bebebe;
});
