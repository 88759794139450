import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  text-align: ",
        ";\n  ",
        ";\n  font-family: ",
        ";\n  ",
        ";\n  ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n    ",
        ";\n    ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n    ",
        ";\n  }\n\n  b {\n    font-family: ",
        ";\n    font-weight: 400;\n  }\n\n  img {\n    cursor: text;\n    vertical-align: baseline;\n  }\n\n  a {\n    color: ",
        ";\n    font-weight: 700;\n\n    &:hover {\n      color: ",
        ";\n    }\n  }\n\n  button {\n    font-family: ",
        ";\n    font-size: ",
        ";\n    font-weight: 400;\n    color: ",
        ";\n    background: none;\n    border: none;\n    padding: 0;\n    outline: inherit;\n\n    span {\n      font-family: ",
        ";\n      color: white;\n      background-color: ",
        ";\n      padding-left: 10px;\n      padding-right: 10px;\n      border-radius: 50%;\n      cursor: pointer;\n    }\n\n    &:hover {\n      span {\n        background-color: ",
        ";\n      }\n    }\n\n    &:active {\n      span {\n        background-color: ",
        ";\n      }\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToFontSize, pxToRem } from "@vfit/shared/themes";
export var CustomTextContainer = styled.div.withConfig({
    componentId: "sc-6f54bfb5-0"
})(_templateObject(), function(param) {
    var textAlign = param.textAlign;
    return textAlign;
}, function(param) {
    var modal = param.modal;
    return modal ? "margin-top: 16px; margin-bottom: 32px" : "margin: 48px 23px 31px 23px";
}, function(param) {
    var fontFamily = param.fontFamily;
    return fontFamily || fonts.light;
}, function(param) {
    var sizeMobile = param.sizeMobile, lineHeightMobile = param.lineHeightMobile;
    return pxToCssFont(sizeMobile || 30, lineHeightMobile || 38);
}, function(param) {
    var margin = param.margin;
    return margin !== undefined ? "margin: ".concat(pxToRem.apply(void 0, _to_consumable_array(margin))) : null;
}, breakpoints.tablet, function(param) {
    var size = param.size, lineHeight = param.lineHeight;
    return pxToCssFont(size || 36, lineHeight || 50);
}, function(param) {
    var modal = param.modal;
    return modal && "margin-top: 24px; margin-bottom: 32px";
}, function(param) {
    var margin = param.margin;
    return margin !== undefined ? "margin: ".concat(pxToRem.apply(void 0, _to_consumable_array(margin))) : null;
}, breakpoints.desktop, function(param) {
    var modal = param.modal;
    return modal && "margin-top: 16px; margin-bottom: 40px";
}, function(param) {
    var margin = param.margin;
    return margin !== undefined ? "margin: ".concat(pxToRem.apply(void 0, _to_consumable_array(margin))) : null;
}, fonts.exbold, colors.$262626, colors.$e60000, fonts.exbold, function(param) {
    var sizeMobile = param.sizeMobile;
    return pxToFontSize(sizeMobile || 30);
}, colors.$e60000, fonts.light, colors.$e60000, colors.$bd0000, colors.$a10000);
