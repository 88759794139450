import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  ",
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: ",
        ";\n\n  & div {\n    padding: 0;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  @keyframes fadein {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n    }\n  }\n\n  color: ",
        ";\n  ",
        "\n  font-family: ",
        ";\n  text-align: left;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var AddressManualContainer = styled.div.withConfig({
    componentId: "sc-18624a49-0"
})(_templateObject());
export var AddressManualTitle = styled.h3.withConfig({
    componentId: "sc-18624a49-1"
})(_templateObject1(), pxToCssFontSize(30), fonts.exbold, breakpoints.tablet, pxToCssFontSize(42), breakpoints.desktop, pxToCssFontSize(36));
export var AddressManualInputContainer = styled.div.withConfig({
    componentId: "sc-18624a49-2"
})(_templateObject2(), pxToRem(16));
export var ErrorMsg = styled.p.withConfig({
    componentId: "sc-18624a49-3"
})(_templateObject3(), colors.$e60000, pxToCssFont(14, 18), fonts.regular);
