import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  font-size: 1.75rem; /* 28px */\n  line-height: 1.3; /* 28/36 */\n\n  @media (min-width: ",
        ") {\n    font-size: 2.25rem; /* 36px */\n    line-height: 1.35; /* 36/48 */\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    ",
        "\n  "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    ",
        "\n  "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    ",
        "\n  "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    ",
        "\n  "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
import { breakpoints } from "../../breakpoints";
import { cssfonts } from "../cssfonts";
var common = css(_templateObject(), breakpoints.desktop);
export var h4 = {
    extrabold: css(_templateObject1(), cssfonts.exbold, common),
    bold: css(_templateObject2(), cssfonts.bold, common),
    regular: css(_templateObject3(), cssfonts.regular, common),
    light: css(_templateObject4(), cssfonts.light, common)
};
