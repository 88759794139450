/**
 *
 * Interfaces
 *
 */ export var ICoverageToolType;
(function(ICoverageToolType) {
    ICoverageToolType[ICoverageToolType["MANUAL"] = 0] = "MANUAL";
    ICoverageToolType[ICoverageToolType["AUTO"] = 1] = "AUTO";
    ICoverageToolType[ICoverageToolType["MAPS"] = 2] = "MAPS";
    ICoverageToolType[ICoverageToolType["ERROR"] = 3] = "ERROR";
    ICoverageToolType[ICoverageToolType["LIST_ADDRESS"] = 4] = "LIST_ADDRESS";
})(ICoverageToolType || (ICoverageToolType = {}));
