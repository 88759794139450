import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  max-width: 70%;\n  margin-bottom: ",
        ";\n  ",
        "\n  font-weight: 400;\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 24px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  max-width: 70%;\n  margin-bottom: 24px;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n\n  p {\n    margin: inherit;\n  }\n\n  @media (min-width: ",
        ") {\n    h2 {\n      ",
        "\n    }\n\n    ",
        "\n    p {\n      margin: inherit;\n    }\n\n    h4 {\n      ",
        "\n    }\n\n    h5 {\n      ",
        "\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    p {\n      margin: inherit;\n    }\n\n    h4 {\n      ",
        "\n    }\n\n    h5 {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  color: ",
        ";\n\n  p {\n    margin: inherit;\n  }\n\n  @media (min-width: ",
        ") {\n    h2 {\n      ",
        "\n      color: ",
        ";\n    }\n\n    p {\n      margin: inherit;\n      ",
        "\n      color: ",
        ";\n    }\n\n    h4 {\n      ",
        "\n      color: ",
        ";\n    }\n\n    h5 {\n      ",
        "\n      color: ",
        ";\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    p {\n      ",
        "\n      margin: inherit;\n    }\n\n    h4 {\n      ",
        "\n    }\n\n    h5 {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  max-width: 70%;\n  margin-bottom: ",
        ";\n  ",
        "\n  font-weight: 400;\n  color: ",
        ";\n\n  span {\n    display: flex;\n    font-weight: 400;\n    color: ",
        ";\n    align-items: baseline;\n  }\n\n  @media (min-width: ",
        ") {\n    h2 {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  color: ",
        ";\n\n  p {\n    margin: inherit;\n  }\n\n  @media (min-width: ",
        ") {\n    h2 {\n      ",
        "\n      color: ",
        ";\n    }\n\n    p {\n      margin: inherit;\n      ",
        "\n      color: ",
        ";\n    }\n\n    h4 {\n      ",
        "\n      color: ",
        ";\n    }\n\n    h5 {\n      ",
        "\n      color: ",
        ";\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    p {\n      ",
        "\n      margin: inherit;\n    }\n\n    h4 {\n      ",
        "\n    }\n\n    h5 {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  margin-bottom: ",
        ";\n  min-height: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-bottom: ",
        ";\n    min-height: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    min-height: ",
        ";\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  width: 100%;\n  min-height: 100%;\n  min-width: 100%;\n  overflow: hidden;\n  z-index: -1;\n  object-fit: cover;\n  object-position: center;\n  border-radius: ",
        ";\n  position: ",
        ";\n  top: ",
        ";\n  left: ",
        ";\n\n  @media (min-width: ",
        ") {\n    border-radius: 0 0 30px 30px;\n  }\n\n  @media (min-width: ",
        ") {\n    border-radius: 0 0 30px 30px;\n  }\n\n  &::after {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    display: inline-block;\n    background: linear-gradient(1.14deg, rgba(0, 0, 0, 4%) 11.28%, rgba(0, 0, 0, 0%) 75.72%);\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: ",
        ";\n\n  display: flex;\n  .back {\n    width: inherit;\n    display: flex;\n    cursor: pointer;\n    margin: 0;\n    appearance: none;\n    background: none;\n    border: none;\n    font-family: inherit;\n    font-size: inherit;\n    padding: 0;\n  }\n\n  div {\n    transform: rotate(180deg);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    svg {\n      width: 9px;\n      height: 16px;\n\n      path {\n        stroke: white;\n      }\n    }\n  }\n\n  span {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: white;\n    margin-left: 15px;\n  }\n\n  button {\n    border: 0;\n    display: flex;\n    cursor: pointer;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: ",
        ";\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  margin-left: 32px;\n  padding-bottom: 32px;\n  padding-top: ",
        ";\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n\n  @media (min-width: ",
        ") {\n    padding-top: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    margin-left: 80px;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  flex: 0.8;\n\n  @media (min-width: ",
        ") {\n    flex: 0.9;\n  }\n\n  @media (min-width: ",
        ") {\n    flex: 0.8;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  display: flex;\n  bottom: 23px;\n  width: 100%;\n  flex: 0.2;\n  padding-bottom: 60px;\n  justify-content: center;\n  z-index: 10;\n  margin-left: -32px;\n\n  @media (min-width: ",
        ") {\n    padding-bottom: 0;\n    margin-left: -32px;\n    flex: 0.1;\n  }\n\n  @media (min-width: ",
        ") {\n    padding-bottom: 10px;\n    margin-left: -80px;\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  h2 {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    letter-spacing: ",
        ";\n    text-transform: uppercase;\n    color: ",
        ";\n    margin: 0 30px;\n  }\n\n  h4 {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 400;\n    color: ",
        ";\n    margin: 30px 30px 0;\n    max-width: none;\n  }\n\n  h5 {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 700;\n    color: ",
        ";\n    margin: 8px 30px 0;\n    max-width: none;\n  }\n\n  @media (min-width: ",
        ") {\n    h2 {\n      ",
        "\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    h2 {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n  margin-top: 80px;\n  margin-bottom: 80px;\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    margin-bottom: 24px;\n  }\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n  }\n"
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin-top: 30px;\n  flex-direction: column;\n\n  button,\n  a {\n    width: 210px;\n    margin-bottom: 10px;\n  }\n  @media (min-width: ",
        ") {\n    gap: 20px;\n  }\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n  }\n  gap: 15px;\n"
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, getTypography, pxToCssFont, pxToCssFontSize, pxToRem } from "@vfit/shared/themes";
export var Category = styled.div.withConfig({
    componentId: "sc-ab96dfdb-0"
})(_templateObject(), fonts.regular, function(param) {
    var headerShort = param.headerShort;
    return headerShort ? "8px" : "10px";
}, pxToCssFont(18, 24), colors.$f2f2f2, breakpoints.tablet);
export var Title = styled.div.withConfig({
    componentId: "sc-ab96dfdb-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(42, 40), colors.$ffffff, breakpoints.tablet, pxToCssFont(18, 24), pxToCssFont(64, 80), pxToCssFont(30, 38), pxToCssFont(20, 30), breakpoints.desktop, pxToCssFont(70, 65), pxToCssFont(42, 52), pxToCssFont(20, 30));
export var TitleLite = styled.div.withConfig({
    componentId: "sc-ab96dfdb-2"
})(_templateObject2(), getTypography("h4.extrabold"), colors.$262626, breakpoints.tablet, getTypography("h4.extrabold"), colors.$262626, getTypography("h4.extrabold"), colors.$262626, getTypography("h4.extrabold"), colors.$262626, getTypography("h4.extrabold"), colors.$262626, breakpoints.desktop, getTypography("h4.extrabold"), getTypography("h4.extrabold"), getTypography("h4.extrabold"));
export var Description = styled.div.withConfig({
    componentId: "sc-ab96dfdb-3"
})(_templateObject3(), fonts.regular, function(param) {
    var headerShort = param.headerShort;
    return headerShort ? "32px" : "35px";
}, pxToCssFont(18, 24), colors.$f2f2f2, colors.$ffffff, breakpoints.tablet, pxToCssFont(18, 24));
export var DescriptionLite = styled.div.withConfig({
    componentId: "sc-ab96dfdb-4"
})(_templateObject4(), getTypography("h4.light"), colors.$262626, breakpoints.tablet, getTypography("h4.light"), colors.$262626, getTypography("h4.light"), colors.$262626, getTypography("h4.light"), colors.$262626, getTypography("h4.light"), colors.$262626, breakpoints.desktop, getTypography("h4.light"), getTypography("h4.light"), getTypography("h4.light"));
export var SlideProductContainer = styled.div.withConfig({
    componentId: "sc-ab96dfdb-5"
})(_templateObject5(), function(param) {
    var haveDivider = param.haveDivider;
    return !haveDivider ? "0px" : "50px";
}, function(param) {
    var shortHero = param.shortHero;
    return shortHero ? "320px" : "442px";
}, breakpoints.tablet, function(param) {
    var haveDivider = param.haveDivider;
    return !haveDivider ? "0px" : "80px";
}, function(param) {
    var shortHero = param.shortHero;
    return shortHero ? "480px" : "800px";
}, breakpoints.desktop, function(param) {
    var shortHero = param.shortHero;
    return shortHero ? "480px" : "680px";
});
export var DivImgContainer = styled.div.withConfig({
    componentId: "sc-ab96dfdb-6"
})(_templateObject6(), function(props) {
    return props.isApp ? "0 0 12px 12px" : "0 0 20px 20px";
}, function(props) {
    return props.absolute ? "absolute" : "relative";
}, function(props) {
    return props.absolute ? 0 : "";
}, function(props) {
    return props.absolute ? 0 : "";
}, breakpoints.tablet, breakpoints.desktop);
export var SlideProductNavigation = styled.div.withConfig({
    componentId: "sc-ab96dfdb-7"
})(_templateObject7(), function(param) {
    var shortHero = param.shortHero;
    return shortHero ? "32px" : "22px";
}, fonts.regular, pxToCssFontSize(16), breakpoints.tablet, function(param) {
    var shortHero = param.shortHero;
    return shortHero ? "56px" : "22px";
}, breakpoints.desktop, function(param) {
    var shortHero = param.shortHero;
    return shortHero ? "40px" : "22px";
});
export var SlideProductContent = styled.div.withConfig({
    componentId: "sc-ab96dfdb-8"
})(_templateObject8(), function(param) {
    var shortHero = param.shortHero, headerLite = param.headerLite;
    return shortHero ? "".concat(headerLite ? "80px" : "32px") : "".concat(headerLite ? "80px" : "32px");
}, breakpoints.tablet, function(param) {
    var shortHero = param.shortHero, headerLite = param.headerLite;
    return shortHero ? "".concat(headerLite ? "112px" : "64px") : "".concat(headerLite ? "112px" : "64px");
}, breakpoints.desktop);
export var SlideProductContentTop = styled.div.withConfig({
    componentId: "sc-ab96dfdb-9"
})(_templateObject9(), breakpoints.tablet, breakpoints.desktop);
export var SlideProductContentBottom = styled.div.withConfig({
    componentId: "sc-ab96dfdb-10"
})(_templateObject10(), breakpoints.tablet, breakpoints.desktop);
export var SlideProductText = styled.div.withConfig({
    componentId: "sc-ab96dfdb-11"
})(_templateObject11(), fonts.regular, pxToCssFont(16, 24), pxToRem(0.5), colors.$ffffff, fonts.exbold, pxToCssFont(24, 28), colors.$f2f2f2, fonts.regular, pxToCssFont(18, 24), colors.$bebebe, breakpoints.tablet, pxToCssFont(18, 24), breakpoints.desktop, pxToCssFont(18, 24));
export var SlideProductLiteText = styled.div.withConfig({
    componentId: "sc-ab96dfdb-12"
})(_templateObject12());
export var ModalTitle = styled.div.withConfig({
    componentId: "sc-ab96dfdb-13"
})(_templateObject13());
export var ModalContent = styled.div.withConfig({
    componentId: "sc-ab96dfdb-14"
})(_templateObject14());
export var SlideButtonsContainer = styled.div.withConfig({
    componentId: "sc-ab96dfdb-15"
})(_templateObject15(), breakpoints.tablet, breakpoints.desktop);
