import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  border-radius: ",
        ";\n  color: ",
        ";\n  display: inline-block;\n  font-size: ",
        ";\n  font-weight: 700;\n  padding: ",
        ";\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { pxToRem } from "@vfit/shared/themes";
export var ChipStyle = styled.span.withConfig({
    componentId: "sc-15786fc8-0"
})(_templateObject(), function(p) {
    return p.BgColor || "rgba(37, 40, 43, 1)";
}, pxToRem(20), function(p) {
    return p.color || " rgba(255, 255, 255, 1)";
}, pxToRem(12), pxToRem(4, 10), function(p) {
    return p.margin ? "margin: ".concat(pxToRem.apply(void 0, _to_consumable_array(p.margin)), ";") : "";
});
