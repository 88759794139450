import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  border: none;\n  cursor: pointer;\n  padding: 0;\n  position: absolute;\n  right: ",
        ";\n  top: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  overflow: hidden;\n  z-index: 1;\n  opacity: ",
        ";\n  ",
        ";\n  ",
        ";\n  ",
        ";\n  bottom: ",
        ";\n  right: ",
        ";\n  position: fixed;\n  top: unset;\n  width: calc(100% - ",
        ");\n\n  @media (min-width: ",
        ") {\n    bottom: ",
        ";\n    right: ",
        ";\n    width: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    bottom: ",
        ";\n    right: ",
        ";\n    width: ",
        ";\n  }\n\n  @keyframes slide-left-to-right {\n    from {\n      transform: translateX(-100%);\n      opacity: 0;\n    }\n\n    to {\n      transform: translateX(0);\n      opacity: 1;\n    }\n  }\n\n  @keyframes slide-down {\n    from {\n      transform: translateY(-100%);\n    }\n\n    to {\n      transform: translateY(0);\n    }\n  }\n\n  @keyframes slide-up {\n    from {\n      transform: translateY(100%);\n    }\n\n    to {\n      transform: translateY(0);\n    }\n  }\n\n  @keyframes fade-in {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import { breakpoints, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var HTMLWrapper = styled.div.withConfig({
    componentId: "sc-6b415f55-0"
})(_templateObject());
export var XTopClose = styled.button.withConfig({
    componentId: "sc-6b415f55-1"
})(_templateObject1(), pxToRem(24), pxToRem(16));
export var BannerStyle = styled.section.withConfig({
    componentId: "sc-6b415f55-2"
})(_templateObject2(), function(p) {
    return p.animationType === "fade-in" || p.animationType === "slide-left-to-right" ? 0 : 1;
}, function(p) {
    return p.animationType === "slide-down" ? "transition: translateY(-100%)" : "";
}, function(p) {
    return p.animationType === "slide-left-to-right" ? "transform: translateX(-100%)" : "";
}, function(p) {
    return p.animationType ? "animation: ".concat(p.animationType, " 0.5s ease-in forwards ").concat(p.delay, "s") : "";
}, pxToRem(23), pxToRem(16), pxToRem(32), breakpoints.tablet, pxToRem(32), pxToRem(32), pxToRem(331), breakpoints.desktop, pxToRem(80), pxToRem(80), pxToRem(516));
