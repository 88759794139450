import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n\n  ::after {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    display: inline-block;\n    background: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  div {\n    position: inherit !important;\n\n    ::after {\n      content: '';\n      position: absolute;\n      left: 0;\n      top: 0;\n      width: 100%;\n      height: 100%;\n      display: inline-block;\n      background: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var GradientLambdaImageContainer = styled.div.withConfig({
    componentId: "sc-76a75f2-0"
})(_templateObject(), function(param) {
    var gradient = param.gradient;
    return gradient;
});
export var GradientDefaultImageContainer = styled.div.withConfig({
    componentId: "sc-76a75f2-1"
})(_templateObject1(), function(param) {
    var gradient = param.gradient;
    return gradient;
});
