import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 20px;\n  background: transparent;\n  border-color: ",
        ";\n  border-style: solid;\n  border-width: 0 1px;\n  margin-top: -10px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  border-radius: 6px;\n  background-color: ",
        ";\n  z-index: 3;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  z-index: 3;\n  max-height: 258px;\n  overflow-y: scroll;\n  position: absolute;\n  top: 0;\n  margin-top: -10px;\n  width: 100%;\n  width: fill-available;\n  background-color: ",
        ";\n  border-radius: 0 0 6px 6px;\n  padding: 0;\n  border-left: 1px solid ",
        ";\n  border-right: 1px solid ",
        ";\n  border-bottom: 1px solid ",
        ";\n\n  ::-moz-scrollbar {\n    display: none;\n  }\n\n  ::-webkit-scrollbar {\n    display: none;\n  }\n\n  li:first-child {\n    padding-top: 16px;\n  }\n\n  li:last-child {\n    padding-bottom: 16px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  list-style: none;\n  padding: 8px 20px;\n  color: ",
        ";\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  text-align: left;\n\n  &:hover {\n    cursor: pointer;\n    background-color: #ccc;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  list-style: none;\n  padding: 8px 20px;\n  color: ",
        ";\n  font-family: ",
        ";\n  font-weight: 700;\n  ",
        "\n  text-align: left;\n  text-decoration: underline;\n\n  &:hover {\n    cursor: pointer;\n    background-color: #ccc;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { colors, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var FillBorder = styled.div.withConfig({
    componentId: "sc-f316463f-0"
})(_templateObject(), colors.$bebebe);
export var Wrapper = styled.div.withConfig({
    componentId: "sc-f316463f-1"
})(_templateObject1(), colors.$ffffff);
export var Suggestions = styled.ul.withConfig({
    componentId: "sc-f316463f-2"
})(_templateObject2(), colors.$ffffff, colors.$bebebe, colors.$bebebe, colors.$bebebe);
export var SuggestionItem = styled.li.withConfig({
    componentId: "sc-f316463f-3"
})(_templateObject3(), colors.$7e7e7e, fonts.regular, pxToCssFont(18, 24));
export var SuggestionItemPlaceholder = styled.li.withConfig({
    componentId: "sc-f316463f-4"
})(_templateObject4(), colors.$0d0d0d, fonts.regular, pxToCssFont(18, 24));
