import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  width: 0;\n  background-color: ",
        ";\n\n  .yxt-SearchBar-container {\n    border: none;\n    box-shadow: none;\n    margin-top: 3px;\n    margin-left: 10px;\n\n    form {\n      :focus {\n        border: inherit;\n      }\n\n      input {\n        font-family: ",
        ";\n        padding-right: 44px;\n        text-overflow: ellipsis;\n      }\n    }\n  }\n\n  .yxt-AutoComplete {\n    ::before {\n      background-color: white;\n    }\n\n    ul li {\n      font-family: ",
        ";\n    }\n  }\n\n  .yxt-SearchBar-clear {\n    display: none;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  svg {\n    width: 17px !important;\n    margin-right: 5px !important;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  min-width: 31px;\n  width: ",
        ";\n  height: 50px;\n  border-radius: 10px;\n  float: right;\n  border: ",
        ";\n  box-shadow: ",
        ";\n  transition: width 0.2s;\n\n  svg {\n    path {\n      stroke: ",
        ";\n    }\n  }\n\n  :hover {\n    svg {\n      path {\n        stroke: ",
        ";\n      }\n    }\n  }\n\n  .searchbox-icon {\n    display: flex;\n    align-items: center;\n    height: 100%;\n    position: absolute;\n    top: 0;\n    right: 0;\n    ",
        "\n    padding: 0;\n    margin: 0;\n    border: 0;\n    outline: 0;\n    text-align: center;\n    cursor: pointer;\n    color: ",
        ";\n    z-index: 2;\n\n    svg {\n      width: 24px;\n\n      @media (min-width: ",
        ") {\n        width: 25px;\n      }\n    }\n  }\n\n  button {\n    background: transparent;\n    border: none;\n  }\n\n  button:hover svg {\n    path {\n      stroke: ",
        ";\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    width: ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var SearchBoxContainer = styled.div.withConfig({
    componentId: "sc-e39c3e64-0"
})(_templateObject(), colors.$bebebe, fonts.light, fonts.light);
export var CloseContainer = styled.div.withConfig({
    componentId: "sc-e39c3e64-1"
})(_templateObject1());
export var SearchBoxContent = styled.div.withConfig({
    componentId: "sc-e39c3e64-2"
})(_templateObject2(), function(param) {
    var isOpen = param.isOpen;
    return isOpen ? "200px" : 0;
}, function(param) {
    var isOpen = param.isOpen;
    return isOpen ? "0.805957px solid white" : "none";
}, function(param) {
    var isOpen = param.isOpen;
    return isOpen ? "0 0 0.625rem 0 rgb(0 0 0 / 18%)" : "none";
}, colors.$0d0d0d, colors.$e60000, pxToCssFont(22, 50), colors.$ffffff, breakpoints.tablet, colors.$e60000, breakpoints.tablet, function(param) {
    var isOpen = param.isOpen;
    return isOpen ? "280px" : 0;
});
