import { useAnimation } from "@vfit/shared/hooks";
export var useErrorMessage = function(param) {
    var error = param.error;
    var errorA = useAnimation({
        prefix: "error",
        show: typeof error === "string" && !!error,
        thingsToWait: error
    });
    return {
        errorA: errorA
    };
};
