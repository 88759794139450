import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: ",
        ";\n  padding: 20px 16px;\n  flex: 100%;\n  @media (min-width: ",
        ") {\n    flex: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding: 0 20px 0 16px;\n  text-align: ",
        ";\n  h2,\n  h4 {\n    font-weight: inherit;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  position: relative;\n  margin: 0;\n  padding: 18px 16px 18px 20px;\n  display: flex;\n  transition: border 2s;\n  border-radius: 5.008px;\n  background: transparent;\n  ",
        ";\n\n  .wrapper {\n    display: inline-flex;\n    width: 100%;\n    margin: 0;\n    padding: 0;\n    height: 22px;\n  }\n\n  .wrapper-icon {\n    display: flex;\n\n    @media (min-width: ",
        ") {\n      align-self: center;\n    }\n\n    @media (min-width: ",
        ") {\n      align-self: center;\n    }\n  }\n\n  .icon {\n    position: relative;\n    margin: auto;\n    display: flex;\n    flex-direction: column;\n    appearance: none;\n    background: none;\n    border: none;\n    font-family: inherit;\n    font-size: inherit;\n    padding: 0;\n\n    &:hover {\n      cursor: ",
        ";\n    }\n  }\n\n  ",
        "\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  padding: 20px;\n  display: ",
        ";\n  flex: 100%;\n  @media (min-width: ",
        ") {\n    flex: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  background-color: transparent;\n  margin: auto;\n  padding: 0;\n  width: 100%;\n  height: 22px;\n  outline: 0;\n  border: 0;\n  text-overflow: ellipsis;\n  font-family: ",
        ";\n  ",
        "\n  text-align: left;\n\n  &:disabled {\n    background-color: transparent;\n    cursor: not-allowed;\n  }\n\n  &:focus {\n    outline: none;\n  }\n\n  &::placeholder {\n    color: ",
        ";\n  }\n\n  &:-webkit-autofill,\n  &:-webkit-autofill:hover,\n  &:-webkit-autofill:focus,\n  &:-webkit-autofill:active {\n    box-shadow: 0 0 0 30px white inset !important;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: auto;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  background-color: transparent;\n  margin: auto;\n  padding: 0;\n  width: 100%;\n  height: 22px;\n  outline: 0;\n  border: 0;\n  text-overflow: ellipsis;\n  font-family: ",
        ";\n  ",
        "\n  text-align: left;\n\n  &:disabled {\n    background-color: transparent;\n    cursor: not-allowed;\n  }\n\n  &:focus {\n    outline: none;\n  }\n\n  &::placeholder {\n    color: ",
        ";\n  }\n\n  &:-webkit-autofill,\n  &:-webkit-autofill:hover,\n  &:-webkit-autofill:focus,\n  &:-webkit-autofill:active {\n    box-shadow: 0 0 0 30px white inset !important;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: auto;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  width: ",
        ";\n  height: ",
        ";\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  flex-shrink: 0;\n  cursor: pointer;\n  appearance: none;\n  border-radius: ",
        ";\n  border: ",
        " solid ",
        ";\n  margin-right: ",
        ";\n  padding: 0;\n  transition: background-color 0.3s ease;\n\n  &::before {\n    display: block;\n    content: '';\n    background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 9'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M11 1 4.158 8 1 4.77'/%3E%3C/svg%3E\");\n    width: ",
        ";\n    height: ",
        ";\n  }\n\n  &:checked {\n    background-color: ",
        ";\n    border-color: ",
        ";\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  display: flex;\n  flex-direction: row;\n  cursor: pointer;\n  text-align: left;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  height: 18px;\n  padding: 0 8px;\n  margin-left: -5.008px;\n  background: white;\n  z-index: -2;\n  ",
        "\n  font-family: ",
        ";\n  text-align: left;\n  position: absolute;\n  transition: 0.2s;\n\n  ",
        ":not(:placeholder-shown) ~ &,\n  ",
        ":focus ~ & {\n    top: -10px;\n    ",
        "\n    z-index: 2;\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  max-width: 400px;\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  justify-content: space-between;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  width: 100%;\n  row-gap: 20px;\n  cursor: pointer;\n  border: 2px solid ",
        ";\n\n  section {\n    display: flex;\n  }\n\n  .label {\n    ",
        ";\n  }\n\n  .options {\n    margin-left: 30px;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n    span {\n      vertical-align: top;\n      margin-left: 5px;\n    }\n  }\n\n  input[type='radio'] {\n    appearance: none;\n    height: 25px;\n    width: 25px;\n    border: 1px solid ",
        ";\n    border-radius: 50%;\n    outline: none;\n    cursor: pointer;\n  }\n\n  input[type='radio']::before {\n    content: '';\n    display: block;\n    width: 100%;\n    height: 75%;\n    border-color: ",
        ";\n    border-radius: 50%;\n  }\n\n  input[type='radio']:checked::before {\n    background: ",
        ";\n    border-color: ",
        ";\n  }\n\n  input[type='radio']:checked {\n    border-color: ",
        ";\n    align-items: center;\n    display: flex;\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var StyledInputContainer = styled.div.withConfig({
    componentId: "sc-4a6641fb-0"
})(_templateObject(), function(param) {
    var isHidden = param.isHidden;
    return isHidden ? "none" : "block";
}, breakpoints.tablet, function(param) {
    var isColMiddle = param.isColMiddle;
    return isColMiddle ? "50%" : "100%";
});
export var StyledFormTextContainer = styled.div.withConfig({
    componentId: "sc-4a6641fb-1"
})(_templateObject1(), function(param) {
    var textAlign = param.textAlign;
    return textAlign || "left";
});
export var StyledFieldset = styled.div.withConfig({
    componentId: "sc-4a6641fb-2"
})(_templateObject2(), function(param) {
    var isError = param.isError, isDisabled = param.isDisabled;
    if (isError) {
        return "border: 1px solid ".concat(colors.$e60000);
    }
    return isDisabled ? "border: 1px solid #BEBEBE" : "border: 1px solid #BEBEBE";
}, breakpoints.mobile, breakpoints.tablet, function(props) {
    return props.isDisabled ? "not-allowed" : "pointer";
}, function(props) {
    return props.isDisabled ? "&:hover {cursor: not-allowed}" : "";
});
export var StyledCheckboxContainer = styled.div.withConfig({
    componentId: "sc-4a6641fb-3"
})(_templateObject3(), function(param) {
    var isHidden = param.isHidden;
    return isHidden ? "none" : "block";
}, breakpoints.tablet, function(param) {
    var isColMiddle = param.isColMiddle;
    return isColMiddle ? "50%" : "100%";
});
export var StyledInput = styled.input.withConfig({
    componentId: "sc-4a6641fb-4"
})(_templateObject4(), fonts.regular, pxToCssFontSize(18), colors.$262626, breakpoints.tablet);
export var StyledDropdown = styled.select.withConfig({
    componentId: "sc-4a6641fb-5"
})(_templateObject5(), fonts.regular, pxToCssFontSize(18), colors.$262626, breakpoints.tablet);
export var StyledCheckbox = styled.input.withConfig({
    componentId: "sc-4a6641fb-6"
})(_templateObject6(), pxToRem(20), pxToRem(20), pxToRem(3), pxToRem(1), colors.$bebebe, pxToRem(10), pxToRem(12), pxToRem(9), colors.$00697c, colors.$00697c);
export var StyledCheckboxLabel = styled.label.withConfig({
    componentId: "sc-4a6641fb-7"
})(_templateObject7(), pxToCssFont(16, 20));
export var StyledLegend = styled.label.withConfig({
    componentId: "sc-4a6641fb-8"
})(_templateObject8(), colors.$7e7e7e, pxToCssFontSize(18), fonts.regular, StyledInput, StyledInput, pxToCssFont(14, 18), colors.$262626);
export var StyledSection = styled.section.withConfig({
    componentId: "sc-4a6641fb-9"
})(_templateObject9());
export var StyledContainerButton = styled.div.withConfig({
    componentId: "sc-4a6641fb-10"
})(_templateObject10());
export var StyledRadioGroup = styled.div.withConfig({
    componentId: "sc-4a6641fb-11"
})(_templateObject11(), colors.$ffffff, pxToCssFont(18, 20), colors.$bebebe, colors.$00697c, colors.$00697c, colors.$00697c, colors.$00697c);
