import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var Svg = styled.svg.withConfig({
    componentId: "sc-d506efa1-0"
})(_templateObject(), function(param) {
    var shrinkable = param.shrinkable;
    return !shrinkable ? "flex-shrink: 0;" : "";
});
