import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  animation: fadein 2s;\n  margin-block: 0;\n  margin-inline: 0;\n  margin-bottom: -2px !important;\n  color: ",
        ";\n  ",
        "\n  font-family: ",
        ";\n  text-align: left;\n\n  @keyframes fadein {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: -8px !important;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var StyledErrorMessage = styled.p.withConfig({
    componentId: "sc-d2fca328-0"
})(_templateObject(), colors.$e60000, pxToCssFont(12, 24), fonts.regular, breakpoints.desktop);
