import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  border-top: 1px solid ",
        ";\n  display: flex;\n  width: 100%;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  width: 100%;\n\n  a {\n    color: #333 !important;\n  }\n\n  a:link {\n    width: 100%;\n    display: flex;\n    color: #333;\n  }\n\n  a:hover,\n  li:hover {\n    color: ",
        ";\n    text-underline-offset: 3px;\n    background-color: #f0f0f0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 10;\n  display: flex;\n  padding: 0;\n  margin: 0;\n  padding-left: 137px;\n  gap: 12px;\n  background-color: white;\n  width: 100%;\n\n  /* stylelint-disable-next-line no-descending-specificity */\n  li {\n    display: flex;\n    align-items: stretch;\n    list-style: none;\n\n    hovered {\n      color: #333;\n    }\n  }\n\n  a:link {\n    position: relative;\n    display: flex;\n    height: 50px;\n    align-items: center;\n    text-decoration: none;\n    color: ",
        ";\n    font-family: ",
        ";\n    font-style: normal;\n    font-weight: 400;\n    ",
        "\n    padding: 0 24px;\n  }\n\n  a:visited {\n    color: ",
        ";\n  }\n\n  a:hover,\n  li:hover {\n    background-color: #f0f0f0;\n    color: #333;\n  }\n\n  a:active {\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  top: 50px;\n  display: flex;\n  flex-direction: column;\n  padding: 0;\n  margin: 0;\n  background-color: ",
        ";\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-3f5357df-0"
})(_templateObject(), colors.$f2f2f2);
export var DropDownContent = styled.li.withConfig({
    componentId: "sc-3f5357df-1"
})(_templateObject1(), colors.$ffffff, colors.$e60000);
export var Menu = styled.ul.withConfig({
    componentId: "sc-3f5357df-2"
})(_templateObject2(), colors.$7e7e7e, fonts.regular, pxToCssFont(18, 26), colors.$7e7e7e, colors.$7e7e7e);
export var DropDownContainer = styled.ul.withConfig({
    componentId: "sc-3f5357df-3"
})(_templateObject3(), colors.$ffffff);
