import React from "react";
var observeHeight = function(ref) {
    var ref1, ref2, ref3, ref4;
    var body = (ref1 = document.getElementsByTagName("body")) === null || ref1 === void 0 ? void 0 : ref1[0];
    if (!body) return;
    var bodyWidth = (ref2 = body.getBoundingClientRect()) === null || ref2 === void 0 ? void 0 : ref2.width;
    var bodyHeight = (ref3 = body.getBoundingClientRect()) === null || ref3 === void 0 ? void 0 : ref3.height;
    var wrapperNote = null;
    body.childNodes.forEach(function(note) {
        var noteElement = note;
        if (noteElement.clientWidth === bodyWidth && noteElement.clientHeight === bodyHeight) {
            wrapperNote = note;
        }
    });
    wrapperNote && ((ref4 = wrapperNote) === null || ref4 === void 0 ? void 0 : ref4.childNodes.forEach(function(note) {
        var noteElement = note;
        if (noteElement.clientWidth === bodyWidth && noteElement.clientHeight >= bodyHeight) {
            noteElement.style.paddingBottom = "".concat(ref.current.clientHeight, "px");
        }
    }));
};
export { observeHeight };
