import { iFrameManager } from "@vfit/shared/providers";
export var openPopup = function(url, onClose, fullscreen, fullScreenExtended, icon, showIcon, showLogo, title, variant) {
    iFrameManager.handleIFrame({
        url: url,
        onClose: onClose,
        fullscreen: fullscreen,
        fullScreenExtended: fullScreenExtended,
        icon: icon,
        showIcon: showIcon,
        showLogo: showLogo,
        title: title,
        variant: variant
    });
};
