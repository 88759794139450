import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { checkWindow } from "@vfit/shared/data-access";
export var trackLink = function(linkName) {
    var event_category = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "users", event_action = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : "click", event_name = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : "ui interaction", tracking_type = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : "link", transaction_coupon_code = arguments.length > 5 && arguments[5] !== void 0 ? arguments[5] : "", product_discount_value = arguments.length > 6 && arguments[6] !== void 0 ? arguments[6] : "", product_discount_percentage = arguments.length > 7 && arguments[7] !== void 0 ? arguments[7] : "", cart_product_price = arguments.length > 8 && arguments[8] !== void 0 ? arguments[8] : "";
    var ref, ref1, ref2, ref3, ref4, ref5;
    var pageName = (ref = window) === null || ref === void 0 ? void 0 : (ref1 = ref.trackLink) === null || ref1 === void 0 ? void 0 : ref1.pageName;
    var event_label_track = linkName;
    if (pageName) {
        event_label_track = "".concat(pageName, " ").concat(linkName || "");
    }
    var payload = _object_spread({
        event_name: event_name,
        event_label_track: event_label_track,
        event_category: event_category,
        event_action: event_action,
        tracking_type: tracking_type
    }, transaction_coupon_code && {
        transaction_coupon_code: transaction_coupon_code
    }, product_discount_value && {
        product_discount_value: product_discount_value
    }, product_discount_percentage && {
        product_discount_percentage: product_discount_percentage
    }, cart_product_price && {
        cart_product_price: cart_product_price
    });
    if (checkWindow() && ((ref2 = window) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.trackLink) === null || ref3 === void 0 ? void 0 : ref3.track)) (ref4 = window.trackLink) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.track) === null || ref5 === void 0 ? void 0 : ref5.call(ref4, payload);
};
export var trackView = function(trackObject) {
    var ref, ref1, _trackView, ref2;
    if (checkWindow() && ((ref = window) === null || ref === void 0 ? void 0 : (ref1 = ref.trackView) === null || ref1 === void 0 ? void 0 : ref1.track)) (ref2 = (_trackView = window.trackView).track) === null || ref2 === void 0 ? void 0 : ref2.call(_trackView, trackObject);
};
export var setTrackLink = function(trackViewMethod, pageName) {
    if (!checkWindow()) return;
    window["trackLink"] = {
        track: trackViewMethod,
        pageName: pageName
    };
};
export var setTrackView = function(trackViewMethod) {
    if (!checkWindow()) return;
    window["trackView"] = {
        track: trackViewMethod
    };
};
