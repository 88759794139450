import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  z-index: 99;\n  ",
        ";\n  justify-content: center;\n  align-items: center;\n\n  @keyframes rotate {\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n\n  @keyframes dash {\n    0% {\n      stroke-dasharray: 1, 150;\n      stroke-dashoffset: 0;\n    }\n\n    50% {\n      stroke-dasharray: 90, 150;\n      stroke-dashoffset: -35;\n    }\n\n    100% {\n      stroke-dasharray: 90, 150;\n      stroke-dashoffset: -124;\n    }\n  }\n\n  & svg {\n    animation: rotate 2s linear infinite;\n    z-index: 2;\n    margin: -25px 0 0 -25px;\n    width: 50px;\n    height: 50px;\n\n    & circle {\n      stroke: ",
        ";\n      stroke-linecap: round;\n      fill: trasparent;\n      animation: dash 1.5s ease-in-out infinite;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { colors } from "@vfit/shared/themes";
import styled from "styled-components";
export var SpinnerContainer = styled.div.withConfig({
    componentId: "sc-304a6f4e-0"
})(_templateObject(), function(param) {
    var isChild = param.isChild;
    return !isChild ? "position: absolute; top: 50%; left: 50%" : "margin: 16px 0";
}, colors.$e60000);
