import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background-color: #000000c4;\n  bottom: 0;\n  left: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  z-index: 99;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  z-index: 99;\n\n  svg {\n    height: ",
        ";\n    margin: ",
        ";\n    width: ",
        ";\n\n    @media (min-width: ",
        ") {\n      height: ",
        ";\n      margin: ",
        ";\n      width: ",
        ";\n    }\n\n    @media (min-width: ",
        ") {\n      height: ",
        ";\n      margin: ",
        ";\n      width: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  left: 0;\n  overflow: hidden;\n  position: fixed;\n  right: 0;\n  top: 0;\n\n  .closing-icon {\n    margin-top: ",
        ";\n    margin-right: ",
        ";\n    position: absolute;\n    right: 0;\n\n    &:hover {\n      cursor: pointer;\n    }\n  }\n\n  svg {\n    height: ",
        ";\n    width: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    .closing-icon {\n      margin-top: ",
        ";\n      margin-right: ",
        ";\n    }\n\n    svg {\n      height: ",
        ";\n      width: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  height: calc(100vh - ",
        ");\n  margin: ",
        " auto ",
        ";\n  overflow: auto;\n  position: relative;\n  width: calc(100% - ",
        ");\n\n  @media (min-width: ",
        ") {\n    width: calc(100% - ",
        ");\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  cursor: pointer;\n  position: absolute;\n  right: 0;\n  top: 0;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { breakpoints, colors, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var Background = styled.div.withConfig({
    componentId: "sc-1859901-0"
})(_templateObject());
export var LogoWrapper = styled.div.withConfig({
    componentId: "sc-1859901-1"
})(_templateObject1(), pxToRem(30), pxToRem(16), pxToRem(30), breakpoints.tablet, pxToRem(32), pxToRem(12, 16), pxToRem(32), breakpoints.desktop, pxToRem(48), pxToRem(24, 80), pxToRem(48));
export var Container = styled.div.withConfig({
    componentId: "sc-1859901-2"
})(_templateObject2(), colors.$ffffff, pxToRem(21.5), pxToRem(21.5), pxToRem(17.33), pxToRem(17.33), breakpoints.tablet, pxToRem(48), pxToRem(38), pxToRem(19.5), pxToRem(19.5));
export var ModalContent = styled.div.withConfig({
    componentId: "sc-1859901-3"
})(_templateObject3(), pxToRem(48), pxToRem(32), pxToRem(16), pxToRem(32), breakpoints.desktop, pxToRem(226));
export var IconWrap = styled.span.withConfig({
    componentId: "sc-1859901-4"
})(_templateObject4());
