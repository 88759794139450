import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  row-gap: 8px;\n\n  @media (min-width: ",
        ") {\n    row-gap: 10px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  width: 8px;\n  cursor: ",
        ";\n  height: ",
        ";\n  border: ",
        ";\n  border-radius: ",
        ";\n  background-color: ",
        ";\n  transition: ",
        ";\n  transition-delay: ",
        ";\n\n  @media (min-width: ",
        ") {\n    width: 10px;\n    height: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { breakpoints, colors } from "@vfit/shared/themes";
import styled from "styled-components";
export var SectionStickContainer = styled.div.withConfig({
    componentId: "sc-beb17f02-0"
})(_templateObject(), breakpoints.tablet);
export var Stick = styled.div.withConfig({
    componentId: "sc-beb17f02-1"
})(_templateObject1(), function(param) {
    var active = param.active;
    return active ? "inherit" : "pointer";
}, function(param) {
    var active = param.active;
    return active ? "32px" : "8px";
}, function(param) {
    var active = param.active;
    return active ? "none" : "1px solid ".concat(colors.$ffffff);
}, function(param) {
    var active = param.active;
    return active ? "9px" : "20px";
}, function(param) {
    var active = param.active;
    return active ? colors.$ffffff : "inherit";
}, function(param) {
    var active = param.active;
    return active ? "all 0.6s ease-in-out" : "opacity .5s, background-color .5s, width .5s";
}, function(param) {
    var active = param.active;
    return active ? "0s" : ".5s, .5s, 0s";
}, breakpoints.tablet, function(param) {
    var active = param.active;
    return active ? "40px" : "10px";
});
