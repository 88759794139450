/* eslint-disable no-useless-escape */ /* eslint-disable radix */ /* eslint-disable no-param-reassign */ import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import * as yup from "yup";
import { LoggerInstance } from "@vfit/shared/providers";
export var regex = {
    // regexes currently used in the project
    msisdn: /^[0-9]+$/,
    phoneNumber: /^[0-9]*$/,
    city: /^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g,
    postalCode: /^\d+$/,
    stateOrProvince: /^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g,
    street: /^[a-zA-Z+"-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g,
    streetNumber: /^[a-z0-9+"/"+\s]+$/i,
    identificationNumber: /^[a-zA-Z0-9]*$/,
    expirationDate: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/,
    fiscalCode: /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/,
    firstName: /^[A-zÀ-ú ']*$/,
    lastName: /^[A-zÀ-ú ']*$/,
    documentNumber: /^[a-zA-Z0-9]*$/,
    // Commonly used Regex
    // Digits
    wholeNumbers: /^\d+$/,
    decimalNumbers: /^\d*\.\d+$/,
    wholeAndDecimalNumbers: /^\d*(\.\d+)?$/,
    negativePositiveWholeDecimalNumbers: /^-?\d*(\.\d+)?$/,
    wholeDecimalFractions: /[-]?[0-9]+[,.]?[0-9]*([\/][0-9]+[,.]?[0-9]*)*/,
    //  Alphanumeric Characters
    alphanumericWithoutSpace: /^[a-zA-Z0-9]*$/,
    alphanumericWithSpace: /^[a-zA-Z0-9 ]*$/,
    // EMAIL
    commonEmailIds: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
    uncommonEmailIds: /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/,
    // PASSWORD STRENGTH
    complexPassword: /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/,
    moderatePassword: /(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{8,}$/,
    // USERNAME
    alphanumericString: /^[a-z0-9_-]{3,16}$/,
    // URL
    includeHttpsProtocol: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/,
    protocolOptional: /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
    // IP
    IPv4Address: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
    IPv6Address: /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/,
    IPv4AndIPv6Addresses: /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/,
    // DATES
    DateFormatYYYY_MM_dd_UsingSeparator: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
    dateFormat_dd_MM_YYYYUsingSeparators: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
    dateFormat_dd_mmm_YYYYUsingSeparators: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
    // TIME
    timeFormatHH_MM_12_Hour: /^(0?[1-9]|1[0-2]):[0-5][0-9]$/,
    mobilePhoneNumber: /^(3)([0-9]{8,9})$/
};
export var configurationInputs = {
    fixedPhone: {
        regex: {
            value: regex.phoneNumber,
            message: "Inserire un numero valido"
        },
        min: {
            value: 10,
            message: "Campo errato"
        },
        max: {
            value: 11,
            message: "Campo errato"
        },
        string: true,
        required: "Campo obbligatorio"
    },
    // TODO: Re-evaluate validation when error designs are available
    msisdn: {
        string: true,
        regex: {
            value: /^[0-9]+$/
        },
        min: {
            value: 9,
            message: "Il numero inserito \xe8 troppo corto"
        },
        max: {
            value: 10,
            message: "Il numero inserito \xe8 troppo lungo"
        },
        required: "Campo obbligatorio"
    },
    phoneNumber: {
        string: true,
        required: "Campo obbligatorio",
        min: {
            value: 9,
            message: "Inserire un numero valido"
        },
        max: {
            value: 10,
            message: "Inserire un numero valido"
        },
        regex: {
            value: regex.mobilePhoneNumber,
            message: "Inserire un numero valido"
        }
    },
    city: {
        regex: {
            value: /^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g
        },
        string: true,
        min: {
            value: 1,
            message: "Campo obbligatorio"
        },
        required: "Campo obbligatorio"
    },
    postalCode: {
        regex: {
            value: /^\d+$/
        },
        string: true,
        min: {
            value: 1,
            message: "Campo obbligatorio"
        },
        required: "Campo obbligatorio"
    },
    stateOrProvince: {
        regex: {
            value: /^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g
        },
        string: true,
        min: {
            value: 1,
            message: "Campo obbligatorio"
        },
        required: "Campo obbligatorio"
    },
    street: {
        regex: {
            value: /^[a-zA-Z+"-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g
        },
        string: true,
        min: {
            value: 1,
            message: "Campo obbligatorio"
        },
        required: "Campo obbligatorio"
    },
    streetNumber: {
        regex: {
            value: /^[a-z0-9+"/"+\s]+$/i
        },
        string: true,
        min: {
            value: 1,
            message: "Campo obbligatorio"
        },
        required: "Campo obbligatorio"
    },
    identificationNumber: {
        regex: {
            value: /^[a-zA-Z0-9]*$/
        },
        string: true,
        min: {
            value: 6,
            message: "Inserire un numero di documento valido"
        },
        max: {
            value: 15,
            message: "Inserire un numero di documento valido"
        },
        required: "Campo obbligatorio"
    },
    expirationDate: {
        // regex: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/,
        string: true,
        required: "Campo obbligatorio ",
        test: {
            field: "rangeTest",
            message: "Inserire una data di scadenza valida - made with capability",
            fn: function(value) {
                if ((value === null || value === void 0 ? void 0 : value.includes("_")) === false) {
                    if (value.match(/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/)) {
                        var today = new Date();
                        var dmy = value.split("/");
                        var selectedDate = new Date(parseInt(dmy[2]), parseInt(dmy[1]) - 1, parseInt(dmy[0]));
                        var yesterday = new Date();
                        yesterday.setDate(today.getDate() - 1);
                        var tenYears = new Date();
                        tenYears.setFullYear(today.getFullYear() + 10);
                        if (selectedDate > yesterday && selectedDate <= tenYears) return true;
                        return false;
                    }
                    return false;
                }
                return false;
            }
        }
    },
    nationality: {
        string: true,
        required: "Campo obbligatorio "
    },
    identificationType: {
        string: true,
        required: "Campo obbligatorio "
    },
    fiscalCode: {
        string: true,
        min: {
            value: 1,
            message: "Campo Obbligatorio"
        },
        max: {
            value: 16,
            message: "Inserire un codice fiscale valido"
        },
        required: "Campo obbligatorio ",
        test: {
            field: "fiscalCode",
            message: "Inserire un codice fiscale valido - made with capability",
            fn: function(value) {
                if ((value === null || value === void 0 ? void 0 : value.length) === 16) if (value === null || value === void 0 ? void 0 : value.match(/^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/)) return true;
                else return false;
                return true;
            }
        }
    },
    firstName: {
        required: "Campo obbligatorio required ",
        string: true,
        regex: {
            value: /^[A-zÀ-ú ']*$/,
            message: "Inserire un nome valido"
        },
        min: {
            value: 1,
            message: "Campo obbligatorio"
        }
    },
    lastName: {
        required: "Campo obbligatorio ",
        string: true,
        regex: {
            value: /^[A-zÀ-ú ']*$/,
            message: "Inserire un cognome valido"
        },
        min: {
            value: 1,
            message: "Campo obbligatorio del min"
        }
    },
    documentNumber: {
        regex: {
            value: /^[a-zA-Z0-9]*$/,
            message: "Inserire un numero di documento valido"
        },
        min: {
            value: 6,
            message: "Inserire un numero di documento valido"
        },
        max: {
            value: 15,
            message: "Inserire un numero di documento valido"
        },
        string: true,
        required: "Campo obbligatorio"
    },
    emailAddress: {
        string: true,
        required: "Campo obbligatorio",
        email: "inserire email nel formato corretto "
    },
    placeId: {
        string: true,
        required: "Campo obbligatorio"
    }
};
var normalizeInput = function(input) {
    if (input.slice(-1) === "*") return input.slice(0, -1);
    return input;
};
var handleInputsObject = function(tempObj, inputLabel) {
    var ref, ref1, ref2, ref3, ref4;
    var input = normalizeInput(inputLabel);
    tempObj = _object_spread_props(_object_spread({}, tempObj), _define_property({}, input, yup));
    var designedInput = configurationInputs[input];
    var isRequired = inputLabel.slice(-1) === "*" && designedInput.required;
    if (designedInput.string) tempObj[input] = tempObj[input].string();
    if (designedInput.min) tempObj[input] = tempObj[input].min(designedInput.min.value, designedInput.min.message);
    if (designedInput.max) tempObj[input] = tempObj[input].max(designedInput.max.value, designedInput.max.message);
    if (designedInput.regex) tempObj[input] = tempObj[input].matches(((ref = designedInput.regex) === null || ref === void 0 ? void 0 : ref.value) || designedInput.regex, ((ref1 = designedInput.regex) === null || ref1 === void 0 ? void 0 : ref1.message) || "Campo errato");
    if (isRequired) tempObj[input] = tempObj[input].required(isRequired);
    if (designedInput.test) tempObj[input] = tempObj[input].test(((ref2 = designedInput.test) === null || ref2 === void 0 ? void 0 : ref2.field) || null, ((ref3 = designedInput.test) === null || ref3 === void 0 ? void 0 : ref3.message) || null, ((ref4 = designedInput.test) === null || ref4 === void 0 ? void 0 : ref4.fn) || designedInput.test);
    return tempObj;
};
export var defineYupSchema = function(inputs) {
    var tempObj = {};
    if (Array.isArray(inputs)) {
        inputs.forEach(function(input) {
            tempObj = handleInputsObject(tempObj, input);
        });
    } else if (configurationInputs[inputs]) {
        tempObj = handleInputsObject(tempObj, inputs);
    }
    return yup.object(_object_spread({}, tempObj));
};
export var getYupSchema = function(inputs) {
    if (Array.isArray(inputs)) {
        inputs.forEach(function(input) {
            var normalizedInput = normalizeInput(input);
            var designedInput = configurationInputs[normalizedInput];
            if (designedInput) {
                return defineYupSchema(inputs);
            }
            LoggerInstance.debug("error no input found", input, configurationInputs);
        });
    } else if (configurationInputs[inputs]) {
        return defineYupSchema(inputs);
    } else {
        LoggerInstance.debug("error no input found", inputs, configurationInputs);
    }
    return console.error("get yup schema not work!");
};
