import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  border-spacing: 10px;\n  display: flex;\n  gap: 16px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  background: ",
        ";\n  box-shadow: ",
        ";\n  border-radius: 6px;\n  color: #333;\n  font-style: normal;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-style: normal;\n  width: stretch;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  align-self: center;\n  font-weight: 400;\n  font-family: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  align-self: center;\n  text-align: center;\n  font-weight: 400;\n  font-family: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  align-self: center;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, shadows, pxToCssFont, pxToCssFontSize } from "@vfit/shared/themes";
export var ContentRow = styled.div.withConfig({
    componentId: "sc-15303606-0"
})(_templateObject());
export var CardContainer = styled.div.withConfig({
    componentId: "sc-15303606-1"
})(_templateObject1(), colors.$ffffff, shadows.card_overlay);
export var ItemRow = styled.div.withConfig({
    componentId: "sc-15303606-2"
})(_templateObject2(), fonts.regular, pxToCssFontSize(16), breakpoints.desktop, pxToCssFont(18, 24));
export var Title = styled.span.withConfig({
    componentId: "sc-15303606-3"
})(_templateObject3(), fonts.exbold, breakpoints.tablet, pxToCssFont(16, 22), breakpoints.desktop, pxToCssFont(20, 30));
export var Body = styled.span.withConfig({
    componentId: "sc-15303606-4"
})(_templateObject4(), fonts.regular, breakpoints.mobile, pxToCssFont(12, 16), breakpoints.desktop, pxToCssFont(16, 22));
export var Icon = styled.div.withConfig({
    componentId: "sc-15303606-5"
})(_templateObject5());
export var Content = styled.div.withConfig({
    componentId: "sc-15303606-6"
})(_templateObject6());
