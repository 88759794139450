import foreignCountries from "./foreign-countries.json";
import italianMunicipalities from "./italian-municipalities.json";
import italianProvinces from "./italian-provinces.json";
import { dateCode, getCheckCode, getValidDate, nameCode, surnameCode } from "./fiscal-code.utils";
var getForeignCountries = function() {
    return foreignCountries;
};
// https://github.com/matteocontrini/comuni-json/blob/master/comuni.json
var getItalianMunicipalities = function() {
    return italianMunicipalities;
};
var getItalianProvinces = function() {
    return italianProvinces;
};
var calculateFiscalCode = function(name, surname, gender, dayOfBirth, monthOfBirth, yearOfBirth, country) {
    var code = country.code;
    var surnameStr = surnameCode(surname).substr(0, 3);
    var nameStr = nameCode(name).substr(0, 3);
    try {
        var birthday = getValidDate(dayOfBirth, monthOfBirth, yearOfBirth);
        var dateStr = dateCode("0".concat(birthday.getDate()).slice(-2).toString(), birthday.getMonth() + 1, birthday.getFullYear(), gender);
        var cf = "".concat(surnameStr).concat(nameStr).concat(dateStr).concat(code);
        var checkCode = getCheckCode(cf);
        return "".concat(cf).concat(checkCode);
    } catch (error) {
        return "";
    }
};
export { getForeignCountries, getItalianMunicipalities, getItalianProvinces, calculateFiscalCode };
