import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  font-style: normal;\n  color: ",
        ";\n  width: 287px;\n\n  @media (min-width: ",
        ") {\n    width: 366px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 406px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  text-align: center;\n  ",
        "\n  margin-bottom: 16px;\n  margin-top: 36px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  text-align: center;\n  ",
        "\n  margin-bottom: 32px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 26px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 40px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  text-align: start;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  margin-bottom: 16px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 20px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  text-align: start;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  padding-left: 34px;\n  margin-bottom: 16px;\n  position: relative;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: inline-block;\n  ",
        "\n  margin: 0;\n  text-decoration: underline;\n\n  i {\n    font-style: normal;\n    text-decoration: none;\n    position: absolute;\n    top: ",
        "px;\n    left: 7px;\n    transform: translateY(-50%);\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin-bottom: 36px;\n  text-align: left;\n  margin-top: 24px;\n\n  @media (min-width: ",
        ") {\n    margin-top: 24px;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 40px;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: 22px;\n  margin-right: 10px;\n\n  @media (min-width: ",
        ") and (max-width: ",
        ") {\n    margin-top: 2px;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  input {\n    position: absolute;\n    margin: 0 auto;\n    opacity: 0;\n    cursor: pointer;\n    height: 20px;\n    width: 20px;\n    z-index: 3;\n  }\n\n  .checkmark::after {\n    left: 7px;\n    top: 3px;\n    width: 4px;\n    height: 9px;\n    border: solid white;\n    border-width: 0 2px 2px 0;\n    transform: rotate(45deg);\n    content: '';\n    position: absolute;\n    display: none;\n  }\n\n  .checkmark {\n    position: absolute;\n    height: 20px;\n    width: 20px;\n    border-radius: 3px;\n    border: 1px solid #bebebe;\n  }\n\n  &:hover input ~ .checkmark {\n    border: 1px solid #bebebe;\n  }\n\n  input:checked ~ .checkmark {\n    background-color: #00697c;\n    border: none;\n  }\n\n  input:checked ~ .checkmark::after {\n    display: block;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  text-align: start;\n  font-family: ",
        ";\n  font-weight: 700;\n  ",
        "\n  width: 256px;\n\n  @media (min-width: ",
        ") {\n    width: 334px;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    width: 374px;\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-8dc76516-0"
})(_templateObject(), colors.$262626, breakpoints.tablet, breakpoints.desktop);
export var Title = styled.div.withConfig({
    componentId: "sc-8dc76516-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Description = styled.div.withConfig({
    componentId: "sc-8dc76516-2"
})(_templateObject2(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30), breakpoints.desktop);
export var Subtitle = styled.div.withConfig({
    componentId: "sc-8dc76516-3"
})(_templateObject3(), fonts.exbold, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(24, 30));
export var ListEl = styled.li.withConfig({
    componentId: "sc-8dc76516-4"
})(_templateObject4(), fonts.regular, pxToCssFont(16, 22), breakpoints.tablet, pxToCssFont(18, 24));
var ListElTitleHeight = 22;
export var ListElTitle = styled.h3.withConfig({
    componentId: "sc-8dc76516-5"
})(_templateObject5(), pxToCssFont(16, ListElTitleHeight), ListElTitleHeight / 2);
export var DetailButton = styled.div.withConfig({
    componentId: "sc-8dc76516-6"
})(_templateObject6(), breakpoints.tablet, pxToCssFont(18, 26), breakpoints.desktop);
export var CheckboxDiv = styled.div.withConfig({
    componentId: "sc-8dc76516-7"
})(_templateObject7(), breakpoints.tablet, breakpoints.desktop);
export var Checkbox = styled.div.withConfig({
    componentId: "sc-8dc76516-8"
})(_templateObject8());
export var DocsDiv = styled.div.withConfig({
    componentId: "sc-8dc76516-9"
})(_templateObject9());
export var DocCheck = styled.div.withConfig({
    componentId: "sc-8dc76516-10"
})(_templateObject10(), fonts.regular, pxToCssFont(16, 22), breakpoints.tablet, pxToCssFont(18, 26), breakpoints.desktop);
