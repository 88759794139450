import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  z-index: ",
        ";\n  ",
        ";\n  background-color: #4a4d4e;\n  height: ",
        ";\n  border-radius: 0 0 20px 20px;\n  overflow: hidden;\n\n  @media (min-width: ",
        ") {\n    border-radius: 0 0 30px 30px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { breakpoints } from "@vfit/shared/themes";
import styled from "styled-components";
export var SliderWrapper = styled.div.withConfig({
    componentId: "sc-5a9c9f6b-0"
})(_templateObject(), function(param) {
    var zIndex = param.zIndex;
    return zIndex;
}, function(param) {
    var headerHeight = param.headerHeight, indexSlide = param.indexSlide;
    return indexSlide > 0 ? "\n    margin-top: -".concat(headerHeight, "px;\n  ") : "";
}, function(param) {
    var viewportHeight = param.viewportHeight;
    return viewportHeight ? "".concat(viewportHeight, "px") : "100vh";
}, breakpoints.tablet);
