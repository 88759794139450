import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    to {\n      transform: rotate(360deg);\n    }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { keyframes } from "styled-components";
export var spin = keyframes(_templateObject());
