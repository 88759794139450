import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  color: ",
        ";\n\n  img {\n    width: 18px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    margin-bottom: 24px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  text-align: center;\n  margin-bottom: 80px;\n  button {\n    margin: 0 auto;\n    width: auto;\n    max-width: 400px;\n    span {\n      overflow: hidden;\n      text-overflow: ellipsis;\n      width: 100%;\n      display: block;\n    }\n  }\n  white-space: nowrap;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, fonts } from "@vfit/shared/themes";
export var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-bdfecfee-0"
})(_templateObject(), fonts.regular, colors.$7e7e7e);
export var ModalTitle = styled.div.withConfig({
    componentId: "sc-bdfecfee-1"
})(_templateObject1());
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-bdfecfee-2"
})(_templateObject2());
export var ModalContent = styled.div.withConfig({
    componentId: "sc-bdfecfee-3"
})(_templateObject3());
