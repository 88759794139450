import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  font-size: 1rem; /* 16px */\n  line-height: 1.4; /* 16/22 */\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    ",
        "\n  "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    ",
        "\n  "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    ",
        "\n    \n    &:hover {\n      ",
        "\n    }\n  "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    ",
        "\n  "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
import { colors } from "../../colors";
import { cssfonts } from "../cssfonts";
var common = css(_templateObject());
export var body2 = {
    extrabold: css(_templateObject1(), cssfonts.exbold, common),
    bold: css(_templateObject2(), cssfonts.bold, common),
    link: css(_templateObject3(), cssfonts.bold, common, colors.$e60000),
    regular: css(_templateObject4(), cssfonts.regular, common)
};
