import { useEffect, useState } from "react";
import { useDeviceType } from "@vfit/shared/hooks";
export var useHeaderBanner = function(param) {
    var htmlDesktop = param.htmlDesktop, _htmlMobile = param.htmlMobile, htmlMobile = _htmlMobile === void 0 ? "" : _htmlMobile, _htmlTablet = param.htmlTablet, htmlTablet = _htmlTablet === void 0 ? "" : _htmlTablet;
    var ref = useDeviceType(), isMobile = ref.isMobile, isTablet = ref.isTablet, isBigDesktop = ref.isBigDesktop, isDesktop = ref.isDesktop;
    var getHtmlByDevice = function() {
        if (isDesktop || isBigDesktop) {
            return htmlDesktop || "";
        }
        if (isMobile) {
            return htmlMobile;
        }
        return htmlTablet;
    };
    var ref1 = useState(getHtmlByDevice()), html = ref1[0], setHtml = ref1[1];
    useEffect(function() {
        setHtml(getHtmlByDevice());
    }, [
        isMobile,
        isTablet,
        isBigDesktop,
        isDesktop
    ]);
    return {
        htmlPurified: html
    };
};
