import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-left: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  border-bottom: 1px solid\n    ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  height: 69px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin-top: 16px;\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin-left: ",
        ";\n  font-family: ",
        ";\n  ",
        ";\n  font-weight: ",
        ";\n  cursor: pointer;\n  color: ",
        ";\n  text-decoration: none;\n  padding: 1rem 0;\n  background: ",
        ";\n  line-height: ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin-left: ",
        ";\n  padding-top: 12px;\n  padding-bottom: 12px;\n  font-size: ",
        ";\n  font-weight: ",
        ";\n  color: ",
        ";\n  font-family: ",
        ";\n  line-height: ",
        ";\n  text-decoration: none;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToFontSize } from "@vfit/shared/themes";
import { AccordionNavigationType } from "@vfit/shared/models";
export var AccordionNavigationContainer = styled.div.withConfig({
    componentId: "sc-44bc2ea4-0"
})(_templateObject(), function(param) {
    var enableMargin = param.enableMargin, marginLeft = param.marginLeft;
    return enableMargin ? marginLeft || "24px" : 0;
});
export var AccordionNavigationInnerContainer = styled.div.withConfig({
    componentId: "sc-44bc2ea4-1"
})(_templateObject1(), function(param) {
    var accordionNavigationType = param.accordionNavigationType;
    return accordionNavigationType === AccordionNavigationType.MENU ? "#F2F2F2" : "#BEBEBE";
}, breakpoints.tablet, function(param) {
    var accordionNavigationType = param.accordionNavigationType;
    return accordionNavigationType === AccordionNavigationType.FOOTER && ":last-child {\n      border-bottom: none;\n    }";
});
export var DefaultLinkWrapper = styled(AccordionNavigationInnerContainer).withConfig({
    componentId: "sc-44bc2ea4-2"
})(_templateObject2());
export var AccordionLinksContent = styled.div.withConfig({
    componentId: "sc-44bc2ea4-3"
})(_templateObject3());
export var AccordionNavigationLink = styled.a.withConfig({
    componentId: "sc-44bc2ea4-4"
})(_templateObject4(), function(param) {
    var enableMargin = param.enableMargin, leftMargin = param.leftMargin;
    return enableMargin ? leftMargin || "24px" : 0;
}, function(param) {
    var fontFamily = param.fontFamily;
    return fontFamily || fonts.regular;
}, function(param) {
    var fontSize = param.fontSize;
    return fontSize || pxToFontSize(16);
}, function(param) {
    var fontWeight = param.fontWeight;
    return fontWeight || 400;
}, function(param) {
    var color = param.color;
    return color || colors.$262626;
}, function(param) {
    var background = param.background;
    return background || colors.$ffffff;
}, function(param) {
    var lineHeight = param.lineHeight;
    return lineHeight || "inherit";
});
export var AccordionNavigationDefaultLink = styled.a.withConfig({
    componentId: "sc-44bc2ea4-5"
})(_templateObject5(), function(param) {
    var enableMargin = param.enableMargin, leftMargin = param.leftMargin;
    return enableMargin ? leftMargin || "24px" : 0;
}, function(param) {
    var fontSizeLinks = param.fontSizeLinks;
    return fontSizeLinks || pxToFontSize(16);
}, function(param) {
    var fontWeightLinks = param.fontWeightLinks;
    return fontWeightLinks || 400;
}, function(param) {
    var colorLinks = param.colorLinks;
    return colorLinks || colors.$262626;
}, function(param) {
    var fontFamilyLinks = param.fontFamilyLinks;
    return fontFamilyLinks || fonts.regular;
}, function(param) {
    var lineHeight = param.lineHeight;
    return lineHeight || "inherit";
});
