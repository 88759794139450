import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  display: flex;\n  top: 0;\n  left: 0;\n  z-index: 4;\n  width: 100%;\n  border-bottom: 1px solid ",
        ";\n\n  nav {\n    width: 100%;\n    background-color: ",
        ";\n  }\n\n  + div {\n    padding-top: ",
        "px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  body {\n  overflow: hidden;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n  flex-basis: 0;\n  padding-top: 24px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  flex-grow: 1;\n  width: 25%;\n\n  p {\n    margin: 0;\n    margin-bottom: 18px;\n    font-family: ",
        ";\n    color: white;\n    ",
        "\n    font-weight: 700;\n  }\n\n  div {\n    display: flex;\n    flex-direction: column;\n\n    a {\n      color: white;\n      ",
        "\n      font-weight: 400;\n      font-family: ",
        ";\n      text-decoration: none;\n      margin-bottom: 5px;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled, { createGlobalStyle } from "styled-components";
import { colors, fonts, pxToCssFontSize } from "@vfit/shared/themes";
export var MainContainer = styled.header.withConfig({
    componentId: "sc-e4360b12-0"
})(_templateObject(), colors.$f2f2f2, colors.$ffffff, function(param) {
    var pt = param.pt;
    return "".concat(pt);
});
export var OverlayGlobalStyle = createGlobalStyle(_templateObject1());
export var FooterLinks = styled.div.withConfig({
    componentId: "sc-e4360b12-1"
})(_templateObject2());
export var FooterLinksCol = styled.div.withConfig({
    componentId: "sc-e4360b12-2"
})(_templateObject3(), fonts.regular, pxToCssFontSize(20), pxToCssFontSize(18), fonts.regular);
