import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  div:not(:last-child) {\n    margin-bottom: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var Container = styled.div.withConfig({
    componentId: "sc-a5fbc7db-0"
})(_templateObject(), function(p) {
    return "".concat(p.itemsDistance, "px");
});
