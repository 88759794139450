import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding: 32px 24px;\n  gap: 24px;\n  width: 327px;\n  min-height: ",
        ";\n  visibility: ",
        ";\n  background: ",
        ";\n  border: 1px solid ",
        ";\n  border-radius: 20px;\n  flex: none;\n  order: 1;\n  align-self: stretch;\n  flex-grow: 0;\n\n  @media (min-width: ",
        ") {\n    margin: 16px;\n  }\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    align-items: center;\n    padding: 0 32px;\n    gap: 32px;\n    width: 405px;\n    margin: 0;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") {\n    margin: 32px 0;\n  }\n\n  div {\n    font-weight: 700;\n    margin: 0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") {\n    margin-left: 32px;\n  }\n\n  svg {\n    width: 3.82px;\n    height: 7.64px;\n\n    @media (min-width: ",
        ") {\n      width: 5.45px;\n      height: 10.91px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import { breakpoints, colors } from "@vfit/shared/themes";
import styled from "styled-components";
export var DocumentItemWrapper = styled.div.withConfig({
    componentId: "sc-32b4b2ee-0"
})(_templateObject(), function(param) {
    var height = param.height;
    return "".concat(height || 149, "px");
}, function(param) {
    var visibility = param.visibility;
    return visibility || "inherit";
}, colors.$ffffff, colors.$bebebe, breakpoints.tablet, breakpoints.desktop);
export var DocumentItemTitleWrapper = styled.div.withConfig({
    componentId: "sc-32b4b2ee-1"
})(_templateObject1(), breakpoints.desktop);
export var DocumentButtonItemWrapper = styled.div.withConfig({
    componentId: "sc-32b4b2ee-2"
})(_templateObject2(), breakpoints.desktop, breakpoints.desktop);
