import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { API } from "@vfit/shared/data-access";
import { LoggerInstance, fwvClient } from "@vfit/shared/providers";
import { useMutation } from "react-query";
var customerEligibilityService = function(param) {
    var token = param.token, startFlow = param.startFlow;
    return fwvClient.post(API.CUSTOMER_ELIGIBILITY, _object_spread({
        t: token
    }, startFlow && {
        startFlow: startFlow
    }));
};
export var useCustomerEligibility = function() {
    return useMutation([
        "checkCustomerEligibility"
    ], function(data) {
        return customerEligibilityService(data);
    }, {
        onSuccess: function(data) {
            LoggerInstance.debug(data);
        },
        onError: function(error) {
            LoggerInstance.debug(error);
        }
    });
};
