import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-top: ",
        ";\n  font-family: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { fonts } from "@vfit/shared/themes";
var ContainerTopProduct = styled.div.withConfig({
    componentId: "sc-b464559-0"
})(_templateObject(), function(param) {
    var haveTitle = param.haveTitle;
    return haveTitle ? "45px" : 0;
}, fonts.regular);
export default ContainerTopProduct;
