import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-flow: row wrap;\n  row-gap: 10px;\n  margin-top: 12px;\n\n  & > div {\n    margin-right: 8px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 24px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { breakpoints } from "@vfit/shared/themes";
import styled from "styled-components";
export var PillsContainer = styled.div.withConfig({
    componentId: "sc-399e1fe3-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
