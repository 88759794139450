export var EVENTS_TYPE = [
    "COOKIE_PREFERENCES"
];
export var COOKIE_PREFERENCES_FALLBACK_URL = "https://www.vodafone.it/portal/Privati/Area-Privacy/La-nostra-informativa?icmp=cbu_web_FOOTER_preferenze-cookie";
export var ONCLICK_EVENTS = {
    COOKIE_PREFERENCES: function() {
        if (!window.Optanon) {
            window.open(COOKIE_PREFERENCES_FALLBACK_URL, "_blank");
            return;
        }
        try {
            var oneTrustConsentSdk = document.querySelector("#onetrust-consent-sdk");
            if (oneTrustConsentSdk) {
                var ref, ref1, ref2, ref3;
                (ref = oneTrustConsentSdk) === null || ref === void 0 ? void 0 : (ref1 = ref.style) === null || ref1 === void 0 ? void 0 : ref1.removeProperty("display");
                (ref2 = oneTrustConsentSdk) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.style) === null || ref3 === void 0 ? void 0 : ref3.removeProperty("z-index");
            }
            window.Optanon.ToggleInfoDisplay();
        } catch (e) {
            console.log(e);
        }
    }
};
