import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  .loading {\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    min-height: ",
        ";\n    min-width: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    min-height: ",
        ";\n    min-width: ",
        ";\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, pxToRem } from "@vfit/shared/themes";
export var ClickToCallCardFlow = styled.div.withConfig({
    componentId: "sc-eebe97e4-0"
})(_templateObject());
export var LoadingContainer = styled.div.withConfig({
    componentId: "sc-eebe97e4-1"
})(_templateObject1(), breakpoints.tablet, pxToRem(313), pxToRem(272), breakpoints.desktop, pxToRem(313), pxToRem(406), pxToRem(0, 0, 22, 0));
