import { useAnimation } from "@vfit/shared/hooks";
import { colors } from "@vfit/shared/themes";
import { useMemo } from "react";
export var useSideIcon = function(param) {
    var disabled = param.disabled, error = param.error, loading = param.loading;
    var iconLoadingA = useAnimation({
        isInfinite: true,
        prefix: "icon-loading",
        show: !!loading
    });
    var iconErrorA = useAnimation({
        prefix: "icon-error",
        show: !!error
    });
    var iconColor = useMemo(function() {
        switch(true){
            case !!error:
                return colors.$bd0000;
            case disabled:
                return colors.$7e7e7e;
            default:
                return colors.$0d0d0d;
        }
    }, [
        disabled,
        error
    ]);
    return {
        disabled: disabled,
        iconColor: iconColor,
        iconErrorA: iconErrorA,
        iconLoadingA: iconLoadingA
    };
};
