import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  animation: fadein 2s;\n  margin-block: 0;\n  margin-inline: 0;\n  margin-bottom: -2px !important;\n  color: ",
        ";\n  ",
        "\n  font-family: ",
        ";\n  text-align: left;\n\n  @keyframes fadein {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: -8px !important;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: 60px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  margin: 0;\n  padding: 18px 16px 18px 20px;\n  height: 60px;\n  display: flex;\n  ",
        ";\n  transition: border 2s;\n  border-radius: 5px;\n  background: transparent;\n\n  .wrapper {\n    display: inline-flex;\n    width: 100%;\n    margin: 0;\n    padding: 0;\n  }\n\n  .wrapper-icon {\n    display: flex;\n    align-self: center;\n  }\n\n  .icon {\n    margin: auto;\n    display: flex;\n    flex-direction: column;\n    appearance: none;\n    background: none;\n    border: none;\n    font-family: inherit;\n    font-size: inherit;\n    padding: 0;\n\n    &:hover {\n      cursor: ",
        ";\n    }\n  }\n\n  ",
        "\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  height: 22px;\n\n  dateInput {\n    width: 100%;\n  }\n\n  input {\n    background-color: transparent;\n    margin: auto;\n    padding: 0;\n    width: 100%;\n    outline: 0;\n    border: 0;\n    ",
        "\n    font-family: ",
        ";\n    text-align: left;\n\n    &:disabled {\n      background-color: transparent;\n      cursor: not-allowed;\n    }\n\n    &:focus {\n      outline: none;\n    }\n\n    &:-webkit-autofill,\n    &:-webkit-autofill:hover,\n    &:-webkit-autofill:focus,\n    &:-webkit-autofill:active {\n      box-shadow: 0 0 0 30px white inset !important;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  padding: 0 8px;\n  margin-left: -5.008px;\n  background: white;\n  z-index: -2;\n  ",
        "\n  font-family: ",
        ";\n  text-align: left;\n  position: absolute;\n  transition: 0.2s;\n  ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from "@vfit/shared/themes";
export var ErrorMessage = styled.p.withConfig({
    componentId: "sc-fad52d1a-0"
})(_templateObject(), colors.$e60000, pxToCssFont(12, 24), fonts.regular, breakpoints.desktop);
export var FieldsetWrap = styled.div.withConfig({
    componentId: "sc-fad52d1a-1"
})(_templateObject1());
export var Fieldset = styled.div.withConfig({
    componentId: "sc-fad52d1a-2"
})(_templateObject2(), function(props) {
    if (props.error) {
        return "border: 1px solid ".concat(colors.$e60000);
    }
    return props.isDisabled ? "border: 1px solid #999" : "border: 1px solid #BEBEBE";
}, function(props) {
    return props.isDisabled ? "not-allowed" : "pointer";
}, function(props) {
    return props.isDisabled ? "&:hover {cursor: not-allowed}" : "";
});
export var Field = styled.div.withConfig({
    componentId: "sc-fad52d1a-3"
})(_templateObject3(), pxToCssFontSize(18), fonts.regular);
export var Legend = styled.label.withConfig({
    componentId: "sc-fad52d1a-4"
})(_templateObject4(), colors.$7e7e7e, pxToCssFontSize(18), fonts.regular, function(props) {
    return props.raiseLabel ? "\n        top: -10px;\n        ".concat(pxToCssFont(14, 18), "\n        z-index:2;\n        color: ").concat(colors.$262626, ";\n      ") : "";
});
