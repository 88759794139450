import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background-color: #4a4d4e;\n  height: 24px;\n  /* stylelint-disable-next-line value-no-vendor-prefix */\n  display: -webkit-box;\n\n  @media (min-width: ",
        ") {\n    height: 29px;\n  }\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    justify-content: space-between;\n    height: 34px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding: 0;\n  margin: 0;\n  display: flex;\n\n  @media (min-width: ",
        ") {\n    margin-left: 213px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-left: 0;\n  margin-right: 113px;\n\n  a {\n    padding: 0;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin-left: 17px;\n\n  a {\n    padding: 0 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  list-style: none;\n  display: flex;\n\n  a {\n    padding: 0 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: ",
        ";\n\n    @media (max-width: ",
        ") {\n      white-space: nowrap;\n    }\n  }\n\n  a:hover,\n  a:active {\n    color: ",
        ";\n  }\n\n  a:link {\n    text-decoration: none;\n    font-family: ",
        ";\n    font-style: normal;\n    font-weight: 400;\n    ",
        "\n  }\n\n  a:link:hover,\n  .selected {\n    background-color: ",
        ";\n    color: ",
        ";\n  }\n\n  a:link:active {\n    background-color: ",
        ";\n    color: ",
        ";\n  }\n\n  &:active {\n    background-color: ",
        ";\n  }\n\n  &:hover {\n    path {\n      fill: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin-right: 11px;\n\n  img {\n    object-fit: contain;\n    width: 10px;\n    height: 12.7px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  hover {\n    cursor: pointer;\n    background-color: ",
        ";\n\n    /* stylelint-disable no-descending-specificity */\n    a {\n      color: ",
        ";\n    }\n\n    img {\n      filter: brightness(30%);\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-6858625d-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var CustomerTypeBox = styled.ul.withConfig({
    componentId: "sc-6858625d-1"
})(_templateObject1(), breakpoints.desktop);
export var OtherInfoBox = styled(CustomerTypeBox).withConfig({
    componentId: "sc-6858625d-2"
})(_templateObject2());
export var Box = styled.div.withConfig({
    componentId: "sc-6858625d-3"
})(_templateObject3(), colors.$bebebe);
export var ListCustomer = styled.li.withConfig({
    componentId: "sc-6858625d-4"
})(_templateObject4(), colors.$bebebe, breakpoints.desktop, colors.$0d0d0d, fonts.regular, pxToCssFont(14, 18), colors.$ffffff, colors.$0d0d0d, colors.$ffffff, colors.$0d0d0d, colors.$ffffff, colors.$0d0d0d);
export var IconMarkerWrapper = styled.div.withConfig({
    componentId: "sc-6858625d-5"
})(_templateObject5());
export var ListOtherInfo = styled(ListCustomer).withConfig({
    componentId: "sc-6858625d-6"
})(_templateObject6(), colors.$ffffff, colors.$0d0d0d);
