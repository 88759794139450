import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 310px;\n\n  @media (min-width: ",
        ") {\n    height: 360px;\n  }\n\n  @media (min-width: ",
        ") {\n    height: 320px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  div {\n    border-radius: 20px 20px 0 0;\n    transform: translateZ(0);\n  }\n\n  a:any-link {\n    display: none !important;\n  }\n\n  .gm-bundled-control {\n    display: none;\n  }\n\n  .gm-style .gm-style-cc a,\n  .gm-style .gm-style-cc button,\n  .gm-style .gm-style-cc span {\n    display: none !important;\n  }\n\n  .gm-style-cc {\n    display: none !important;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var MapContainer = styled.div.withConfig({
    componentId: "sc-2941f1f7-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var StyledMap = styled.span.withConfig({
    componentId: "sc-2941f1f7-1"
})(_templateObject1());
