import _to_array from "@swc/helpers/src/_to_array.mjs";
import { useCallback, useEffect, useRef } from "react";
import { createSyntheticEvent } from "@vfit/shared/hooks";
var NOT_NUMBER = /[^0-9]/g;
export var useOneNumberInput = function(param) {
    var focusedRef = param.focusedRef, onOtpBlur = param.onOtpBlur, onOtpChange = param.onOtpChange, otpRef = param.otpRef;
    var inputRef = useRef(null);
    var validate = useCallback(function(e) {
        if (inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) {
            var ref, ref1;
            var ref2 = _to_array(e.target.value.replace(NOT_NUMBER, "")), first = ref2[0], restValues = ref2.slice(1);
            inputRef.current.value = first !== undefined ? first : "";
            if (otpRef.current) {
                var ref3;
                var childs = (ref3 = inputRef.current.parentElement) === null || ref3 === void 0 ? void 0 : ref3.children;
                if (childs) {
                    var v = "";
                    for(var i = 0; i < childs.length; i += 1){
                        var child = childs[i].nodeName === "INPUT" ? childs[i] : null;
                        v += child && child.value ? child.value : "";
                    }
                    // eslint-disable-next-line no-param-reassign
                    otpRef.current.value = v;
                    var syntheticEvent = createSyntheticEvent(otpRef.current, "change");
                    if (syntheticEvent) {
                        onOtpChange === null || onOtpChange === void 0 ? void 0 : onOtpChange(syntheticEvent);
                    }
                }
            }
            if (((ref = e.target) === null || ref === void 0 ? void 0 : ref.nextSibling) && ((ref1 = e.target) === null || ref1 === void 0 ? void 0 : ref1.nextSibling.nodeName) === "INPUT") {
                var nextOtp = e.target.nextSibling;
                var nextValue = restValues.join("");
                if (nextValue) {
                    nextOtp.value = "".concat(nextValue).concat(nextOtp.value);
                    nextOtp.focus();
                }
            }
        }
    }, [
        onOtpChange,
        otpRef
    ]);
    var handleBlur = useCallback(function() {
        // eslint-disable-next-line no-param-reassign
        focusedRef.current = false;
        var syntheticEvent = createSyntheticEvent(otpRef.current, "blur");
        if (syntheticEvent) {
            onOtpBlur === null || onOtpBlur === void 0 ? void 0 : onOtpBlur(syntheticEvent);
        }
    }, [
        focusedRef,
        onOtpBlur,
        otpRef
    ]);
    var handleFocus = useCallback(function(e) {
        var inputValue = e.target.value;
        e.target.value = ""; // Temporarily clear value
        e.target.value = inputValue; // re-assign after the cursor automatically moved to the right of the input field
        // eslint-disable-next-line no-param-reassign
        focusedRef.current = true;
        validate(e);
    }, [
        focusedRef,
        validate
    ]);
    var jumpToPrevious = useCallback(function(e) {
        var el = e.target;
        if (e.key === "Backspace" && el.value === "") {
            if (el.previousSibling && el.previousSibling.nodeName === "INPUT") {
                var previous = el.previousSibling;
                previous.focus();
            }
        }
    }, []);
    var handleKeyDown = useCallback(function(e) {
        var el = e.target;
        if (e.key === "Backspace") {
            if (el.value === "" && el.previousSibling && el.previousSibling.nodeName === "INPUT") {
                var previous = el.previousSibling;
                return previous.focus();
            }
            if (el.value != "") {
                el.value = "";
                return el.focus();
            }
        }
        if (Number.isNaN(Number(e.key))) e.preventDefault();
    }, []);
    // Handle default value
    useEffect(function() {
        var ref;
        if (inputRef.current && inputRef.current === ((ref = inputRef.current.parentElement) === null || ref === void 0 ? void 0 : ref.children[0]) && inputRef.current.value !== undefined) {
            var ref1;
            inputRef.current.value = ((ref1 = otpRef.current) === null || ref1 === void 0 ? void 0 : ref1.value) || "";
            if (inputRef.current.value) {
                var syntheticEvent = createSyntheticEvent(inputRef.current, "change");
                if (syntheticEvent) {
                    validate(syntheticEvent);
                }
            }
        }
    }, []);
    return {
        handleBlur: handleBlur,
        handleFocus: handleFocus,
        inputRef: inputRef,
        jumpToPrevious: jumpToPrevious,
        handleKeyDown: handleKeyDown,
        validate: validate
    };
};
