import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    font-size: 0.875rem; /* 14px */\n    letter-spacing: 0.5px;\n    line-height: 1; /* differs from DS typography */\n    text-transform: uppercase;\n  "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
import { cssfonts } from "../cssfonts";
var common = css(_templateObject());
export var button2 = {
    bold: css(_templateObject1(), cssfonts.bold)
};
