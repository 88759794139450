import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  .swiper-wrapper {\n    justify-content: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var SwiperCardsContainer = styled.div.withConfig({
    componentId: "sc-e600b2c0-0"
})(_templateObject(), function(param) {
    var alignCenter = param.alignCenter;
    return alignCenter ? "center" : "inherit";
});
