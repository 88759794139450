import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  border-radius: 6px;\n  padding: 15px 17px;\n  display: flex;\n  flex-direction: row;\n  cursor: ",
        ";\n  background-color: ",
        ";\n  margin-bottom: ",
        "px;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    padding: 19px 17px;\n    margin-bottom: ",
        "px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: ",
        "px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  z-index: 3;\n  ",
        "\n  flex-shrink: 0;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  font-style: normal;\n  text-align: left;\n  ",
        "\n  width: 208px;\n  margin-left: 18px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    width: 294px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 334px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-762d09e2-0"
})(_templateObject(), function(props) {
    return props.disabled ? "not-allowed" : "pointer";
}, function(props) {
    return props.disabled ? "#ccc" : "";
}, function(props) {
    var ref;
    return ((ref = props.marginBottom) === null || ref === void 0 ? void 0 : ref.mobile) ? "".concat(props.marginBottom.mobile) : "16";
}, function(props) {
    return props.index === props.colorIndex ? "border: 2px solid ".concat(colors.$00697c, ";") : "border: 1px solid ".concat(colors.$bebebe, ";");
}, breakpoints.tablet, function(props) {
    var ref;
    return ((ref = props.marginBottom) === null || ref === void 0 ? void 0 : ref.tablet) ? "".concat(props.marginBottom.tablet) : "12";
}, breakpoints.desktop, function(props) {
    var ref;
    return ((ref = props.marginBottom) === null || ref === void 0 ? void 0 : ref.desktop) ? "".concat(props.marginBottom.desktop) : "22";
});
export var CheckboxContainer = styled.div.withConfig({
    componentId: "sc-762d09e2-1"
})(_templateObject1(), function(props) {
    return "align-self: ".concat(props.align, ";");
});
export var Text = styled.div.withConfig({
    componentId: "sc-762d09e2-2"
})(_templateObject2(), fonts.exbold, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30), breakpoints.desktop);
