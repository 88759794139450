import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    font-size: 2.625rem; /* 42px */\n    line-height: 1.15; /* 42/48 */\n\n    @media (min-width: ",
        ") {\n      font-size: 4rem; /* 64px */\n      line-height: 1.15; /* 64/72 */\n    }\n\n    @media (min-width: ",
        ") {\n      font-size: 4.375rem; /* 70px */\n      line-height: 1.1; /* 70/76 */\n    }\n  "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
import { breakpoints } from "../../breakpoints";
import { cssfonts } from "../cssfonts";
export var h1 = {
    extrabold: css(_templateObject(), cssfonts.exbold, breakpoints.tablet, breakpoints.desktop)
};
