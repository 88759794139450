import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  display: inline-block;\n  width: 45px;\n  height: 24px;\n\n  span {\n    position: absolute;\n    cursor: pointer;\n    border-radius: 100px;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: ",
        ";\n    transition: 0.4s;\n\n    &::before {\n      position: absolute;\n      content: '';\n      height: 20px;\n      width: 20px;\n      left: 2px;\n      bottom: 2px;\n      top: 2px;\n      background-color: ",
        ";\n      transition: 0.4s;\n      border-radius: 50%;\n    }\n  }\n\n  input {\n    opacity: 0;\n    width: 0;\n    height: 0;\n\n    &:checked + span::before {\n      transform: translateX(21px);\n    }\n\n    &:disabled + span {\n      background-color: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { colors } from "@vfit/shared/themes";
import styled from "styled-components";
export var Container = styled.label.withConfig({
    componentId: "sc-194cb27f-0"
})(_templateObject(), colors.$e60000, colors.$ffffff, colors.$bebebe);
