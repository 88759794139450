import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  border-radius: ",
        ";\n  height: ",
        ";\n  left: 50%;\n  position: absolute;\n  top: ",
        ";\n  transform: translateX(-50%);\n  width: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { colors, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var ScrollableIndicator = styled.div.withConfig({
    componentId: "sc-ca0d8902-0"
})(_templateObject(), colors.$bebebe, pxToRem(8), pxToRem(6), pxToRem(11), pxToRem(42));
