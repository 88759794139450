var convertSpecialChars = function(str) {
    if (!str) return "";
    var mapChars = {
        "&amp;": "&",
        "&#61;": "=",
        "&#34;": '"',
        "&lt;": "<",
        "&gt;": ">",
        "&quot;": '"',
        "&#039;": "'",
        "&copy;": "\xa9",
        "&reg;": "\xae",
        "&euro;": "€",
        "&pound;": "\xa3",
        "&yen;": "\xa5",
        "&cent;": "\xa2"
    };
    return str.replace(/&amp;|&lt;|&#61;|&#34;|&gt;|&quot;|&#039;|&copy;|&reg;|&euro;|&pound;|&yen;|&cent;/g, function(m) {
        return mapChars[m];
    });
};
var organizeHtml = function(html) {
    var ref;
    if (!html) return "";
    var parser = new DOMParser();
    var doc = parser === null || parser === void 0 ? void 0 : parser.parseFromString(html, "text/html");
    var outerP = doc.body.querySelector("p");
    if (outerP) {
        outerP.outerHTML = outerP.innerHTML;
    }
    return convertSpecialChars((doc === null || doc === void 0 ? void 0 : (ref = doc.body) === null || ref === void 0 ? void 0 : ref.innerHTML) || "");
};
export { convertSpecialChars, organizeHtml };
