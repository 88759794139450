import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    font-size: 1rem; /* 16px */\n    line-height: 1; /* differs from DS typography */\n\n    @media (min-width: ",
        ") {\n      font-size: 1.125rem; /* 18px */\n    }\n  "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
import { breakpoints } from "../../breakpoints";
import { cssfonts } from "../cssfonts";
export var button1 = {
    regular: css(_templateObject(), cssfonts.regular, breakpoints.tablet)
};
