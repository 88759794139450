// Example of handleUseQuery function provider.
// export const getCountry = (customOptions?: CustomOptions) => {
//   const keysDependency =
//     customOptions && customOptions.keyDependency
//       ? customOptions.keyDependency
//       : ['getCountry'];
//   const options: UseQueryConfig = {
//     queryKey: keysDependency,
//     queryFn: () => nextClient.get(API.COUNTRY),
//     options: {
//       ...(customOptions && { ...customOptions }),
//       /* EG: of the options we can pass
//             onSuccess: (data: any) => {
//                             LoggerInstance.debug('API  GETCOUNTRY CALL WITH NEW UTILS, OVERRIDED ON SUCCESS MESSAGE --> DATA: ', data)
//                         }, */
//     },
//   };
//   return handleUseQuery(options);
// };
export var resetData = function(queryClient) {
    var keys = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
    // TODO keys required always populated besause this cause the reset of cms data
    if (keys.length === 0) return false;
    if (keys.length === 0) localStorage.clear();
    else {
        keys.forEach(function(key) {
            localStorage.removeItem(key);
            queryClient.removeQueries([
                key
            ]);
            queryClient.resetQueries([
                key
            ]);
        });
    }
    return true;
};
