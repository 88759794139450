import { useEffect } from "react";
import { useRouter } from "next/router";
export function useScrollTop() {
    var router = useRouter();
    useEffect(function() {
        setTimeout(function() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant"
            });
        }, 0);
    }, [
        router.pathname
    ]);
}
