import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  display: flex;\n  justify-content: center;\n  animation: fadein 2s;\n  margin-block: 0;\n  margin-inline: 0;\n  margin-bottom: -2px !important;\n  color: ",
        ";\n  ",
        "\n  font-family: ",
        ";\n  text-align: center;\n\n  @keyframes fadein {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: -8px !important;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  width: 500px;\n  height: 57px;\n  position: relative;\n  margin: 0;\n  padding-top: 18px;\n  padding-bottom: 18px;\n  display: flex;\n  transition: border 2s;\n  background: transparent;\n  ",
        ";\n\n  .wrapper {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n    margin: 0;\n    padding: 0;\n    height: 24px;\n  }\n\n  ",
        "\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  background-color: transparent;\n  margin: auto;\n  padding: 0;\n  width: 100%;\n  height: 27px;\n  outline: 0;\n  border: 0;\n  text-overflow: ellipsis;\n  font-family: ",
        ";\n  ",
        "\n  text-align: center;\n\n  &:disabled {\n    background-color: transparent;\n    cursor: not-allowed;\n  }\n\n  &:focus {\n    outline: none;\n  }\n\n  &::placeholder {\n    color: ",
        ";\n  }\n\n  &:-webkit-autofill,\n  &:-webkit-autofill:hover,\n  &:-webkit-autofill:focus,\n  &:-webkit-autofill:active {\n    box-shadow: 0 0 0 30px white inset !important;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: auto;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from "@vfit/shared/themes";
export var ErrorMessage = styled.p.withConfig({
    componentId: "sc-9891b179-0"
})(_templateObject(), colors.$bd0000, pxToCssFont(14, 18), fonts.regular, breakpoints.desktop);
export var FieldsetContainer = styled.div.withConfig({
    componentId: "sc-9891b179-1"
})(_templateObject1());
export var Fieldset = styled.div.withConfig({
    componentId: "sc-9891b179-2"
})(_templateObject2(), function(props) {
    return props.isDisabled ? "border-bottom: 1px solid #BEBEBE" : "border-bottom: 1px solid #BEBEBE";
}, function(props) {
    return props.isDisabled ? "&:hover {cursor: not-allowed}" : "";
});
// div for inputs
export var Field = styled.input.withConfig({
    componentId: "sc-9891b179-3"
})(_templateObject3(), fonts.exbold, pxToCssFontSize(24), colors.$262626, breakpoints.tablet);
