import { useEffect, useState } from "react";
var MIN_RESIZE_DIFFERENCE = 200;
export var useViewportHeight = function() {
    var ref = useState(0), headerHeight = ref[0], setHeaderHeight = ref[1];
    var ref1 = useState(0), viewportHeight = ref1[0], setViewportHeight = ref1[1];
    useEffect(function() {
        var element = document.getElementById("mainHeader");
        if (element === null || element === void 0 ? void 0 : element.clientHeight) {
            setHeaderHeight(element === null || element === void 0 ? void 0 : element.clientHeight);
        }
    }, []);
    useEffect(function() {
        var setVh = function() {
            if (Math.abs(viewportHeight - window.innerHeight) > MIN_RESIZE_DIFFERENCE && window.scrollY === 0) {
                requestAnimationFrame(function() {
                    setViewportHeight(window.innerHeight);
                });
            }
        };
        setViewportHeight(window.innerHeight);
        window.addEventListener("resize", setVh);
        return function() {
            window.removeEventListener("resize", setVh);
        };
    }, [
        viewportHeight
    ]);
    return {
        headerHeight: headerHeight,
        viewportHeight: viewportHeight
    };
};
