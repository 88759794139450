import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-flow: column wrap;\n  align-items: flex-start;\n  margin-left: 24px;\n  margin-right: 24px;\n  margin-bottom: 64px;\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    column-gap: 26px;\n    margin-left: 24px;\n    margin-right: 24px;\n    margin-bottom: 120px;\n  }\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    margin-left: 0;\n    margin-right: 0;\n    margin-bottom: 120px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  flex: 100%;\n  width: 100%;\n  margin-bottom: 14px;\n\n  @media (min-width: ",
        ") {\n    flex: ",
        ";\n    margin-bottom: 14px;\n  }\n\n  @media (min-width: ",
        ") {\n    flex: 1;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    align-self: flex-start;\n    text-align: center;\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  color: ",
        ";\n  min-height: 40px;\n  ",
        "\n  margin-top: 0;\n  margin-bottom: 0;\n\n  p {\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 0 auto;\n    margin-top: 17px;\n    max-width: 190px;\n    min-height: 40px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  color: ",
        ";\n  min-height: 40px;\n  ",
        "\n  margin-top: 0;\n  margin-left: 24px;\n  margin-right: 24px;\n  margin-bottom: 24px;\n\n  p {\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  color: ",
        ";\n  margin-top: 6px;\n  margin-bottom: 0;\n  min-height: 40px;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin-right: 16px;\n\n  @media (min-width: ",
        ") {\n    margin-right: 0;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n  margin-top: 15px;\n  margin-bottom: 10px;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var HomeLinksContainer = styled.div.withConfig({
    componentId: "sc-5492ff3c-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var AnchorContainer = styled.div.withConfig({
    componentId: "sc-5492ff3c-1"
})(_templateObject1(), breakpoints.tablet, function(props) {
    return props.isEven ? "45%" : "100%";
}, breakpoints.desktop);
export var AnchorText = styled.p.withConfig({
    componentId: "sc-5492ff3c-2"
})(_templateObject2(), fonts.regular, colors.$262626, pxToCssFont(18, 24), breakpoints.desktop);
export var CustomDesc = styled.p.withConfig({
    componentId: "sc-5492ff3c-3"
})(_templateObject3(), fonts.light, colors.$262626, pxToCssFont(24, 32));
export var AnchorTitle = styled.p.withConfig({
    componentId: "sc-5492ff3c-4"
})(_templateObject4(), fonts.exbold, colors.$262626, pxToCssFont(24, 30), breakpoints.desktop, pxToCssFont(36, 50));
export var AnchorTextWrapper = styled.p.withConfig({
    componentId: "sc-5492ff3c-5"
})(_templateObject5(), breakpoints.tablet);
export var ArrowButton = styled.div.withConfig({
    componentId: "sc-5492ff3c-6"
})(_templateObject6(), breakpoints.desktop);
