import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: ",
        ";\n  width: 100vw;\n  position: relative;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: ",
        ";\n  width: 100vw;\n\n  div {\n    position: absolute;\n    width: 100%;\n  }\n\n  div::after {\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    content: '';\n    background-image: linear-gradient(\n      90deg,\n      rgba(62, 62, 62, 60%) 17.03%,\n      rgba(0, 0, 0, 0%) 83.87%\n    );\n  }\n\n  video {\n    object-fit: cover;\n    width: 100%;\n    height: ",
        ";\n\n    @media (min-width: ",
        ") {\n      object-fit: cover;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  left: 0;\n  top: 0;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin-top: 35px;\n\n  h2 {\n    font-family: ",
        ";\n    ",
        "\n    letter-spacing: ",
        ";\n    text-transform: uppercase;\n    color: ",
        ";\n    margin: 0 30px;\n  }\n\n  h3 {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    font-size: clamp(",
        ", 3vw + ",
        ", ",
        ");\n    color: ",
        ";\n    margin: 21px 30px 0;\n  }\n\n  h4,\n  ul {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 400;\n    color: ",
        ";\n    margin: 30px 30px 40px;\n    max-width: 80%;\n  }\n\n  div {\n    button {\n      margin-left: 30px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 64px;\n\n    h2 {\n      ",
        "\n    }\n\n    h3 {\n      ",
        "\n      line-height: 1.25;\n      font-size: clamp(",
        ", 2vw + ",
        ", ",
        ");\n      max-width: 95%;\n    }\n\n    h4 {\n      ",
        "\n      max-width: 64%;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 0 80px;\n\n    h2 {\n      ",
        "\n    }\n\n    h3 {\n      ",
        "\n      font-size: clamp(",
        ", 2vw + ",
        ", ",
        ");\n      max-width: 80%;\n    }\n\n    h4 {\n      ",
        "\n      max-width: 60%;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  width: 324px;\n  max-width: 400px;\n\n  btn {\n    z-index: 4;\n    position: relative;\n    width: 324px;\n    max-width: 400px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  padding: 0;\n  margin: 0;\n  gap: 12px;\n  list-style: none;\n  li {\n    display: flex;\n    gap: 12px;\n    font-family: ",
        ";\n    font-weight: 700;\n    ",
        ";\n    @media (min-width: ",
        ") {\n      ",
        ";\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  width: ",
        ";\n  height: ",
        ";\n  svg {\n    color: white;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize, pxToFontSize, pxToRem } from "@vfit/shared/themes";
export var SlideHomeContainer = styled.div.withConfig({
    componentId: "sc-4a2e647a-0"
})(_templateObject(), function(param) {
    var viewportHeight = param.viewportHeight, headerHeight = param.headerHeight;
    return viewportHeight ? "".concat(viewportHeight - headerHeight, "px") : "calc(100vh - ".concat(headerHeight, "px)");
});
export var SlideHomeContainerVideo = styled.div.withConfig({
    componentId: "sc-4a2e647a-1"
})(_templateObject1(), function(param) {
    var viewportHeight = param.viewportHeight, headerHeight = param.headerHeight;
    return viewportHeight ? "".concat(viewportHeight - headerHeight, "px") : "calc(100vh - ".concat(headerHeight, "px)");
}, function(param) {
    var viewportHeight = param.viewportHeight, headerHeight = param.headerHeight;
    return viewportHeight ? "".concat(viewportHeight - headerHeight, "px") : "calc(100vh - ".concat(headerHeight, "px)");
}, breakpoints.desktop);
export var SlideHomeContent = styled.div.withConfig({
    componentId: "sc-4a2e647a-2"
})(_templateObject2());
export var SlideHomeText = styled.div.withConfig({
    componentId: "sc-4a2e647a-3"
})(_templateObject3(), fonts.regular, pxToCssFont(16, 24), pxToRem(0.5), colors.$ffffff, fonts.exbold, pxToCssFont(30, 30), pxToFontSize(30), pxToFontSize(22), pxToFontSize(42), colors.$ffffff, fonts.light, pxToCssFont(18, 24), colors.$ffffff, breakpoints.tablet, pxToCssFont(18, 24), pxToCssFontSize(54), pxToFontSize(42), pxToFontSize(30), pxToFontSize(64), pxToCssFont(20, 30), breakpoints.desktop, pxToCssFont(18, 24), pxToCssFont(64, 64), pxToFontSize(42), pxToFontSize(30), pxToFontSize(70), pxToCssFont(24, 30));
export var SlideHomeButtonSlideContainer = styled.div.withConfig({
    componentId: "sc-4a2e647a-4"
})(_templateObject4());
export var Specs = styled.ul.withConfig({
    componentId: "sc-4a2e647a-5"
})(_templateObject5(), fonts.regular, pxToCssFont(20, 28), breakpoints.tablet, pxToCssFont(20, 30));
export var CheckMarkContainer = styled.div.withConfig({
    componentId: "sc-4a2e647a-6"
})(_templateObject6(), pxToRem(18), pxToRem(26));
