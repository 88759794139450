import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  position: relative;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n\n  > div {\n    position: absolute;\n    width: 100%;\n  }\n\n  video {\n    object-fit: cover;\n    width: 100%;\n    height: 100vh;\n\n    @media (min-width: ",
        ") {\n      object-fit: cover;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  top: 0;\n  left: 0;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding-top: ",
        "px;\n  background-image: linear-gradient(90deg, rgba(62, 62, 62, 60%) 17.03%, rgba(0, 0, 0, 0%) 83.87%);\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  flex: 0.8;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  font-weight: 400;\n  color: ",
        ";\n  margin: 30px 30px 40px;\n  max-width: 80%;\n\n  @media (min-width: ",
        ") {\n    font-family: ",
        ";\n    ",
        "\n    max-width: 64%;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    max-width: 44%;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin-top: 35px;\n\n  h2 {\n    font-family: ",
        ";\n    ",
        "\n    letter-spacing: ",
        ";\n    text-transform: uppercase;\n    color: ",
        ";\n    margin: 0 30px;\n  }\n\n  h3 {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    font-size: clamp(",
        ", 3vw + ",
        ", ",
        ");\n    color: ",
        ";\n    margin: 21px 30px 0;\n  }\n\n  h4 {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 400;\n    color: ",
        ";\n    margin: 30px 30px 40px;\n    max-width: 80%;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 64px;\n\n    h2 {\n      ",
        "\n    }\n\n    h3 {\n      ",
        "\n      line-height: 1.25;\n      font-size: clamp(",
        ", 3vw + ",
        ", ",
        ");\n      max-width: 95%;\n    }\n\n    h4 {\n      ",
        "\n      max-width: 64%;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 0 80px;\n\n    h2 {\n      ",
        "\n    }\n\n    h3 {\n      ",
        "\n      font-size: clamp(",
        ", 3vw + ",
        ", ",
        ");\n      max-width: 65%;\n    }\n\n    h4 {\n      ",
        "\n      max-width: 44%;\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  padding: 0 30px;\n\n  button {\n    width: 240px;\n  }\n\n  @media (min-width: ",
        ") {\n    max-width: 400px;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  padding: 0;\n  margin: 0;\n  gap: 12px;\n  list-style: none;\n  li {\n    display: flex;\n    gap: 12px;\n    font-family: ",
        ";\n    font-weight: 700;\n    ",
        ";\n    @media (min-width: ",
        ") {\n      ",
        ";\n    }\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  width: ",
        ";\n  height: ",
        ";\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize, pxToFontSize, pxToRem } from "@vfit/shared/themes";
export var SlideHomeContainer = styled.div.withConfig({
    componentId: "sc-4724373e-0"
})(_templateObject());
export var SlideHomeContainerVideo = styled.div.withConfig({
    componentId: "sc-4724373e-1"
})(_templateObject1(), breakpoints.desktop);
export var SlideHomeContent = styled.div.withConfig({
    componentId: "sc-4724373e-2"
})(_templateObject2(), function(param) {
    var headerHeight = param.headerHeight;
    return headerHeight;
});
export var SlideHomeContentTop = styled.div.withConfig({
    componentId: "sc-4724373e-3"
})(_templateObject3());
export var HeaderDescription = styled.div.withConfig({
    componentId: "sc-4724373e-4"
})(_templateObject4(), fonts.regular, pxToCssFont(18, 24), colors.$ffffff, breakpoints.tablet, fonts.light, pxToCssFont(20, 30), breakpoints.desktop, pxToCssFont(24, 30));
export var SlideHomeText = styled.div.withConfig({
    componentId: "sc-4724373e-5"
})(_templateObject5(), fonts.regular, pxToCssFont(16, 24), pxToRem(0.5), colors.$ffffff, fonts.exbold, pxToCssFont(30, 30), pxToFontSize(30), pxToFontSize(22), pxToFontSize(42), colors.$ffffff, fonts.light, pxToCssFont(18, 24), colors.$ffffff, breakpoints.tablet, pxToCssFont(18, 24), pxToCssFontSize(54), pxToFontSize(54), pxToFontSize(30), pxToFontSize(64), pxToCssFont(20, 30), breakpoints.desktop, pxToCssFont(18, 24), pxToCssFont(64, 64), pxToFontSize(64), pxToFontSize(30), pxToFontSize(70), pxToCssFont(24, 30));
export var SlideHomeButtonSlideContainer = styled.div.withConfig({
    componentId: "sc-4724373e-6"
})(_templateObject6(), breakpoints.tablet);
export var Specs = styled.ul.withConfig({
    componentId: "sc-4724373e-7"
})(_templateObject7(), fonts.regular, pxToCssFont(20, 28), breakpoints.tablet, pxToCssFont(20, 30));
export var CheckMarkContainer = styled.div.withConfig({
    componentId: "sc-4724373e-8"
})(_templateObject8(), pxToRem(18), pxToRem(26));
