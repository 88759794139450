import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 0 0 16px;\n  text-align: ",
        ";\n  h2,\n  h4 {\n    font-weight: inherit;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: ",
        ";\n  flex: 100%;\n  @media (min-width: ",
        ") {\n    flex: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  section {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    flex-wrap: wrap;\n    width: 100%;\n    row-gap: 12px;\n    border: 2px solid ",
        ";\n    @media (min-width: ",
        ") {\n      row-gap: 16px;\n    }\n  }\n\n  span {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 400;\n    padding-left: 3px;\n  }\n\n  .options {\n    align-items: center;\n    justify-content: flex-start;\n    display: flex;\n    border: 1px solid ",
        ";\n    border-radius: 6px;\n    padding: 16px;\n    cursor: pointer;\n    @media (min-width: ",
        ") {\n      padding: 20px 16px 20px;\n    }\n    span {\n      vertical-align: top;\n      margin-left: 5px;\n    }\n\n  }\n  .checked {\n    border: 2px solid ",
        ";\n  }\n  input[type='radio'] {\n    appearance: none;\n    height: 24px;\n    width: 24px;\n    border: 1px solid ",
        ";\n    border-radius: 50%;\n    outline: none;\n    cursor: pointer;\n  }\n\n  input[type='radio']::before {\n    content: '';\n    display: block;\n    width: 100%;\n    height: 75%;\n    border-color: ",
        ";\n    border-radius: 50%;\n  }\n\n  input[type='radio']:checked::before {\n    background: ",
        ";\n    border-color: ",
        ";\n  }\n\n  input[type='radio']:checked {\n    border-color: ",
        ";\n    align-items: center;\n    display: flex;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  max-width: 100%;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFontSize } from "@vfit/shared/themes";
import styled from "styled-components";
export var StyledFormTextContainer = styled.div.withConfig({
    componentId: "sc-aaad2f93-0"
})(_templateObject(), function(param) {
    var textAlign = param.textAlign;
    return textAlign || "left";
});
export var StyledInputContainer = styled.div.withConfig({
    componentId: "sc-aaad2f93-1"
})(_templateObject1(), function(param) {
    var isHidden = param.isHidden;
    return isHidden ? "none" : "block";
}, breakpoints.tablet, function(param) {
    var isColMiddle = param.isColMiddle;
    return isColMiddle ? "50%" : "100%";
});
export var StyledRadioGroup = styled.div.withConfig({
    componentId: "sc-aaad2f93-2"
})(_templateObject2(), colors.$ffffff, breakpoints.desktop, fonts.exbold, pxToCssFontSize(20), colors.$bebebe, breakpoints.desktop, colors.$00697c, colors.$bebebe, colors.$00697c, colors.$00697c, colors.$00697c, colors.$00697c);
export var StyledSection = styled.section.withConfig({
    componentId: "sc-aaad2f93-3"
})(_templateObject3());
export var StyledContainerButton = styled.div.withConfig({
    componentId: "sc-aaad2f93-4"
})(_templateObject4());
