import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 32px 23px 32px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  align-items: flex-start;\n  gap: 16px;\n \n\n  div{\n    margin: 0;\n    p{\n      margin: 0;\n    }\n  }\n\n\n  @media (min-width: ",
        ") {\n    margin: 48px 80px;\n    flex-direction: row; \n    justify-content: space-between;\n    align-items: center;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin: 0 23px 0 23px;\n  display: flex;\n  justify-content: center;\n  \n  flex-wrap: wrap;\n  gap: 20px;\n\n  @media (min-width: ",
        ") {\n    margin: 0 80px;\n    justify-content: flex-start;\n    gap: 84px 32px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 5px;\n  padding: 6px 16px;\n  background-color:  ",
        ";\n  border-radius: 20px;\n\n  p {\n    margin: 0;\n    line-height: 24px;\n    font-size: 18px;\n    font-family: ",
        ";\n  }\n\n  .filter {\n    font-weight: 700;\n    margin-right: 8px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts } from "@vfit/shared/themes";
import styled from "styled-components";
export var ListCardDeviceContainer = styled.div.withConfig({
    componentId: "sc-49d4ff6a-0"
})(_templateObject());
export var Top = styled.div.withConfig({
    componentId: "sc-49d4ff6a-1"
})(_templateObject1(), breakpoints.desktop);
export var ContainerCard = styled.div.withConfig({
    componentId: "sc-49d4ff6a-2"
})(_templateObject2(), breakpoints.desktop);
export var ShowFilterSet = styled.div.withConfig({
    componentId: "sc-49d4ff6a-3"
})(_templateObject3(), colors.$f2f2f2, fonts.regular);
