import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  width: 100%;\n\n  @media (min-width: ",
        ") {\n    width: auto;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  margin-top: ",
        ";\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  border: 1px solid ",
        ";\n  border-radius: 20px;\n  background-color: ",
        ";\n  min-height: ",
        ";\n  position: relative;\n\n  @media (min-width: ",
        ") {\n    width: 296px;\n  }\n\n  .button {\n    justify-self: flex-end;\n    padding: 0 24px;\n    margin: auto 0 32px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 0 24px;\n  opacity:",
        ";\n  div {\n    margin: 0;\n  }\n\n  h4 {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 700;\n    color: ",
        ";\n    margin: 0;\n    max-width: none;\n  }\n\n  .description {\n    div {\n      margin: 0;\n\n      p {\n        color: ",
        ";\n        margin: 0;\n        font-weight: 700;\n      }\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  display: flex;\n  align-items: center;\n\n  .discount div div {\n    padding: 6px 8px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  background-color: ",
        ";\n  border-radius: 20px;\n  height: 73px;\n  position: absolute;\n\n  span {\n    font-family: ",
        ";\n    font-style: normal;\n    font-weight: 700;\n    ",
        "\n    letter-spacing: 0.5px;\n    text-transform: uppercase;\n    color: ",
        ";\n    padding-top: 10px;\n    margin-left: 36px;\n    display: flex;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n  height: 200px;\n\n  img {\n    width: auto;\n    height: 100%;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var CardInfoDevicWrapper = styled.div.withConfig({
    componentId: "sc-2190906b-0"
})(_templateObject(), breakpoints.tablet);
export var Container = styled.div.withConfig({
    componentId: "sc-2190906b-1"
})(_templateObject1(), function(param) {
    var disableMarginTop = param.disableMarginTop;
    return disableMarginTop ? 0 : "36px";
}, colors.$bebebe, colors.$ffffff, function(param) {
    var enableAutoHeight = param.enableAutoHeight;
    return enableAutoHeight ? "auto" : 493;
}, breakpoints.tablet);
export var ContainerInfo = styled.div.withConfig({
    componentId: "sc-2190906b-2"
})(_templateObject2(), function(param) {
    var disabled = param.disabled;
    return disabled ? 0.5 : 1;
}, fonts.regular, pxToCssFont(14, 18), colors.$7e7e7e, colors.$7e7e7e);
export var Price = styled.div.withConfig({
    componentId: "sc-2190906b-3"
})(_templateObject3(), pxToCssFont(12, 16));
export var ProductCardOfferLabel = styled.div.withConfig({
    componentId: "sc-2190906b-4"
})(_templateObject4(), function(param) {
    var haveLabel = param.haveLabel;
    return haveLabel ? "#00697C" : "transparent";
}, fonts.regular, pxToCssFont(12, 16), colors.$ffffff);
export var ImageDevices = styled.div.withConfig({
    componentId: "sc-2190906b-5"
})(_templateObject5());
