import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  color: ",
        ";\n\n  img {\n    width: 18px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  div {\n    padding: 32px 0;\n    margin-bottom: 0;\n\n    p {\n      margin-block: 0;\n    }\n\n    @media (min-width: ",
        ") {\n      padding: 80px 0 48px;\n    }\n\n    @media (min-width: ",
        ") {\n      padding: 80px 0 84px;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts } from "@vfit/shared/themes";
export var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-b0ee52ec-0"
})(_templateObject(), fonts.regular, colors.$7e7e7e);
export var Title = styled.div.withConfig({
    componentId: "sc-b0ee52ec-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.desktop);
