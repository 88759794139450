import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    font-family: ",
        ";\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    margin: 0;\n    ",
        "\n  "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    text-decoration: line-through;\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n    font-family: ",
        ";\n  "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    font-weight: 700;\n    margin: 0;\n    text-transform: uppercase;\n    color: ",
        ";\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n  "
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    margin: ",
        ";\n  "
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    margin: 0;\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    ",
        "\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    ",
        "\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n    padding-left: 3px;\n    ",
        "\n  "
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    ",
        "\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n\n    p {\n      margin: 0;\n      margin-block: 0;\n    }\n  "
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n    margin: 0;\n    ",
        "\n  "
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _tagged_template_literal([
        "\n    font-family: ",
        ";\n    ",
        "\n    text-decoration: line-through;\n  "
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
function _templateObject16() {
    var data = _tagged_template_literal([
        "\n    font-family: ",
        ";\n    ",
        "\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject16 = function _templateObject16() {
        return data;
    };
    return data;
}
function _templateObject17() {
    var data = _tagged_template_literal([
        "\n    font-weight: 700;\n    ",
        "\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject17 = function _templateObject17() {
        return data;
    };
    return data;
}
function _templateObject18() {
    var data = _tagged_template_literal([
        "\n    font-weight: 700;\n    padding-left: 3px;\n    ",
        "\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  "
    ]);
    _templateObject18 = function _templateObject18() {
        return data;
    };
    return data;
}
function _templateObject19() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    margin: ",
        ";\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n\n    p {\n      margin: 0;\n      margin-block: 0;\n    }\n  "
    ]);
    _templateObject19 = function _templateObject19() {
        return data;
    };
    return data;
}
function _templateObject20() {
    var data = _tagged_template_literal([
        "\n    font-family: ",
        " !important;\n    ",
        "\n  "
    ]);
    _templateObject20 = function _templateObject20() {
        return data;
    };
    return data;
}
function _templateObject21() {
    var data = _tagged_template_literal([
        "\n    font-weight: 400 !important;\n    font-family: ",
        " !important;\n    ",
        " !important;\n  "
    ]);
    _templateObject21 = function _templateObject21() {
        return data;
    };
    return data;
}
function _templateObject22() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject22 = function _templateObject22() {
        return data;
    };
    return data;
}
function _templateObject23() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject23 = function _templateObject23() {
        return data;
    };
    return data;
}
function _templateObject24() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject24 = function _templateObject24() {
        return data;
    };
    return data;
}
function _templateObject25() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject25 = function _templateObject25() {
        return data;
    };
    return data;
}
function _templateObject26() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  gap: ",
        ";\n\n  > button {\n    flex-shrink: 0;\n  }\n"
    ]);
    _templateObject26 = function _templateObject26() {
        return data;
    };
    return data;
}
function _templateObject27() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n\n  & > :last-child {\n    margin: 0;\n  }\n"
    ]);
    _templateObject27 = function _templateObject27() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { breakpoints, colors, fonts, getTypography, pxToCssFont, pxToRem } from "@vfit/shared/themes";
// sunrise theme
var common = {
    amount: css(_templateObject(), pxToCssFont(30, 38), fonts.exbold, breakpoints.desktop, pxToCssFont(42, 52)),
    label: css(_templateObject1(), pxToCssFont(18, 24)),
    oldAmount: css(_templateObject2(), pxToCssFont(20, 32), breakpoints.desktop, pxToCssFont(24, 40)),
    recurrence: css(_templateObject3(), fonts.exbold),
    freeAmountRecurrence: css(_templateObject4()),
    specs: css(_templateObject5(), pxToCssFont(16, 22), function(param) {
        var color = param.color;
        return color === "white" ? colors.$bebebe : colors.$7e7e7e;
    }, breakpoints.desktop, pxToCssFont(18, 24)),
    section: css(_templateObject6(), function(param) {
        var color = param.color;
        return color === "white" ? "color: ".concat(colors.$ffffff, ";") : "";
    }),
    detail: css(_templateObject7(), pxToCssFont(18, 24), pxToRem(0, 0, 8)),
    note: css(_templateObject8(), pxToCssFont(12, 16), breakpoints.desktop, pxToCssFont(14, 18))
};
/* used on sunrise */ var commercial = _object_spread_props(_object_spread({}, common), {
    recurrence: css(_templateObject9(), common.recurrence, pxToCssFont(24, 28), breakpoints.desktop, pxToCssFont(36, 45))
});
/* used on reshaping */ var details = _object_spread_props(_object_spread({}, common), {
    amount: css(_templateObject10(), common.amount, breakpoints.desktop, pxToCssFont(36, 45)),
    recurrence: css(_templateObject11(), common.recurrence, pxToCssFont(16, 22), breakpoints.desktop, pxToCssFont(20, 30)),
    freeAmountRecurrence: css(_templateObject12(), getTypography("h3.extrabold")),
    detail: css(_templateObject13(), common.detail, pxToCssFont(14, 18), breakpoints.desktop, pxToCssFont(18, 24))
});
// mva theme
var mvaCommon = _object_spread_props(_object_spread({}, common), {
    label: css(_templateObject14(), getTypography("body1.bold")),
    oldAmount: css(_templateObject15(), fonts.light, pxToCssFont(20, 28)),
    amount: css(_templateObject16(), fonts.exbold, pxToCssFont(32, 40), breakpoints.desktop, pxToCssFont(44, 52)),
    recurrence: css(_templateObject17(), pxToCssFont(16, 20), breakpoints.tablet, pxToCssFont(20, 28), breakpoints.desktop, pxToCssFont(22, 32)),
    freeAmountRecurrence: css(_templateObject18(), pxToCssFont(32, 40), breakpoints.desktop, pxToCssFont(44, 52)),
    detail: css(_templateObject19(), pxToCssFont(16, 20), pxToRem(0, 0, 8), breakpoints.tablet, pxToCssFont(18, 24))
});
/* used on onboarding */ var mvaDetails = _object_spread({}, mvaCommon);
var freeAmount = _object_spread_props(_object_spread({}, common), {
    amount: css(_templateObject20(), fonts.vodafone, pxToCssFont(42, 40)),
    recurrence: css(_templateObject21(), fonts.vodafone, pxToCssFont(18, 24))
});
export var getVariant = function(component, variant) {
    switch(variant){
        case "details":
            return details[component];
        case "mva:details":
            return mvaDetails[component];
        case "freeAmount":
            return freeAmount[component];
        default:
            return commercial[component];
    }
};
export var Label = styled.h4.withConfig({
    componentId: "sc-cecfe468-0"
})(_templateObject22(), function(param) {
    var variant = param.variant;
    return getVariant("label", variant);
});
export var Specs = styled.p.withConfig({
    componentId: "sc-cecfe468-1"
})(_templateObject23(), function(param) {
    var variant = param.variant;
    return getVariant("specs", variant);
});
export var Detail = styled.p.withConfig({
    componentId: "sc-cecfe468-2"
})(_templateObject24(), function(param) {
    var variant = param.variant;
    return getVariant("detail", variant);
});
export var Note = styled.p.withConfig({
    componentId: "sc-cecfe468-3"
})(_templateObject25(), function(param) {
    var variant = param.variant;
    return getVariant("note", variant);
});
export var Footer = styled.footer.withConfig({
    componentId: "sc-cecfe468-4"
})(_templateObject26(), pxToRem(20));
export var Section = styled.section.withConfig({
    componentId: "sc-cecfe468-5"
})(_templateObject27(), function(param) {
    var variant = param.variant;
    return getVariant("section", variant);
});
