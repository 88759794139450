import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  ",
        ";\n  font-weight: 700;\n  width: 100%;\n\n  div {\n    ",
        ";\n    font-weight: 400;\n    font-family: ",
        ";\n  }\n\n  div:first-of-type {\n    width: calc(100% - ",
        "px - 15px);\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  flex-shrink: 0;\n  border: none;\n  background: ",
        ";\n  color: white;\n  width: ",
        "px;\n  height: ",
        "px;\n  border-radius: 50%;\n  font-family: ",
        ";\n  transition: all 0.3s;\n  margin-left: 15px;\n  cursor: pointer;\n  position: relative;\n\n  span {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n\n  &:hover {\n    background: ",
        ";\n  }\n\n  &:active {\n    background: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  div {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 400;\n    color: ",
        ";\n    margin: 0;\n    max-width: none;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import { colors, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
var circleBtnWidth = 24;
export var Box = styled.div.withConfig({
    componentId: "sc-66a89eda-0"
})(_templateObject(), pxToCssFont(18, 24), pxToCssFont(20, 30), fonts.regular, circleBtnWidth);
var btnSize = 26;
export var BtnModalPromo = styled.button.withConfig({
    componentId: "sc-66a89eda-1"
})(_templateObject1(), colors.$e60000, btnSize, btnSize, fonts.light, colors.$bd0000, colors.$a10000);
export var ModalDetailText = styled.div.withConfig({
    componentId: "sc-66a89eda-2"
})(_templateObject2(), fonts.regular, pxToCssFont(18, 24), colors.$262626);
