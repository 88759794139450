import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  .arrow-enter > svg {\n    animation: ",
        " 0.3s forwards;\n  }\n\n  .arrow-exit > svg {\n    animation: ",
        " 0.3s forwards;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n      border-color: ",
        ";\n      border-radius: ",
        ";\n      border-style: solid;\n      border-top: none;\n      border-width: 1px;\n      padding: ",
        ";\n      top: ",
        ";\n    "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n      border-radius: ",
        ";\n      box-shadow: ",
        ";\n      padding: 0;\n      top: ",
        ";\n    "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n      border-radius: ",
        ";\n      box-shadow: ",
        ";\n      padding: 0;\n      top: ",
        ";\n    "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      color: ",
        ";\n      height: ",
        ";\n      padding: ",
        ";\n\n      &:hover {\n        background-color: ",
        ";\n        color: ",
        ";\n      }\n    "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      ",
        "\n      color: ",
        ";\n      height: ",
        ";\n      padding: ",
        ";\n      position: relative;\n\n      &:hover {\n        background-color: ",
        ";\n        color: ",
        ";\n        font-weight: 700;\n      }\n\n      &[aria-selected='false'] + li::after,\n      &:hover + li::after {\n        background-color: ",
        ";\n        top: 0;\n        content: '';\n        display: block;\n        height: 1px;\n        left: ",
        ";\n        position: absolute;\n        width: calc(100% - ",
        ");\n      }\n    "
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      ",
        "\n      color: ",
        ";\n      height: ",
        ";\n      padding: ",
        ";\n      position: relative;\n\n      &:hover {\n        background-color: ",
        ";\n        color: ",
        ";\n        font-weight: 700;\n      }\n\n      &[aria-selected='false'] + li::after,\n      &:hover + li::after {\n        background-color: ",
        ";\n        top: 0;\n        content: '';\n        display: block;\n        height: 1px;\n        left: ",
        ";\n        position: absolute;\n        width: calc(100% - ",
        ");\n      }\n    "
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      color: ",
        ";\n    "
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      color: ",
        ";\n\n      &::before {\n        background-color: ",
        ";\n        content: '';\n        display: ",
        ";\n        height: 1px;\n        left: ",
        ";\n        position: absolute;\n        width: calc(100% - ",
        ");\n        top: -1px;\n      }\n    "
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      color: ",
        ";\n\n      &::before {\n        background-color: ",
        ";\n        content: '';\n        display: ",
        ";\n        height: 1px;\n        left: ",
        ";\n        position: absolute;\n        width: calc(100% - ",
        ");\n        top: -1px;\n      }\n    "
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  left: 0;\n  list-style: none;\n  margin: 0;\n  overflow: hidden;\n  position: absolute;\n  transform-origin: top;\n  transition: all 0.3s ease;\n  width: 100%;\n  z-index: 9;\n\n  &.enter {\n    animation: ",
        " 0.3s forwards;\n  }\n\n  &.exit {\n    animation: ",
        " 0.3s forwards;\n  }\n\n  > * {\n    transition: all 0.3s ease;\n  }\n\n  > div::-webkit-scrollbar {\n    width: ",
        ";\n    height: ",
        ";\n    border-radius: ",
        ";\n  }\n\n  > div::-webkit-scrollbar-thumb {\n    background: ",
        ";\n    border-radius: ",
        ";\n  }\n\n  > div::-webkit-scrollbar-track {\n    background: ",
        ";\n  }\n\n  ",
        "\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  cursor: pointer;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  transition: all 0.3s ease;\n  white-space: nowrap;\n  ",
        "\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  padding: ",
        ";\n  position: relative;\n\n  > a,\n  > button {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n\n  ",
        "\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { animations, colors, cssprops, getTypography, pxToRem, shadows } from "@vfit/shared/themes";
var rotateAnimation = css(_templateObject(), animations.rotate, animations.rotateBack);
var variants = {
    options: {
        animated: css(_templateObject1(), function(param) {
            var error = param.error;
            return !error ? colors.$bebebe : colors.$bd0000;
        }, pxToRem(0, 0, 5, 5), pxToRem(5, 0, 0), pxToRem(64)),
        "mva:animated": css(_templateObject2(), pxToRem(6), shadows.card_overlay, pxToRem(53 + 8)),
        "mva:static": css(_templateObject3(), pxToRem(6), shadows.card_overlay, pxToRem(80 + 8))
    },
    li: {
        animated: css(_templateObject4(), getTypography("body1.regular"), function(param) {
            var selected = param["aria-selected"];
            return !selected ? colors.$7e7e7e : colors.$ffffff;
        }, pxToRem(40), pxToRem(8, 16, 8, 20), colors.$bebebe, colors.$262626),
        "mva:animated": css(_templateObject5(), getTypography("body2.regular"), function(param) {
            var selected = param["aria-selected"];
            return !selected ? "" : "font-weight: 700;";
        }, function(param) {
            var selected = param["aria-selected"];
            return !selected ? colors.$262626 : colors.$ffffff;
        }, pxToRem(50), pxToRem(16), colors.$f2f2f2, colors.$262626, colors.$bebebe, pxToRem(16), pxToRem(32)),
        "mva:static": css(_templateObject6(), getTypography("body3.regular"), function(param) {
            var selected = param["aria-selected"];
            return !selected ? "" : "font-weight: 700;";
        }, function(param) {
            var selected = param["aria-selected"];
            return !selected ? colors.$262626 : colors.$ffffff;
        }, pxToRem(50), pxToRem(16), colors.$f2f2f2, colors.$262626, colors.$bebebe, pxToRem(16), pxToRem(32))
    },
    child: {
        animated: css(_templateObject7(), getTypography("body1.regular"), colors.$7e7e7e),
        "mva:animated": css(_templateObject8(), getTypography("body2.regular"), colors.$262626, colors.$bebebe, function(param) {
            var filteredItems = param.filteredItems;
            return filteredItems.length > 0 ? "block" : "none";
        }, pxToRem(16), pxToRem(32)),
        "mva:static": css(_templateObject9(), getTypography("body2.regular"), colors.$262626, colors.$bebebe, function(param) {
            var filteredItems = param.filteredItems;
            return filteredItems.length > 0 ? "block" : "none";
        }, pxToRem(16), pxToRem(32))
    }
};
export var Container = styled.div.withConfig({
    componentId: "sc-51fbd387-0"
})(_templateObject10(), rotateAnimation, cssprops.styled);
export var Options = styled.ul.withConfig({
    componentId: "sc-51fbd387-1"
})(_templateObject11(), animations.stretchInDown, animations.stretchOutUp, pxToRem(4), pxToRem(18), pxToRem(20), colors.$bebebe, pxToRem(20), colors.$ffffff, function(param) {
    var variant = param.variant;
    return variant ? variants.options[variant] : "";
});
export var Li = styled.li.withConfig({
    componentId: "sc-51fbd387-2"
})(_templateObject12(), function(param) {
    var selected = param["aria-selected"];
    return !selected ? colors.$ffffff : colors.$bebebe;
}, function(param) {
    var variant = param.variant;
    return variant ? variants.li[variant] : "";
});
export var Child = styled.div.withConfig({
    componentId: "sc-51fbd387-3"
})(_templateObject13(), colors.$ffffff, pxToRem(16), function(param) {
    var variant = param.variant;
    return variant ? variants.child[variant] : "";
});
