import { setTrackView } from "@vfit/shared/data-access";
import { useTracking } from "@vfit/shared/providers";
import { getUserInfo } from "./vola.utils";
export var VolaTagging = function() {
    var trackView = useTracking({
        event: [
            "starting_vola"
        ],
        visitorTrackingOpts: getUserInfo(),
        disableInitialTrack: true
    }).trackView;
    setTrackView(trackView);
    return null;
};
