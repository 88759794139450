import React, { useState } from "react";
import { getHome } from "@vfit/shared/data-access";
export var useMenu = function() {
    var ref = useState({
        isHovered: false,
        menuIndex: 0
    }), menuState = ref[0], setMenuState = ref[1];
    return {
        menuState: menuState,
        setMenuState: setMenuState
    };
};
export var handlerClickMenu = function(evt, menu) {
    var home = getHome();
    evt.preventDefault();
    if (!menu.links) {
        var ref;
        if (!menu.url || menu.url === "/") {
            window.location.href = "/";
        } else if ((menu === null || menu === void 0 ? void 0 : (ref = menu.url) === null || ref === void 0 ? void 0 : ref[0]) === "/") {
            window.open("".concat(home).concat(menu.url), "_self");
        } else {
            window.open(menu.url, "_self");
        }
    }
};
