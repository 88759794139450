import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { useQueries, useQuery } from "react-query";
export var handleUseQuery = function(config) {
    var queryKey = config.queryKey, queryFn = config.queryFn, options = config.options;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(queryKey, queryFn, _object_spread({
        retry: 0
    }, options));
};
export default handleUseQuery;
export var handleUseQueryes = function(queries) {
    var _Object;
    var queryNames = [];
    var queriesFormatted = queries.map(function(query) {
        var queryKey = Array.isArray(query.queryKey) ? query.queryKey[0] : query.queryKey;
        queryNames.push(queryKey);
        return _object_spread({
            queryKey: query.queryKey,
            queryFn: query.queryFn
        }, query.options);
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    var resParallelQueries = useQueries(queriesFormatted);
    var formattedRes = resParallelQueries.map(function(queryRes, index) {
        return _define_property({}, queryNames[index], _object_spread({}, queryRes));
    });
    return (_Object = Object).assign.apply(_Object, [
        {}
    ].concat(_to_consumable_array(formattedRes)));
};
