import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  border-radius: 6px;\n  padding: 20px 16px;\n  display: flex;\n  cursor: pointer;\n  ",
        "\n\n  input[type='radio'] {\n    ",
        ";\n    appearance: none;\n    border: 1px solid ",
        ";\n    outline: none;\n  }\n\n  input[type='radio']::before {\n    ",
        ";\n    content: '';\n    display: block;\n    border-color: ",
        ";\n  }\n\n  input[type='radio']:checked {\n    border-color: ",
        ";\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  input[type='radio']:checked::before {\n    background: ",
        ";\n    flex-shrink: 0;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { colors } from "@vfit/shared/themes";
import styled from "styled-components";
var circleWithSize = function(size) {
    return "\n  width: ".concat(size, "px;\n  height: ").concat(size, "px;\n  border-radius: 50%;\n");
};
export var Container = styled.div.withConfig({
    componentId: "sc-6ffcfdad-0"
})(_templateObject(), function(props) {
    return props.index === props.colorIndex ? "border: 2px solid ".concat(colors.$00697c, ";") : "border: 1px solid ".concat(colors.$bebebe, ";");
}, circleWithSize(20), colors.$bebebe, circleWithSize(12), colors.$00697c, colors.$00697c, colors.$00697c);
export var CheckboxContainer = styled.div.withConfig({
    componentId: "sc-6ffcfdad-1"
})(_templateObject1(), function(props) {
    return "align-self: ".concat(props.align);
});
