import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { getSystemEnvironment } from "@vfit/shared/hooks";
import { checkWindow } from "@vfit/shared/data-access";
import { retrieveBasePageName, retrievePageChannel } from "./utils";
export var trackOptions = function(event, event_label, opts, product_name, cart_product) {
    var pageData = {
        basePageName: "",
        pageChannel: "",
        pageName: "",
        pageType: "",
        sysEnv: getSystemEnvironment()
    };
    var hostname = window.location.hostname.split(".");
    var querystringParams = checkWindow() && window.location.search;
    var urlParams = new URLSearchParams(querystringParams);
    var isAppFromQueryString = urlParams.get("app");
    var isApp = checkWindow() && localStorage.getItem("appSession");
    pageData.pageType = hostname[0] === "www" ? hostname[1] : hostname[0];
    pageData.basePageName = retrieveBasePageName(cart_product, product_name);
    pageData.pageName = (opts === null || opts === void 0 ? void 0 : opts.page_name_forced_override) ? opts === null || opts === void 0 ? void 0 : opts.page_name_forced_override : "".concat(pageData.basePageName, ":").concat(event_label || (event === null || event === void 0 ? void 0 : event[0]) || "");
    pageData.pageChannel = retrievePageChannel(pageData.pageType);
    // eslint-disable-next-line no-param-reassign
    opts === null || opts === void 0 ? void 0 : delete opts["eventLabel"];
    return _object_spread(_object_spread_props(_object_spread({
        // Static info
        page_country: "IT",
        page_language: "IT",
        page_locale: "it-IT",
        page_platform: "NextJS"
    }, opts), {
        // Page information
        page_name: pageData.pageName,
        page_environment: pageData.sysEnv,
        event_name: event,
        page_channel: pageData.pageChannel
    }), (event_label || (event === null || event === void 0 ? void 0 : event[0])) && {
        event_label: event_label || (event === null || event === void 0 ? void 0 : event[0])
    }, !!isAppFromQueryString || isApp ? {
        onetrust_sdk_consent: "1:1,2:1,3:1,4:1"
    } : null);
};
export var computeDatalayer = function(event, event_label, opts, pageProduct, cartProduct, visitorTrackingOpts) {
    var ref;
    return _object_spread({}, pageProduct, cartProduct, visitorTrackingOpts, trackOptions(event, event_label, opts, Array.isArray(pageProduct === null || pageProduct === void 0 ? void 0 : pageProduct.product_name) ? (pageProduct === null || pageProduct === void 0 ? void 0 : (ref = pageProduct.product_name) === null || ref === void 0 ? void 0 : ref[0]) || "" : (pageProduct === null || pageProduct === void 0 ? void 0 : pageProduct.product_name) || "", cartProduct));
};
