import { useCallback, useEffect, useMemo, useRef, useState } from "react";
/**
 * Custom React hook for managing animations.
 *
 * @param {UseAnimationProps} props - The properties for the hook.
 * @param {unknown} props.thingsToWait - Things to be rendered with a delay.
 * @param {boolean} [props.isInfinite=false] - Whether the animation should be infinite.
 * @param {string} [props.prefix] - The prefix for the animation class.
 * @param {boolean} props.show - Whether the animation should be shown.
 * @param {boolean} [props.skipOnMount=false] - Whether the animation should be skipped on mount.
 * @return {UseAnimationObject} - The animation state and callbacks.
 * @property {string|undefined} animationClass - The animation class based on the show property.
 * @property {unknown} delayedThings - Things to be rendered with a delay.
 * @property {() => void} onAnimationEnd - The callback function to be called when the animation ends.
 * @property {boolean} shouldRender - Whether the children should be rendered.
 */ export var useAnimation = function(param) {
    var _isInfinite = param.isInfinite, isInfinite = _isInfinite === void 0 ? false : _isInfinite, prefix = param.prefix, show = param.show, _skipOnMount = param.skipOnMount, skipOnMount = _skipOnMount === void 0 ? false : _skipOnMount, thingsToWait = param.thingsToWait;
    var ref = useState(show), shouldRender = ref[0], setShouldRender = ref[1];
    var ref1 = useState(thingsToWait), delayedThings = ref1[0], setDelayedThings = ref1[1];
    var isMounted = useRef(false);
    var enter = prefix ? "".concat(prefix, "-enter") : "enter";
    var exit = prefix ? "".concat(prefix, "-exit") : "exit";
    var animationClass = useMemo(function() {
        if (!isMounted.current && skipOnMount) {
            isMounted.current = true;
            return undefined;
        }
        return show ? enter : exit;
    }, [
        enter,
        exit,
        show,
        skipOnMount
    ]);
    var onAnimationEnd = useCallback(function() {
        if (animationClass === exit) {
            setShouldRender(false);
            setDelayedThings(thingsToWait);
        }
    }, [
        animationClass,
        exit,
        thingsToWait
    ]);
    useEffect(function() {
        if (animationClass === enter) {
            setDelayedThings(thingsToWait);
            setShouldRender(true);
        }
        if (isInfinite) {
            onAnimationEnd();
        }
    }, [
        animationClass,
        enter,
        isInfinite,
        onAnimationEnd,
        thingsToWait
    ]);
    return {
        animationClass: animationClass,
        delayedThings: delayedThings,
        onAnimationEnd: onAnimationEnd,
        shouldRender: shouldRender
    };
};
