export var ISIAFlowType;
(function(ISIAFlowType) {
    ISIAFlowType[ISIAFlowType["RECURRENT"] = 1] = "RECURRENT";
    ISIAFlowType[ISIAFlowType["INSTANT"] = 2] = "INSTANT";
})(ISIAFlowType || (ISIAFlowType = {}));
export var IUpdateProductPriceType;
(function(IUpdateProductPriceType) {
    IUpdateProductPriceType[IUpdateProductPriceType["NONE"] = 0] = "NONE";
    IUpdateProductPriceType[IUpdateProductPriceType["EDIT"] = 1] = "EDIT";
    IUpdateProductPriceType[IUpdateProductPriceType["EDIT_WALLET"] = 2] = "EDIT_WALLET";
    IUpdateProductPriceType[IUpdateProductPriceType["RESET"] = 3] = "RESET";
})(IUpdateProductPriceType || (IUpdateProductPriceType = {}));
export var EMethodCode;
(function(EMethodCode) {
    EMethodCode["CREDIT_CARD"] = "creditCard";
    EMethodCode["BANK_ACCOUNT"] = "bankAccount";
    EMethodCode["WALLET"] = "wallet";
    EMethodCode["PAYPAL"] = "payPal";
    EMethodCode["DEBIT_CARD"] = "debitCard";
    EMethodCode["PREPAID_CARD"] = "prepaidCard";
})(EMethodCode || (EMethodCode = {}));
