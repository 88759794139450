import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 99;\n  background-color: #000000c4;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  left: 0;\n  right: 0;\n  top: 0;\n  background-color: ",
        ";\n  overflow: hidden;\n\n  .closing-icon {\n    position: absolute;\n    right: 0;\n    margin-top: 21.5px;\n    margin-right: 21.5px;\n\n    &:hover {\n      cursor: pointer;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    .closing-icon {\n      margin-top: 48px;\n      margin-right: 38px;\n    }\n\n    svg {\n      width: 32px;\n      height: 32px;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  height: 100vh;\n  overflow: auto;\n  margin: 0;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 24px 16px;\n  height: 100%;\n\n  @media (min-width: ",
        ") {\n    padding: 80px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import { breakpoints, colors } from "@vfit/shared/themes";
import styled from "styled-components";
export var Background = styled.div.withConfig({
    componentId: "sc-1f61a0b0-0"
})(_templateObject());
export var Container = styled.div.withConfig({
    componentId: "sc-1f61a0b0-1"
})(_templateObject1(), colors.$ffffff, breakpoints.tablet);
export var ModalContent = styled.div.withConfig({
    componentId: "sc-1f61a0b0-2"
})(_templateObject2());
export var Padder = styled.div.withConfig({
    componentId: "sc-1f61a0b0-3"
})(_templateObject3(), breakpoints.tablet);
