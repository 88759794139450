import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { IActionStyle, IActionType } from "@vfit/shared/models";
import { AppMobile, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { volaModalManager } from "@vfit/shared/providers";
import { openPopup } from "./openPopup";
import { openLeadModal } from "./openLeadModal";
import { manageErrorLeadPlatform, manageSuccessLeadPlatform } from "./cmsForm";
import { openDeacivationModal } from "./openDeacivationModal";
export var getVariantByActionStyle = function(actionStyle) {
    if (!actionStyle) return "primary";
    switch(actionStyle){
        case IActionStyle.PRIMARY:
        case IActionStyle.PRIMARY.toString():
        case "primary":
            return "primary";
        case IActionStyle.SECONDARY:
        case IActionStyle.SECONDARY.toString():
        case "secondary":
            return "secondary";
        case IActionStyle.TERTIARY:
        case IActionStyle.TERTIARY.toString():
        case "tertiary":
            return "tertiary";
        default:
            return "primary";
    }
};
export var getButtonActionByActionType = function(actionSlide, push, queryClient, slide) {
    if (!actionSlide) return;
    var pstEnrgEvent;
    var hubCatalogEvent;
    var buttonType = (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.type) ? +actionSlide.type : 0;
    switch(buttonType){
        case IActionType.CUSTOM_URL:
            if (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.url) {
                if (AppMobile.checkIsApp() && (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.isBlank) === "true") {
                    AppMobile.openInBrowser(actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.url);
                } else {
                    window.open(actionSlide.url, (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.isBlank) === "true" ? "_blank" : "_self");
                }
            }
            break;
        case IActionType.COVERAGE_TOOL:
            break;
        case IActionType.CALL_ME_NOW:
            if (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.callMeNow) openPopup(actionSlide.callMeNow);
            else if (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.url) openPopup(actionSlide.url);
            break;
        case IActionType.CALL_ME_NOW_SUNRISE:
            if ((actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.title) && (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.url)) {
                var defaultNumber = "";
                var taggingOption;
                var cmsAppClickToCallChoice = {};
                var cmsClickToCallData = null;
                if (AppMobile.checkIsApp()) {
                    var ref, ref1;
                    // TODO: Remove this controls and move in consumer/business.. libs
                    var appSession = getFromLocalStorageByKey("appSession");
                    var isMobileType = (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.localUrl) === "$MOBILE";
                    taggingOption = {
                        product_name: "smart backup",
                        product_category: "addon",
                        product_id: "0054123",
                        product_price: [
                            ""
                        ]
                    };
                    defaultNumber = isMobileType ? appSession === null || appSession === void 0 ? void 0 : appSession.value : (ref1 = appSession === null || appSession === void 0 ? void 0 : (ref = appSession.items) === null || ref === void 0 ? void 0 : ref.find(function(el) {
                        var ref;
                        return (el === null || el === void 0 ? void 0 : (ref = el.type) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "landline";
                    })) === null || ref1 === void 0 ? void 0 : ref1.value;
                    cmsClickToCallData = queryClient.getQueryData("getClickToCallAppConfigurations");
                    cmsAppClickToCallChoice = {
                        title: "Attiva l'offerta",
                        description: "<span>Confermi di voler attivare l'offerta sul numero <b>".concat(defaultNumber, "</b>?</span>")
                    };
                }
                volaModalManager.handleVolaModal(_object_spread({
                    url: (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.url) || "",
                    cmsClickToCallData: _object_spread({}, cmsClickToCallData),
                    defaultNumber: defaultNumber,
                    cmsAppClickToCallChoice: cmsAppClickToCallChoice
                }, taggingOption && {
                    taggingOption: taggingOption
                }));
            }
            break;
        case IActionType.LEAD_MODAL:
            if (queryClient) {
                var allLeads = queryClient === null || queryClient === void 0 ? void 0 : queryClient.getQueryData("getAllLeads");
                // form name
                var ref2 = (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.localUrl) || "";
                var forms = allLeads === null || allLeads === void 0 ? void 0 : allLeads[ref2].forms;
                if (forms && push) {
                    openLeadModal({
                        form: forms,
                        onSuccess: function(submitOutput) {
                            return manageSuccessLeadPlatform(submitOutput, push, queryClient);
                        },
                        onError: function(submitOutput, errorCmsApi) {
                            return manageErrorLeadPlatform(submitOutput, errorCmsApi, push, queryClient);
                        }
                    });
                }
            }
            break;
        case IActionType.DEACTIVATION_MODAL:
            if (queryClient) {
                var allDeactivation = queryClient === null || queryClient === void 0 ? void 0 : queryClient.getQueryData("getAllDeactivationModal");
                var ref3 = (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.localUrl) || "";
                var forms1 = allDeactivation === null || allDeactivation === void 0 ? void 0 : allDeactivation[ref3];
                if (forms1 && push) {
                    openDeacivationModal({
                        form: forms1
                    });
                }
            }
            break;
        case IActionType.LOCAL_URL:
            if ((actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.localUrl) && push) push("/".concat(actionSlide.localUrl), undefined, {
                shallow: true
            });
            break;
        case IActionType.POST_ENRG:
            pstEnrgEvent = new CustomEvent("enrgServiceNotificationEvent");
            document.dispatchEvent(pstEnrgEvent);
            break;
        case IActionType.HUB_CATALOG_ACT:
            hubCatalogEvent = new CustomEvent("hubAppActivationServiceEvent", {
                detail: slide
            });
            document.dispatchEvent(hubCatalogEvent);
            break;
        default:
            break;
    }
};
/**
 * Check if string is JSON
 * @param str
 */ export var isJSON = function(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};
var navigationButtonTypes = [
    IActionType.CUSTOM_URL,
    IActionType.LOCAL_URL
];
export var getActionUrlByActionType = function(btn) {
    return btn.url && btn.type && navigationButtonTypes.includes(Number(btn.type)) ? btn.localUrl || btn.url : undefined;
};
