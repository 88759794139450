import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex: 1;\n  align-self: center;\n  margin: 0 auto;\n\n  @media (min-width: ",
        ") {\n    width: 327px;\n    flex-direction: column;\n    margin-bottom: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 680px;\n    margin-bottom: 48px;\n    flex-direction: row-reverse;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 1062px;\n    margin-bottom: 48px;\n    ",
        "\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  overflow: hidden;\n\n  @media (min-width: ",
        ") {\n    flex: none;\n    height: 150px;\n    border-radius: 20px 20px 0 0;\n  }\n\n  @media (min-width: ",
        ") {\n    flex: 1;\n    height: unset;\n    border-radius: 0 20px 20px 0;\n  }\n\n  @media (min-width: ",
        ") {\n    flex: 1;\n    height: unset;\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  border: 1px solid ",
        ";\n  flex: 1;\n\n  @media (min-width: ",
        ") {\n    border-radius: 0 0 20px 20px;\n  }\n\n  @media (min-width: ",
        ") {\n    border-radius: 20px 0 0 20px;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin: 36px 24px;\n\n  @media (min-width: ",
        ") {\n    margin: 64px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  display: flex;\n\n  div {\n    font-family: ",
        ";\n    color: ",
        ";\n    font-weight: 400;\n    margin: 0 10px 24px 0;\n    ",
        "\n\n    img {\n      width: 24px;\n    }\n\n    @media (min-width: ",
        ") {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  div {\n    text-align: left;\n    margin: 0 0 30px;\n    font-family: ",
        ";\n    color: ",
        ";\n    font-weight: 400;\n    ",
        "\n\n    img {\n      display: none;\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 35px;\n  font-family: ",
        ";\n  ",
        "\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 35px;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  border-spacing: 10px;\n  display: flex;\n  gap: 16px;\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  align-self: center;\n  font-weight: 400;\n  font-family: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-style: normal;\n  width: stretch;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  gap: 32px;\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  align-self: center;\n  text-align: center;\n  font-weight: 400;\n  font-family: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from "@vfit/shared/themes";
export var TechContainer = styled.div.withConfig({
    componentId: "sc-165e045a-0"
})(_templateObject(), breakpoints.mobile, breakpoints.tablet, breakpoints.desktop, function(props) {
    return props.reverse ? "flex-direction: row;" : "flex-direction: row-reverse;";
});
export var TechImage = styled.div.withConfig({
    componentId: "sc-165e045a-1"
})(_templateObject1(), breakpoints.mobile, breakpoints.tablet, breakpoints.desktop, function(props) {
    return props.reverse ? "border-radius: 20px 0 0 20px;" : "border-radius: 0 20px 20px 0;";
});
export var TechCardDesc = styled.div.withConfig({
    componentId: "sc-165e045a-2"
})(_templateObject2(), colors.$bebebe, breakpoints.mobile, breakpoints.tablet, breakpoints.desktop, function(props) {
    return !props.reverse ? "border-radius: 20px 0 0 20px;" : "border-radius: 0 20px 20px 0;";
});
export var TechCardDescContainer = styled.div.withConfig({
    componentId: "sc-165e045a-3"
})(_templateObject3(), breakpoints.desktop);
export var TechTitle = styled.div.withConfig({
    componentId: "sc-165e045a-4"
})(_templateObject4(), fonts.exbold, colors.$262626, pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var TechDesc = styled.div.withConfig({
    componentId: "sc-165e045a-5"
})(_templateObject5(), fonts.regular, colors.$262626, pxToCssFont(18, 24));
export var ExtraItemListTitle = styled.div.withConfig({
    componentId: "sc-165e045a-6"
})(_templateObject6(), fonts.exbold, pxToCssFont(20, 30));
export var TechIcons = styled.div.withConfig({
    componentId: "sc-165e045a-7"
})(_templateObject7());
export var ExtraItemList = styled.div.withConfig({
    componentId: "sc-165e045a-8"
})(_templateObject8());
export var Title = styled.span.withConfig({
    componentId: "sc-165e045a-9"
})(_templateObject9(), fonts.exbold, breakpoints.tablet, pxToCssFont(16, 22), breakpoints.desktop, pxToCssFont(20, 30));
export var ItemRow = styled.div.withConfig({
    componentId: "sc-165e045a-10"
})(_templateObject10(), fonts.regular, pxToCssFontSize(16), breakpoints.desktop, pxToCssFont(18, 24));
export var BodyContainer = styled.div.withConfig({
    componentId: "sc-165e045a-11"
})(_templateObject11());
export var BodyGroup = styled.div.withConfig({
    componentId: "sc-165e045a-12"
})(_templateObject12());
export var Body = styled.span.withConfig({
    componentId: "sc-165e045a-13"
})(_templateObject13(), fonts.regular, breakpoints.mobile, pxToCssFont(12, 16), breakpoints.desktop, pxToCssFont(16, 22));
