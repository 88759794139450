import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  body {\n    overflow: hidden;\n  }\n\n  #sticky-offer-summary {\n    visibility: hidden;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled, { createGlobalStyle } from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var OverlayGlobalStyle = createGlobalStyle(_templateObject());
// actually used only for VBI vola-ctc modal
export var WrapperOverrideStyle = styled.div.withConfig({
    componentId: "sc-8e6ad88f-0"
})(_templateObject1(), function(props) {
    return props.wrapperId && "\n      div #".concat(props.wrapperId, " {\n        padding: 40px 10px 10px;\n\n        @media (min-width: ").concat(breakpoints.tablet, ") {\n          padding: 40px;\n        }\n\n        @media (min-width: ").concat(breakpoints.desktop, ") {\n          padding: 40px 60px;\n        }\n\n        #").concat(props.wrapperId, "-closing-icon-container {\n          position: absolute;\n          top: 16px;\n          right: 24px;\n\n          span {\n            float: none;\n            margin: 0;\n          }\n        }\n\n        .modalContent {\n          margin: 0;\n          height: 100%;\n\n          > div {\n            padding: 0\n          }\n        }\n      }\n    ");
});
