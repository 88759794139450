import { requireStaticImageAtomComponent } from "../require-media";
var FTTH_IMAGE = "logo-ftth.png";
var FTTC_IMAGE = "logo-fttc.png";
var FWA_IMAGE = "logo-fwa.png";
var ADSL_IMAGE = "logo-adsl.png";
// removed code btoa nodejs 14
var ALL_TECHS = [
    {
        tech: "FTTH",
        code: "12|13",
        image: FTTH_IMAGE
    },
    {
        tech: "FTTC",
        code: "14|15",
        image: FTTC_IMAGE
    },
    {
        tech: "ADSL",
        code: "16|17",
        image: ADSL_IMAGE
    },
    {
        tech: "FWA",
        code: "18|19",
        image: FWA_IMAGE,
        variants: [
            {
                tech: "OUTDOOR",
                code: "20"
            },
            {
                tech: "OUTDOOR 4G",
                code: "21"
            },
            {
                tech: "OUTDOOR 5G",
                code: "22"
            },
            {
                tech: "INDOOR",
                code: "23"
            },
            {
                tech: "INDOOR 4G",
                code: "24"
            },
            {
                tech: "INDOOR 5G",
                code: "25"
            },
            {
                tech: "4G",
                code: "26"
            },
            {
                tech: "5G",
                code: "27"
            }, 
        ]
    }, 
];
var replaceAll = function(str, search, replacement) {
    return str.split(search).join(replacement);
};
var getVariants = function(tech) {
    var ref;
    var localVariants = (tech === null || tech === void 0 ? void 0 : tech.variants) && (tech === null || tech === void 0 ? void 0 : (ref = tech.variants) === null || ref === void 0 ? void 0 : ref.length) > 0 ? tech.variants : [];
    var variants = (localVariants === null || localVariants === void 0 ? void 0 : localVariants.length) > 1 ? localVariants === null || localVariants === void 0 ? void 0 : localVariants.map(function(el) {
        return {
            tech: "".concat(tech.tech, " ").concat(el.tech),
            code: "".concat(tech.code, "|").concat(el.code)
        };
    }) : localVariants;
    variants.unshift({
        tech: tech.tech,
        code: tech.code
    });
    return (variants === null || variants === void 0 ? void 0 : variants.sort(function(a, b) {
        return b.tech.length - a.tech.length;
    })) || [];
};
var purifyTextWithLogo = function(sanitizeText) {
    var organizedText = sanitizeText;
    var lineSize = 13;
    ALL_TECHS.forEach(function(tech) {
        var variants = getVariants(tech);
        variants.forEach(function(variant) {
            organizedText = replaceAll(organizedText, variant.tech, "".concat(variant.code));
        });
        variants.forEach(function(variant) {
            organizedText = replaceAll(organizedText, variant.code, "".concat(variant.tech, " <img width='").concat(lineSize, "' alt=\"").concat(variant.tech, "\" src='").concat(requireStaticImageAtomComponent((tech === null || tech === void 0 ? void 0 : tech.image) || "").src, "'/>"));
        });
    });
    return organizedText;
};
export { purifyTextWithLogo };
