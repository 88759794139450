import { getLivePreviewMedia } from "./checkLivePreview";
var PLACEHOLDER_IMAGE = "https://www.vodafone.it/portal/assets/img/menu/vodafone-logo.png";
var requireVideo = function(video, videoMobile, isMobile) {
    var ref, ref1;
    var videoUrl = isMobile ? videoMobile || video : video;
    var livePreviewMedia = getLivePreviewMedia(videoUrl, "videos");
    if (livePreviewMedia) return livePreviewMedia;
    var splitExt = videoUrl === null || videoUrl === void 0 ? void 0 : videoUrl.split(".");
    if (!splitExt) return "";
    var haveGoodNetwork = (navigator === null || navigator === void 0 ? void 0 : (ref = navigator["connection"]) === null || ref === void 0 ? void 0 : ref["downlink"]) ? (navigator === null || navigator === void 0 ? void 0 : (ref1 = navigator["connection"]) === null || ref1 === void 0 ? void 0 : ref1["downlink"]) >= 5 : true;
    var quality = haveGoodNetwork ? "_medium" : "_low";
    var videoSplitted = "".concat(splitExt[0]).concat(quality);
    return "".concat(process.env["NX_HOSTNAME"], "/").concat(process.env["NX_MEDIA_PATHNAME"] || "", "/").concat(videoSplitted, ".").concat(splitExt[1]);
};
var lambdaCustomLoaderHandler = function(param) {
    var src = param.src, width = param.width;
    var ref;
    var isDevLocal = ((ref = process.env) === null || ref === void 0 ? void 0 : ref["NODE_ENV"]) === "development";
    if (isDevLocal) return "".concat(process.env["NX_HOSTNAME"], "/").concat(width, "&text=").concat(src);
    if (src === PLACEHOLDER_IMAGE) {
        return PLACEHOLDER_IMAGE;
    }
    var ext = src.substring(src.lastIndexOf(".") + 1, src.length).toLowerCase();
    if ([
        "svg",
        "gif",
        "json"
    ].includes(ext)) return "".concat(process.env["NX_HOSTNAME"], "/").concat(process.env["NX_MEDIA_PATHNAME"] || "", "/").concat(src);
    return "".concat(process.env["NX_HOSTNAME"], "/").concat(process.env["NX_MEDIA_PATHNAME"] || "", "/").concat(src.replace(/.([^.]*)$/, "_$1"), "_w").concat(width, ".").concat(process.env["NX_MEDIA_IMG_FORMAT"]);
};
var requireStaticImageComponent = function(image, imageMobile, isMobile) {
    return lambdaCustomLoaderHandler({
        src: isMobile ? imageMobile : image,
        width: 2048
    });
};
var getObjImage = function(filename) {
    var src = lambdaCustomLoaderHandler({
        src: filename,
        width: 2048
    });
    var srcSet = [
        640,
        750,
        828,
        1080,
        1200,
        1920,
        2048
    ].reduce(function(prev, breakpoint) {
        return "".concat(prev ? "".concat(prev, ",") : "").concat(lambdaCustomLoaderHandler({
            src: filename,
            width: breakpoint
        }), " ").concat(breakpoint, "w");
    }, "");
    return {
        src: src,
        srcSet: srcSet
    };
};
var requireStaticImageAtomComponent = function(image, imageMobile, isMobile) {
    var filename = isMobile ? imageMobile || image : image;
    return getObjImage(filename);
};
export { PLACEHOLDER_IMAGE, requireVideo, requireStaticImageComponent, requireStaticImageAtomComponent, lambdaCustomLoaderHandler };
