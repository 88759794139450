import { handleEnv } from "@vfit/shared/providers";
import { isJSON } from "./actions";
export var ENCODE_VALUE_PREFIX = "data/base64/";
export var getItemCookie = function(key) {
    var ref, ref1;
    var itemCookie = (ref1 = document === null || document === void 0 ? void 0 : (ref = document.cookie) === null || ref === void 0 ? void 0 : ref.split("; ")) === null || ref1 === void 0 ? void 0 : ref1.reduce(function(total, currentCookie) {
        var item = currentCookie.split("=");
        var storedKey = item[0];
        var storedValue = item[1];
        return key === storedKey ? decodeURIComponent(storedValue) : total;
    }, "");
    var valueSplitted = itemCookie === null || itemCookie === void 0 ? void 0 : itemCookie.split(ENCODE_VALUE_PREFIX);
    var valueToAnalyze = (valueSplitted === null || valueSplitted === void 0 ? void 0 : valueSplitted.length) > 1 ? decodeURIComponent(atob(valueSplitted === null || valueSplitted === void 0 ? void 0 : valueSplitted[1])) || "" : itemCookie;
    if (isJSON(valueToAnalyze)) return JSON.parse(valueToAnalyze);
    return valueToAnalyze;
};
/**
 * Method to return the value to encode
 * append prefix
 * @param value
 */ export var getValueToEncode = function(value) {
    return "".concat(ENCODE_VALUE_PREFIX).concat(value);
};
/**
 * Method to set the item cookie
 * if needed the value in encoded in base64
 * @param key
 * @param value
 * @param numberOfDays
 * @param enableEncode
 * @param domain
 */ export var setItemCookie = function(key, value, numberOfDays, enableEncode) {
    var domain = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : "vodafone.it";
    var isDevLocal = handleEnv("NODE_ENV") === "development";
    var isValueString = typeof value === "string";
    var parsedValue = isValueString ? value : JSON.stringify(value);
    var enableEncodeToSave = isValueString ? enableEncode : true;
    var valueToSave = enableEncodeToSave ? getValueToEncode(btoa(encodeURIComponent(parsedValue))) : parsedValue;
    var now = new Date();
    // set the time to be now + numberOfDays
    now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
    if (isDevLocal) {
        document.cookie = "".concat(key, "=").concat(valueToSave, "; expires=").concat(now.toUTCString(), "; path=/;");
    } else {
        document.cookie = "".concat(key, "=").concat(valueToSave, "; expires=").concat(now.toUTCString(), "; path=/; domain=").concat(domain);
    }
};
