import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    font-size: 0.75rem; /* 12px */\n    letter-spacing: 0.5px;\n    line-height: 1; /* differs from DS typography */\n    text-transform: uppercase;\n  "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n    font-size: 1rem; /* 16px */\n    letter-spacing: 0.5px;\n    line-height: 1.375rem; /* differs from DS typography */\n    text-transform: capitalize;\n  "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
import { cssfonts } from "../cssfonts";
export var tag = {
    bold: css(_templateObject(), cssfonts.bold),
    regular: css(_templateObject1(), cssfonts.regular)
};
