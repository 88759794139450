import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 4;\n  background-color: #000000c4;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  top: ",
        "px;\n  border-top-left-radius: ",
        "px;\n  border-top-right-radius: ",
        "px;\n  background-color: ",
        ";\n  overflow: hidden;\n\n  @media (min-width: ",
        ") {\n    left: 50%;\n    top: 50%;\n    display: table;\n    transform: translate(-50%, -50%);\n    width: 62.2%;\n    border-radius: 20px;\n  }\n\n  @media (min-width: ",
        ") {\n    left: 50%;\n    top: 50%;\n    display: table;\n    transform: translate(-50%, -50%);\n    max-width: 842px;\n  }\n\n  .closing-icon {\n    position: absolute;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    top: 21.5px;\n    right: 21.5px;\n\n    @media (min-width: ",
        ") {\n      top: 25.5px;\n    }\n\n    @media (min-width: ",
        ") {\n      top: 29.5px;\n      right: 29.5px;\n    }\n\n    &:hover {\n      cursor: pointer;\n    }\n  }\n\n  .back-icon {\n    position: relative;\n    display: flex;\n    align-items: center;\n    top: 8.5px;\n    left: 18.75px;\n    height: 17px;\n    width: 18.75px;\n\n    @media (min-width: ",
        ") {\n      top: 10.5px;\n    }\n\n    @media (min-width: ",
        ") {\n      top: 14.5px;\n      left: 26.75px;\n    }\n  }\n\n  .back-icon:hover,\n  .closing-icon:hover {\n    cursor: pointer;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  position: relative;\n  background-color: ",
        ";\n  z-index: 9999;\n  width: 100%;\n  border-top-left-radius: 20px;\n  border-top-right-radius: 20px;\n  height: 36px;\n\n  @media (min-width: ",
        ") {\n    height: 38.5px;\n  }\n\n  @media (min-width: ",
        ") {\n    height: 42.5px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  max-height: 90vh;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  height: 100%;\n  -ms-overflow-style: none; /* for Internet Explorer, Edge */\n\n  ::-webkit-scrollbar {\n    display: none; /* for Chrome, Safari, and Opera */\n  }\n\n  @media (min-width: ",
        ") {\n    height: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    height: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  background-color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    left: 50%;\n    top: 50%;\n    display: table;\n    transform: translate(-50%, -50%);\n    width: 62.2%;\n  }\n\n  .closing-icon:hover {\n    cursor: pointer;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: space-between;\n  padding: 25px;\n  border-bottom: 1px solid #5e5e5e;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: space-between;\n  padding: 30px 25px 25px;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  margin-top: auto;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 25px;\n\n  button {\n    text-transform: uppercase;\n    max-width: 300px;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors } from "@vfit/shared/themes";
export var Background = styled.div.withConfig({
    componentId: "sc-ea6a8d33-0"
})(_templateObject());
export var Container = styled.div.withConfig({
    componentId: "sc-ea6a8d33-1"
})(_templateObject1(), function(props) {
    return props.withBorderRadius ? "66" : "0";
}, function(props) {
    return props.withBorderRadius ? "20" : "0";
}, function(props) {
    return props.withBorderRadius ? "20" : "0";
}, colors.$ffffff, breakpoints.tablet, breakpoints.desktop, breakpoints.tablet, breakpoints.desktop, breakpoints.tablet, breakpoints.desktop);
export var IconsContainer = styled.div.withConfig({
    componentId: "sc-ea6a8d33-2"
})(_templateObject2(), colors.$ffffff, breakpoints.tablet, breakpoints.desktop);
export var ModalContent = styled.div.withConfig({
    componentId: "sc-ea6a8d33-3"
})(_templateObject3(), breakpoints.tablet, function(props) {
    var ref;
    return props === null || props === void 0 ? void 0 : (ref = props.height) === null || ref === void 0 ? void 0 : ref.tablet;
}, breakpoints.desktop, function(props) {
    var ref;
    return props === null || props === void 0 ? void 0 : (ref = props.height) === null || ref === void 0 ? void 0 : ref.desktop;
});
// TODO: wip FIGMA modal error styles
export var ContainerModalError = styled.div.withConfig({
    componentId: "sc-ea6a8d33-4"
})(_templateObject4(), colors.$ffffff, breakpoints.tablet);
export var ModalErrorHeader = styled.div.withConfig({
    componentId: "sc-ea6a8d33-5"
})(_templateObject5());
export var ModalErrorContent = styled.div.withConfig({
    componentId: "sc-ea6a8d33-6"
})(_templateObject6());
export var ModalErrorFooter = styled.div.withConfig({
    componentId: "sc-ea6a8d33-7"
})(_templateObject7());
