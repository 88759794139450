import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  & > svg {\n    animation: none !important;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  &.icon-error-enter > svg {\n    animation: ",
        " 0.3s forwards;\n  }\n\n  &.icon-error-exit > svg {\n    animation: ",
        " 0.3s forwards;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  &.icon-loading-enter > svg {\n    animation: ",
        " 2s linear infinite;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  &.icon-loading-enter > svg > circle {\n    animation: ",
        " 1.5s ease-in-out infinite;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n      border-radius: ",
        ";\n      padding: ",
        ";\n    "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n      border-radius: ",
        ";\n      padding: ",
        ";\n    "
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n      border-radius: ",
        ";\n      padding: ",
        ";\n    "
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  right: 0;\n  top: 0;\n\n  & path {\n    transition: all 0.3s ease;\n  }\n\n  ",
        "\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  pointer-events: none;\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  pointer-events: none;\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        ";\n  border: none;\n  cursor: ",
        ";\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n  pointer-events: none;\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { animations, pxToRem } from "@vfit/shared/themes";
var noAnimation = css(_templateObject());
var fadeAnimation = css(_templateObject1(), animations.fadeIn, animations.fadeOut);
var spinAnimation = css(_templateObject2(), animations.spin);
var dashAnimation = css(_templateObject3(), animations.dash);
var variants = {
    svgContainer: {
        animated: css(_templateObject4(), pxToRem(0, 5, 5, 0), pxToRem(18, 16)),
        "mva:animated": css(_templateObject5(), pxToRem(0, 6, 6, 0), pxToRem(10, 16)),
        "mva:static": css(_templateObject6(), pxToRem(0, 6, 6, 0), pxToRem(10, 16))
    }
};
var svgContainer = css(_templateObject7(), function(param) {
    var variant = param.variant;
    return variant ? variants.svgContainer[variant] : "";
});
export var Icon = styled.span.withConfig({
    componentId: "sc-c56b50c0-0"
})(_templateObject8(), svgContainer);
export var IconLoading = styled.span.withConfig({
    componentId: "sc-c56b50c0-1"
})(_templateObject9(), svgContainer, spinAnimation, dashAnimation);
export var IconButton = styled.button.withConfig({
    componentId: "sc-c56b50c0-2"
})(_templateObject10(), svgContainer, function(param) {
    var isClearable = param.isClearable;
    return !isClearable ? "" : noAnimation;
}, function(param) {
    var disabled = param.disabled;
    return !disabled ? "pointer" : "not-allowed";
});
export var IconError = styled.span.withConfig({
    componentId: "sc-c56b50c0-3"
})(_templateObject11(), svgContainer, fadeAnimation);
