/**
 * Method to scroll to ref section or anchor if section not exists
 * @param refSection
 * @param anchorEl
 */ var scrollToSection = function(refSection, anchorEl, offset) {
    if (refSection) {
        refSection.scrollIntoView({
            behavior: "smooth"
        });
    } else if (offset === undefined) {
        anchorEl === null || anchorEl === void 0 ? void 0 : anchorEl.scrollIntoView({
            behavior: "smooth"
        });
    } else {
        var viewportYDelta = (anchorEl === null || anchorEl === void 0 ? void 0 : anchorEl.getBoundingClientRect().top) || 0;
        var y = window.pageYOffset + viewportYDelta + offset;
        window.scrollTo({
            top: y,
            behavior: "smooth"
        });
    }
};
export { scrollToSection };
