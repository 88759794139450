import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n      border-color: ",
        ";\n      border-radius: ",
        ";\n      height: ",
        ";\n      padding: ",
        ";\n\n      &.valued + span {\n        ",
        "\n        color: ",
        ";\n        transform: translate3d(0, ",
        ", 0);\n      }\n    "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n      border-color: ",
        ";\n      border-radius: ",
        ";\n      ",
        "\n      height: ",
        ";\n      padding: ",
        ";\n\n      &:focus {\n        border-color: ",
        ";\n        box-shadow: ",
        " ",
        ";\n      }\n\n      &.valued + span {\n        ",
        "\n        color: ",
        ";\n        transform: translate3d(0, ",
        ", 0);\n      }\n    "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n      border-color: ",
        ";\n      border-radius: ",
        ";\n      ",
        "\n      height: ",
        ";\n      padding: ",
        ";\n\n      &:focus {\n        border-color: ",
        ";\n        box-shadow: ",
        " ",
        ";\n      }\n    "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  border-width: 1px;\n  border-style: solid;\n  color: ",
        ";\n  cursor: ",
        ";\n  line-height: 1;\n  outline: 0; // TODO This is a bad practice\n  text-align: left;\n  transition: border-color 0.3s ease;\n  width: 100%;\n\n  &::file-selector-button {\n    display: none;\n  }\n\n  ",
        ";\n  ",
        "\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  pointer-events: none;\n  transition: all 0.3s ease;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { colors, getTypography, pxToRem } from "@vfit/shared/themes";
var variants = {
    button: {
        animated: css(_templateObject(), function(param) {
            var error = param.error;
            return !error ? colors.$bebebe : colors.$bd0000;
        }, pxToRem(5), pxToRem(60), pxToRem(18, 56, 18, 20), getTypography("body3.regular"), function(param) {
            var disabled = param.disabled;
            return !disabled ? colors.$262626 : colors.$7e7e7e;
        }, pxToRem(-27)),
        "mva:animated": css(_templateObject1(), function(param) {
            var error = param.error;
            return !error ? colors.$bebebe : colors.$bd0000;
        }, pxToRem(6), function(param) {
            var error = param.error;
            return error ? "box-shadow: ".concat(pxToRem(0, 0, 0, 1), " ").concat(colors.$bd0000, ";") : "";
        }, pxToRem(44), pxToRem(9, 54, 9, 15), colors.$0096AD, pxToRem(0, 0, 0, 1), colors.$0096AD, getTypography("body3.regular"), function(param) {
            var disabled = param.disabled;
            return !disabled ? colors.$262626 : colors.$7e7e7e;
        }, pxToRem(-19)),
        "mva:static": css(_templateObject2(), function(param) {
            var error = param.error;
            return !error ? colors.$bebebe : colors.$bd0000;
        }, pxToRem(6), function(param) {
            var error = param.error;
            return error ? "box-shadow: ".concat(pxToRem(0, 0, 0, 1), " ").concat(colors.$bd0000, ";") : "";
        }, pxToRem(44), pxToRem(9, 54, 9, 15), colors.$0096AD, pxToRem(0, 0, 0, 1), colors.$0096AD)
    }
};
export var Button = styled.button.withConfig({
    componentId: "sc-75525674-0"
})(_templateObject3(), getTypography("body1.regular"), function(param) {
    var disabled = param.disabled;
    return !disabled ? colors.$262626 : colors.$7e7e7e;
}, function(param) {
    var disabled = param.disabled;
    return !disabled ? "pointer" : "not-allowed";
}, function(param) {
    var bg = param.bg;
    return bg ? "background-color: ".concat(bg, ";") : "";
}, function(param) {
    var variant = param.variant;
    return variant ? variants.button[variant] : "";
});
export var Placeholder = styled.span.withConfig({
    componentId: "sc-75525674-1"
})(_templateObject4(), colors.$7e7e7e);
