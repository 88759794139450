import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-content: center;\n  width: ",
        ";\n  border-radius: ",
        ";\n  border: 1px solid #bebebe;\n  margin: 0 auto;\n\n  @media (min-width: ",
        ") {\n    justify-content: space-between;\n    width: 100%;\n    max-width: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  padding: ",
        ";\n  @media (min-width: ",
        ") {\n    padding: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    width: 55%;\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-content: center;\n  justify-content: center;\n  border-top-right-radius: ",
        ";\n  border-bottom-right-radius: ",
        ";\n  background: rgb(242, 242, 242);\n  width: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-left: 0;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin: auto;\n  width: 100%;\n  height: 100%;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  height: ",
        ";\n  display: flex;\n  gap: 16px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  width: ",
        ";\n  height: auto;\n  align-content: center;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  width: ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  color: ",
        ";\n\n  img {\n    width: ",
        ";\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin-bottom: ",
        ";\n  font-weight: 400;\n  font-family: ",
        ";\n\n  p {\n    margin: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin-bottom: ",
        ";\n\n  p {\n    margin: 0;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  margin: 25px;\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-a07a6563-0"
})(_templateObject(), pxToRem(327), pxToRem(20), breakpoints.tablet, pxToRem(1280));
export var DescriptionCard = styled.div.withConfig({
    componentId: "sc-a07a6563-1"
})(_templateObject1(), pxToRem(32, 0, 24, 24), breakpoints.tablet, pxToRem(40, 0, 24, 24), breakpoints.desktop, pxToRem(64, 0, 80, 80));
export var QRCodeCard = styled.div.withConfig({
    componentId: "sc-a07a6563-2"
})(_templateObject2(), pxToRem(20), pxToRem(20), pxToRem(405), breakpoints.tablet);
export var QRCode = styled.div.withConfig({
    componentId: "sc-a07a6563-3"
})(_templateObject3());
export var StoreContainer = styled.div.withConfig({
    componentId: "sc-a07a6563-4"
})(_templateObject4(), pxToRem(56.63));
export var Store = styled.div.withConfig({
    componentId: "sc-a07a6563-5"
})(_templateObject5(), pxToRem(152.5));
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-a07a6563-6"
})(_templateObject6(), pxToRem(277));
export var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-a07a6563-7"
})(_templateObject7(), fonts.regular, colors.$7e7e7e, pxToRem(18));
export var Title = styled.div.withConfig({
    componentId: "sc-a07a6563-8"
})(_templateObject8(), pxToCssFont(28, 36), pxToRem(24), fonts.exbold, breakpoints.desktop, pxToCssFont(36, 48));
export var Description = styled.div.withConfig({
    componentId: "sc-a07a6563-9"
})(_templateObject9(), pxToCssFont(18, 24), pxToRem(24));
export var MainContainer = styled.div.withConfig({
    componentId: "sc-a07a6563-10"
})(_templateObject10());
