import { useEffect, useState } from "react";
export var ScrollDirection;
(function(ScrollDirection) {
    ScrollDirection["up"] = "up";
    ScrollDirection["down"] = "down";
})(ScrollDirection || (ScrollDirection = {}));
var useScrollDirectionExp = function(param) {
    var isVisible = param.isVisible;
    var threshold = 100;
    var ref = useState(ScrollDirection.up), scrollDir = ref[0], setScrollDir = ref[1];
    var ref1 = useState(0), translateY = ref1[0], setTranslateY = ref1[1];
    useEffect(function() {
        var previousScrollYPosition = window.scrollY;
        var scrolledMoreThanThreshold = function(currentScrollYPosition) {
            return Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;
        };
        var isScrollingUp = function(currentScrollYPosition) {
            return currentScrollYPosition > previousScrollYPosition && !(previousScrollYPosition > 0 && currentScrollYPosition === 0) && !(currentScrollYPosition > 0 && previousScrollYPosition === 0);
        };
        var updateScrollDirection = function() {
            var currentScrollYPosition = window.scrollY;
            if (scrolledMoreThanThreshold(currentScrollYPosition)) {
                var newScrollDirection = isScrollingUp(currentScrollYPosition) ? ScrollDirection.down : ScrollDirection.up;
                setScrollDir(newScrollDirection);
                previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
                // Check on visibility
                if (isVisible) {
                    if (newScrollDirection === ScrollDirection.up) {
                        setTranslateY(function(prevState) {
                            var res = prevState + 15;
                            return res >= 75 ? 75 : res;
                        });
                    } else {
                        setTranslateY(function(prevState) {
                            var res = prevState - 15;
                            return res <= -75 ? -75 : res;
                        });
                    }
                }
            }
        };
        var onScroll = function() {
            return window.requestAnimationFrame(updateScrollDirection);
        };
        window.addEventListener("scroll", onScroll);
        return function() {
            return window.removeEventListener("scroll", onScroll);
        };
    }, [
        isVisible
    ]);
    return {
        scrollDir: scrollDir,
        translateY: translateY
    };
};
export { useScrollDirectionExp };
