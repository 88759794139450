import { purify } from "@vfit/shared/themes";
var stickyBannerHeaderOrganize = function(cmsConfig) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8;
    return {
        animationDelay: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref = cmsConfig.stickybanner) === null || ref === void 0 ? void 0 : ref.animationDelay) || "",
        animationType: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref1 = cmsConfig.stickybanner) === null || ref1 === void 0 ? void 0 : ref1.animationType) || "slide-down",
        htmlDesktop: ((ref3 = purify([
            (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref2 = cmsConfig.stickybanner) === null || ref2 === void 0 ? void 0 : ref2.htmlDesktop) || ""
        ])) === null || ref3 === void 0 ? void 0 : ref3[0]) || "",
        htmlTablet: ((ref5 = purify([
            (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref4 = cmsConfig.stickybanner) === null || ref4 === void 0 ? void 0 : ref4.htmlTablet) || ""
        ])) === null || ref5 === void 0 ? void 0 : ref5[0]) || "",
        htmlMobile: ((ref7 = purify([
            (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref6 = cmsConfig.stickybanner) === null || ref6 === void 0 ? void 0 : ref6.htmlMobile) || ""
        ])) === null || ref7 === void 0 ? void 0 : ref7[0]) || "",
        useGlobalBanner: (cmsConfig === null || cmsConfig === void 0 ? void 0 : (ref8 = cmsConfig.stickybanner) === null || ref8 === void 0 ? void 0 : ref8.useGlobalBanner) || false
    };
};
export { stickyBannerHeaderOrganize };
