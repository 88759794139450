import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { getItemCookie } from "@vfit/shared/data-access";
export var isOptanonCookieAlertClosed = function() {
    return Boolean(getItemCookie("OptanonAlertBoxClosed"));
};
export var retrieveOptanonCookieSettings = function() {
    var ref;
    var optanonCookie = getOptanonConsentCookie();
    if (!optanonCookie || optanonCookie.trim() === "") {
        return null;
    }
    var groupsSegmentMatch = optanonCookie.match(/groups=[^&]*/);
    if (!groupsSegmentMatch) {
        return null;
    }
    var groupsData = (ref = groupsSegmentMatch[0]) === null || ref === void 0 ? void 0 : ref.substring(7);
    if (!groupsData) {
        return null;
    }
    var pairs = groupsData.split(",");
    var groups = pairs.reduce(function(obj, pair) {
        var ref = _sliced_to_array(pair.split(":"), 2), key = ref[0], value = ref[1];
        if (!key || value === undefined) {
            return obj;
        }
        return _object_spread_props(_object_spread({}, obj), _define_property({}, key.trim(), value.trim() === "1"));
    }, {});
    return groups;
};
export var isAcceptedAllCookies = function() {
    var groups = retrieveOptanonCookieSettings();
    return Object.values(groups).every(function(value) {
        return value === true;
    });
};
var getOptanonConsentCookie = function() {
    var regex = /OptanonConsent=([^;]+)(;|$)/;
    var match = document.cookie.match(regex);
    return match ? decodeURIComponent(match[1]) : null;
};
export var isAcceptedCookieLawGroup = function(group) {
    var isAlertClosed = isOptanonCookieAlertClosed();
    var groups = retrieveOptanonCookieSettings();
    if (groups === null) return false;
    return isAlertClosed && groups[group];
};
