import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useCheckChannelMutation, useCheckOperatorMutation, useStoreCmbMutation } from "@vfit/shared/data-access";
import { useEffect, useState } from "react";
export var erroreMessages = {
    "002": {
        title: "Errore sul canale",
        description: "Ci dispiace! Stiamo riscontrando problemi sul canale. La invitiamo a riprovare pi\xf9 tardi."
    },
    "003": {
        title: "Errore sul canale",
        description: "Ci dispiace! Il canale risulta attualmente chiuso. La invitiamo a riprovare pi\xf9 tardi."
    },
    "004": {
        title: "Errore sul canale",
        description: "Ci dispiace! Il canale risulta essere in manutenzione. La invitiamo a riprovare pi\xf9 tardi."
    },
    "005": {
        title: "Errore sul canale",
        description: "Ci dispiace, nessun operatore \xe8 attualmente disponibile, la invitiamo a scegliere la fascia oraria di contatto di sua preferenza"
    },
    "006": {
        title: "Errore sul canale",
        description: "Ci dispiace, il canale \xe8 in una fascia oraria di chiusura notturna. La invitiamo a riprovare pi\xf9 tardi."
    },
    "007": {
        title: "Errore sul canale",
        description: "Ti confermiamo che abbiamo gi\xe0 registrato la tua richiesta, sarai contattato da un nostro operatore il prima possibile."
    }
};
export var useVolaService = function(skill, desc, telephoneNumber, timeSlot) {
    var ref = useState(false), isSuccess = ref[0], setIsSuccess = ref[1];
    var ref1 = useState(false), isLoading = ref1[0], setIsLoading = ref1[1];
    var ref2 = useState(false), isError = ref2[0], setIsError = ref2[1];
    var ref3 = useState(null), codeResponse = ref3[0], setCodeResponse = ref3[1];
    var ref4 = useState(""), responseTitle = ref4[0], setResponseTitle = ref4[1];
    var ref5 = useState(""), responseMessage = ref5[0], setResponseMessage = ref5[1];
    var ref6 = useState(""), responseTransaction = ref6[0], setResponseTransaction = ref6[1];
    var ref7 = useState(""), volaService = ref7[0], setVolaService = ref7[1];
    var ref8 = useCheckChannelMutation(), checkChannelMutation = ref8.mutate, checkChannelIsLoading = ref8.isLoading, checkChannelIsError = ref8.isError, checkChannelError = ref8.error, checkChannelIsSuccess = ref8.isSuccess, checkChannelData = ref8.data;
    var ref9 = useCheckOperatorMutation(), checkOperatorMutation = ref9.mutate, checkOperatorIsError = ref9.isError, checkOperatorError = ref9.error, checkOperatorIsSuccess = ref9.isSuccess, checkOperatorData = ref9.data;
    var ref10 = useStoreCmbMutation(), storeCmbMutation = ref10.mutate, storeCmbIsError = ref10.isError, storeCmbIsSuccess = ref10.isSuccess, storeCmbData = ref10.data;
    useEffect(function() {
        if (!!skill && !checkChannelIsLoading) {
            setIsLoading(true);
        }
    }, []);
    /**
   * Get now hour
   * Check & Return Boolean Check if is in these time slots
   * @param contactTimes
   * @returns
   */ var isWithinContactTimes = function(contactTimes) {
        var now = new Date().getHours();
        return contactTimes === null || contactTimes === void 0 ? void 0 : contactTimes.some(function(param) {
            var _param = _sliced_to_array(param, 2), start = _param[0], end = _param[1];
            return now >= start && now < end;
        });
    };
    useEffect(function() {
        if (checkChannelIsSuccess && (checkChannelData === null || checkChannelData === void 0 ? void 0 : checkChannelData.contact_times)) {
            // Formattinh the times to be comparible
            var contactTimes = checkChannelData.contact_times.map(function(item) {
                return item.split("-").map(function(time) {
                    return parseFloat(time.replace(".", ":"));
                });
            });
            if (isWithinContactTimes(contactTimes)) {
                setIsLoading(false);
                setVolaService("cmn");
            } else {
                setIsLoading(false);
                setVolaService("cmb");
            }
        }
    }, [
        checkChannelIsSuccess
    ]);
    useEffect(function() {
        if (volaService === "cmn" && !!telephoneNumber && !checkOperatorIsSuccess) {
            checkOperatorMutation({
                id_channel: skill,
                telephone_number: telephoneNumber,
                desc: desc
            });
            setIsLoading(true);
        }
    }, [
        volaService,
        telephoneNumber
    ]);
    useEffect(function() {
        if (checkOperatorIsSuccess && (checkOperatorData === null || checkOperatorData === void 0 ? void 0 : checkOperatorData.code_response) === "000") {
            setVolaService("cmn");
            setIsSuccess(true);
            setIsLoading(false);
            setCodeResponse(checkOperatorData === null || checkOperatorData === void 0 ? void 0 : checkOperatorData.code_response);
            setResponseMessage(checkOperatorData === null || checkOperatorData === void 0 ? void 0 : checkOperatorData.description);
            setResponseTransaction(checkOperatorData === null || checkOperatorData === void 0 ? void 0 : checkOperatorData.id_request);
        }
        if (checkOperatorIsSuccess && (checkOperatorData === null || checkOperatorData === void 0 ? void 0 : checkOperatorData.code_response) === "001") {
            setVolaService("cmb");
            setIsLoading(false);
            setCodeResponse(checkOperatorData === null || checkOperatorData === void 0 ? void 0 : checkOperatorData.code_response);
            setResponseMessage(checkOperatorData === null || checkOperatorData === void 0 ? void 0 : checkOperatorData.description);
            setResponseTransaction(checkOperatorData === null || checkOperatorData === void 0 ? void 0 : checkOperatorData.id_request);
        }
    }, [
        checkOperatorIsSuccess,
        checkOperatorData
    ]);
    useEffect(function() {
        if (telephoneNumber && timeSlot && volaService === "cmb" && checkOperatorIsSuccess && (checkOperatorData === null || checkOperatorData === void 0 ? void 0 : checkOperatorData.code_response) === "001") {
            storeCmbMutation({
                id_channel: skill,
                desc: desc,
                telephone_number: telephoneNumber,
                contact_time: timeSlot
            });
            setIsLoading(true);
        }
    }, [
        volaService,
        timeSlot
    ]);
    useEffect(function() {
        if (storeCmbIsSuccess && (storeCmbData === null || storeCmbData === void 0 ? void 0 : storeCmbData.code_response) === "000") {
            setIsSuccess(true);
            setIsLoading(false);
            setCodeResponse(storeCmbData === null || storeCmbData === void 0 ? void 0 : storeCmbData.code_response);
            setResponseMessage(storeCmbData === null || storeCmbData === void 0 ? void 0 : storeCmbData.description);
            setResponseTransaction(storeCmbData === null || storeCmbData === void 0 ? void 0 : storeCmbData.id_request);
        }
        if (storeCmbIsSuccess && (storeCmbData === null || storeCmbData === void 0 ? void 0 : storeCmbData.code_response) === "001") {
            setIsSuccess(false);
            setIsError(true);
            setIsLoading(false);
            setCodeResponse(storeCmbData === null || storeCmbData === void 0 ? void 0 : storeCmbData.code_response);
            setResponseMessage(storeCmbData === null || storeCmbData === void 0 ? void 0 : storeCmbData.description);
            setResponseTransaction(storeCmbData === null || storeCmbData === void 0 ? void 0 : storeCmbData.id_request);
        }
    }, [
        checkChannelIsSuccess,
        storeCmbIsSuccess,
        storeCmbData
    ]);
    // Error Handling
    useEffect(function() {
        if ((checkChannelIsError || checkOperatorIsError) && (!!checkChannelError || checkOperatorError)) {
            var ref, ref1;
            setIsSuccess(false);
            setIsLoading(false);
            setIsError(true);
            var codeRes = (ref = checkChannelError || checkOperatorError) === null || ref === void 0 ? void 0 : (ref1 = ref.body) === null || ref1 === void 0 ? void 0 : ref1.code_response;
            var errorMessToDisplay = erroreMessages[(codeRes === null || codeRes === void 0 ? void 0 : codeRes.toString()) || ""];
            setCodeResponse(codeRes);
            setResponseTitle(errorMessToDisplay === null || errorMessToDisplay === void 0 ? void 0 : errorMessToDisplay.title);
            setResponseMessage(errorMessToDisplay === null || errorMessToDisplay === void 0 ? void 0 : errorMessToDisplay.description);
        }
    }, [
        checkChannelIsError,
        checkOperatorIsError,
        storeCmbIsError,
        checkChannelError,
        checkOperatorError, 
    ]);
    return {
        codeResponse: codeResponse,
        responseTitle: responseTitle,
        responseMessage: responseMessage,
        responseTransaction: responseTransaction,
        checkChannelMutation: checkChannelMutation,
        checkChannelIsSuccess: checkChannelIsSuccess,
        checkChannelData: checkChannelData,
        checkOperatorIsSuccess: checkOperatorIsSuccess,
        storeCmbMutation: storeCmbMutation,
        isLoading: isLoading,
        isError: isError,
        isSuccess: isSuccess,
        volaService: volaService
    };
};
