import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: -1;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  width: 327px;\n  height: auto;\n  border-radius: 20px;\n  border: 1px solid rgba(190, 190, 190, 1);\n  background-color: #ffffff;\n  padding: 24px 24px 24px 24px;\n  gap: 24px;\n  p{\n    margin:0;\n  }\n  @media(min-width: ",
        "){\n    margin-top: 180px;\n    padding: 24px;\n  }\n  @media(min-width: ",
        "){\n    min-width: 405px;\n    border-radius: 30px;\n    margin: 0;\n    padding: 32px 36px 32px 36px;\n    gap: 32px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: auto;\n  gap: 8px;\n  @media(max-width: ",
        "){\n    margin-bottom: 120px;\n  }\n  @media(min-width: ",
        "){\n    padding: 0;\n    width: 328px;\n  }\n  @media(min-width: ",
        "){\n    width: 514px;\n  }\n\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n  font-family: ",
        ";\n\n  @media(min-width: ",
        "){\n    ",
        ";\n\n  }\n\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-weight: 400;\n  ",
        ";\n  font-family: ",
        ";\n  p{\n    margin: 0;\n  }\n  @media(min-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  padding: 0;\n  margin: 0;\n  gap: 12px;\n  list-style: none;\n  li{\n    display:flex;\n    gap: 12px;\n    font-family: ",
        ";\n    font-weight: 700;\n    ",
        ";\n    @media(min-width: ",
        ") {\n    ",
        ";\n  }\n\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  width: 100vw;\n  height: ",
        ";\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  background:  linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85));\n  border-top: 0.5px solid #BEBEBE;\n  border-bottom: 0.5px solid #BEBEBE;\n  backdrop-filter: blur(5px);\n  -webkit-backdrop-filter: blur(5px);\n  position:fixed;\n  right:0;\n  bottom: 0;\n  z-index:3\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-size: 24px;\n  line-height: 32px;\n  letter-spacing: 0;\n  text-align: left;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  width: ",
        ";\n  height: ",
        ";\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, pxToCssFont, pxToRem, fonts } from "@vfit/shared/themes";
export var MapContainer = styled.div.withConfig({
    componentId: "sc-756e18e-0"
})(_templateObject());
export var CardPriceLayout = styled.div.withConfig({
    componentId: "sc-756e18e-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.desktop);
export var CardAddressLayout = styled.div.withConfig({
    componentId: "sc-756e18e-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.tablet, breakpoints.desktop);
export var Title = styled.div.withConfig({
    componentId: "sc-756e18e-3"
})(_templateObject3(), pxToCssFont(32, 40), fonts.exbold, breakpoints.desktop, pxToCssFont(44, 52));
export var Description = styled.div.withConfig({
    componentId: "sc-756e18e-4"
})(_templateObject4(), pxToCssFont(20, 28), fonts.regular, breakpoints.desktop, pxToCssFont(22, 32));
export var Specs = styled.ul.withConfig({
    componentId: "sc-756e18e-5"
})(_templateObject5(), fonts.regular, pxToCssFont(20, 28), breakpoints.tablet, pxToCssFont(20, 30));
export var StickyButton = styled.div.withConfig({
    componentId: "sc-756e18e-6"
})(_templateObject6(), pxToRem(77));
export var FooterPrice = styled.div.withConfig({
    componentId: "sc-756e18e-7"
})(_templateObject7(), fonts.exbold);
export var CheckMarkContainer = styled.div.withConfig({
    componentId: "sc-756e18e-8"
})(_templateObject8(), pxToRem(18), pxToRem(26));
