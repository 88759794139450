import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
import { getTypography } from "../../../utils";
export var type = css(_templateObject(), function(param) {
    var t = param.t;
    return t ? getTypography(t) : "";
});
