import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { handleEnv } from "@vfit/shared/providers";
import { credentials, headersNext, mode, isRequiredCustomHeaders, mockApiKey, isEnabledMockApiKey } from "./config";
var MOCK_DXL_TOKEN = "eyJhbGciOiJSUzI1NiJ9.eyJzZXNzaW9uX2lkIjoiWkFzRmU3d1o3U2hQM2NhaVMyMzluemtBdHZHN3o5WjEiLCJzdWIiOiJ2b2RhZm9uZS1keGwiLCJpc3MiOiJqcy1sb2dpbiIsImF1ZCI6ImF1ZGllbmNlX3ZhbHVlIiwiZXhwIjoxNjc4NDQ3NDk5fQ.H3aD5b8E8sjK8ZZ4AwZJU0zjbHzRLhtwozy4XkmmeUSutIfr7Q5ulu23YKlyBk_hia41r7MTL13WfiMYBOBxYuGGW7OEFzG2xvUmbKD9nyf6phjD-aXSpdxMdcw2quX8PJhtnjOM9UUKL3Ghnv-QKPZpEUNVx_I8ck5_092_K2_u0FXhcL6YgTEQJlDIoQsPQ6uCMowgnMBlZ0ZBNJizIjmx6B8jG0RxStRyEvuNWAR3rc3wfGnUcnBVzLRfZY9GPWuwtnjnBACtkGFwQKETelnKRfjf_bp_oMyughznrqjpDbzs3yM0tHQAMz3ElWZGMOHRft4wRHDJjy23tNIBDA";
var getHeadersNext = function() {
    var headers = isRequiredCustomHeaders ? headersNext : {};
    if (isEnabledMockApiKey && mockApiKey && mockApiKey !== "") {
        headers = _object_spread_props(_object_spread({}, headers), {
            "x-api-key": mockApiKey
        });
    }
    return headers;
};
/**
 * Method to return the headers and the credential mode
 * if is NOT in development append headerNext
 */ var checkCustomHeaderNext = function() {
    if (!isRequiredCustomHeaders || isRequiredCustomHeaders && isEnabledMockApiKey && mockApiKey && mockApiKey !== "") {
        return {
            headers: getHeadersNext()
        };
    }
    return {
        headers: getHeadersNext(),
        mode: mode,
        credentials: credentials
    };
};
var getHeadersDxlGatewayCloud = function() {
    var headers = {};
    if (isEnabledMockApiKey && mockApiKey && mockApiKey !== "") {
        headers = _object_spread_props(_object_spread({}, headers), {
            "x-api-key": mockApiKey
        });
    }
    return headers;
};
var getSilentLoginHeadersNext = function(silentLoginHeaders) {
    if (isEnabledMockApiKey && mockApiKey && mockApiKey !== "") {
        return {};
    }
    return silentLoginHeaders;
};
var deleteMockCustomHeader = function(headers) {
    if (isEnabledMockApiKey && mockApiKey && mockApiKey !== "") {
        headers === null || headers === void 0 ? void 0 : headers.forEach(function(value, name) {
            if (name !== "x-api-key") headers.delete(name);
        });
    }
};
/**
 * NOT DELETE
 * IS Temporary
 */ var getFwvAPI = function() {
    var ref;
    var env = process === null || process === void 0 ? void 0 : (ref = process.env) === null || ref === void 0 ? void 0 : ref["NX_FWVAPI"];
    var isDevLocal = handleEnv("NODE_ENV") === "development";
    if (isDevLocal) return "http://localhost:6767/api";
    return atob(env) || "";
};
export { MOCK_DXL_TOKEN, deleteMockCustomHeader, checkCustomHeaderNext, getHeadersNext, getSilentLoginHeadersNext, getHeadersDxlGatewayCloud, getFwvAPI };
