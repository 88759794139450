import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  border: 1px solid #d8d8d8;\n  margin: 0 auto;\n  border-radius: 5px;\n  height: 162px;\n  padding: 16px;\n  padding-bottom: 20px;\n  color: ",
        ";\n  text-align: left;\n\n  @media (min-width: ",
        ") {\n    padding: 24px;\n    padding-top: 20px;\n    height: 192px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  margin-bottom: 16px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 24px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  margin-bottom: 16px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  p {\n    margin: 0 auto;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n\n  svg {\n    margin-right: 8px;\n    height: 16px;\n    width: 24px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-ef2f79c3-0"
})(_templateObject(), colors.$262626, breakpoints.tablet);
export var Title = styled.div.withConfig({
    componentId: "sc-ef2f79c3-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(24, 30));
export var Info = styled.div.withConfig({
    componentId: "sc-ef2f79c3-2"
})(_templateObject2(), fonts.regular, pxToCssFont(18, 24), breakpoints.tablet, pxToCssFont(20, 30));
export var Expiration = styled.div.withConfig({
    componentId: "sc-ef2f79c3-3"
})(_templateObject3(), fonts.regular, pxToCssFont(14, 18));
