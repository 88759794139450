import _instanceof from "@swc/helpers/src/_instanceof.mjs";
export var isValidDate = function(dateTime) {
    return _instanceof(dateTime, Date) || typeof dateTime === "number" ? true : !Number.isNaN(Date.parse(dateTime));
};
export var getDay = function(dateTime) {
    if (!isValidDate(dateTime)) {
        return "";
    }
    var d = new Intl.DateTimeFormat("it-IT", {
        day: "numeric",
        month: "long",
        timeZone: "Europe/Rome",
        weekday: "long"
    }).format(new Date(dateTime));
    return "".concat(d.charAt(0).toUpperCase()).concat(d.slice(1));
};
export var getDayAndMonth = function(dateTime) {
    if (!isValidDate(dateTime)) {
        return "";
    }
    var d = new Intl.DateTimeFormat("it-IT", {
        day: "numeric",
        month: "long",
        timeZone: "Europe/Rome"
    }).format(new Date(dateTime));
    return d;
};
// The main difference between the new implementation and the isValidDate is that the new way
// is using the Date object and its getTime() method to check if the date is valid or not
// while the isValidDate function is using the Date.parse() method which is not as strict as the
// Date object's getTime() method. The new implementation is more strict and will return an empty
// string if the date is not valid, while the isValidDate function will return true even if the
// date is not valid.
export var getCompleteDate = function(dateTime) {
    /* if (!(dateTime instanceof Date) || (dateTime instanceof Date && Number.isNaN(dateTime.getTime())))
    return ''; */ if (!isValidDate(dateTime)) {
        return "";
    }
    var d = new Intl.DateTimeFormat("it-IT", {
        day: "2-digit",
        month: "2-digit",
        timeZone: "Europe/Rome",
        year: "numeric"
    }).format(new Date(dateTime));
    return d;
};
export var getWeekday = function(dateTime) {
    if (!isValidDate(dateTime)) {
        return "";
    }
    var d = new Intl.DateTimeFormat("it-IT", {
        timeZone: "Europe/Rome",
        weekday: "long"
    }).format(new Date(dateTime));
    return "".concat(d.charAt(0).toUpperCase()).concat(d.slice(1));
};
export var getTimeRange = function(startDateTime, endDateTime) {
    if (!isValidDate(startDateTime) || !isValidDate(endDateTime)) {
        return "";
    }
    return "".concat(new Intl.DateTimeFormat("it-IT", {
        hour: "numeric",
        minute: "numeric",
        timeZone: "Europe/Rome"
    }).format(new Date(startDateTime)), " - ").concat(new Intl.DateTimeFormat("it-IT", {
        hour: "numeric",
        minute: "numeric",
        timeZone: "Europe/Rome"
    }).format(new Date(endDateTime))).replace(/[:]/g, ".");
};
