import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  button {\n    background: transparent;\n    border: none;\n    font-family: ",
        ";\n    font-weight: 700;\n    text-decoration: underline;\n    ",
        "\n    margin-top: 8px;\n\n    @media (min-width: ",
        ") {\n      margin-top: 32px;\n    }\n\n    &,\n    &:active,\n    &:visited {\n      color: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  color: ",
        ";\n  ",
        "\n  margin-bottom: 16px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 20px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  color: ",
        ";\n  ",
        "\n  margin-bottom: 24px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  height: 1px;\n  background-color: ",
        ";\n  margin: 36px 0 32px;\n\n  @media (min-width: ",
        ") {\n    margin: 45px 0 40px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-6532cd06-0"
})(_templateObject(), fonts.regular, pxToCssFont(16, 22), breakpoints.tablet, colors.$262626);
export var Title = styled.div.withConfig({
    componentId: "sc-6532cd06-1"
})(_templateObject1(), fonts.exbold, colors.$262626, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(24, 30));
export var Description = styled.div.withConfig({
    componentId: "sc-6532cd06-2"
})(_templateObject2(), fonts.regular, colors.$262626, pxToCssFont(16, 22), breakpoints.tablet, pxToCssFont(18, 24));
export var Divider = styled.div.withConfig({
    componentId: "sc-6532cd06-3"
})(_templateObject3(), colors.$bebebe, breakpoints.desktop);
