import { checkWindow } from "./check-window";
export var checkIsApp = function() {
    var ref;
    if (!checkWindow()) return false;
    var queryString = new URLSearchParams(window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.search);
    return !!queryString.get("app") || !!localStorage.getItem("isApp");
};
export var execute = function(name, params, callback) {
    var ref, ref1;
    var is = function(type, val) {
        return ![
            undefined,
            null
        ].includes(val) && val.constructor === type;
    };
    var serialize = function(p) {
        return Object.keys(p).map(function(key) {
            var item = p[key];
            if (is(Array, item) || is(Object, item)) {
                item = JSON.stringify(item);
            }
            return "".concat(key, "=").concat(encodeURIComponent(item));
        }).join("&");
    };
    (ref = window) === null || ref === void 0 ? void 0 : (ref1 = ref.BWB_JS) === null || ref1 === void 0 ? void 0 : ref1.execute(name, serialize(params), callback);
};
export var openInBrowser = function(url) {
    execute("openInBrowser", {
        url: url
    }, null);
};
export var setBackButtonUrl = function(url) {
    execute("setBackButtonUrl", {
        url: url
    }, null);
};
