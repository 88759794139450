import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin-left: 23px;\n  margin-bottom: 30px;\n  margin-top: 10px;\n  width: 85%;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  padding: 10px 0;\n  margin: 0;\n  background-color: transparent;\n  border: none;\n  color: #0096ae;\n\n  p {\n    padding: 0;\n    margin: 0;\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n  }\n\n  &:active {\n    background-color: #f0f0f0;\n  }\n\n  &:hover {\n    cursor: pointer;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: 16px;\n  height: 16px;\n  margin-left: 4px;\n\n  svg path {\n    stroke: #0096ae;\n    stroke-width: 1px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  height: 66px;\n  border-top: 1px solid ",
        ";\n  border-bottom: 1px solid transparent;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  flex: 1;\n  padding: 0;\n  margin: 0;\n  background-color: transparent;\n  border: none;\n  border-right: 1px solid ",
        ";\n\n  p {\n    margin: 0;\n    margin-left: 8px;\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: #666;\n  }\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  &:active {\n    background-color: #f0f0f0;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  width: 16px;\n  height: 16px;\n\n  svg path {\n    fill: #666;\n  }\n\n  &.align {\n    padding-top: 2px;\n\n    svg path {\n      fill: none;\n      stroke: #666;\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  border: none;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin-right: 11px;\n\n  img {\n    object-fit: contain;\n    max-width: 16px;\n    max-height: 12.7px;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-661ee508-0"
})(_templateObject());
export var CustomerTypeContainer = styled.div.withConfig({
    componentId: "sc-661ee508-1"
})(_templateObject1());
export var ActionsLink = styled.button.withConfig({
    componentId: "sc-661ee508-2"
})(_templateObject2(), fonts.regular, pxToCssFont(14, 18));
export var WrapperSvg = styled.div.withConfig({
    componentId: "sc-661ee508-3"
})(_templateObject3());
export var OtherActionsContainer = styled.div.withConfig({
    componentId: "sc-661ee508-4"
})(_templateObject4(), colors.$f2f2f2);
export var LeftButton = styled.button.withConfig({
    componentId: "sc-661ee508-5"
})(_templateObject5(), colors.$f2f2f2, fonts.regular, pxToCssFont(14, 18));
export var IconButtonsWrapper = styled.div.withConfig({
    componentId: "sc-661ee508-6"
})(_templateObject6());
export var RightButton = styled(LeftButton).withConfig({
    componentId: "sc-661ee508-7"
})(_templateObject7());
export var IconMarkerWrapper = styled.div.withConfig({
    componentId: "sc-661ee508-8"
})(_templateObject8());
