import { checkWindow } from "@vfit/shared/data-access";
import { useMediaQuery } from "./useMediaQuery";
var isTabletDevice = function() {
    var tabletReg = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;
    if (!checkWindow()) return false;
    var usrAgent = navigator.userAgent;
    return tabletReg.test(usrAgent);
};
export function useDeviceType() {
    var isBigDesktop = useMediaQuery("bigDesktop", "min");
    var isDesktop = useMediaQuery("desktop", "min");
    var isTabletVer = useMediaQuery("desktop", "min");
    var isTabletLand = useMediaQuery("desktop", "max");
    var isMobile = useMediaQuery("tablet", "max");
    var isTablet = !isMobile && !isDesktop && !isBigDesktop && (isTabletLand || isTabletVer) || isTabletDevice();
    return {
        isDesktop: isDesktop,
        isBigDesktop: isBigDesktop,
        isTablet: isTablet,
        isMobile: isMobile
    };
}
