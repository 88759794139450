export var TechTypes;
(function(TechTypes) {
    TechTypes["FTTH"] = "FTTH";
    TechTypes["FIBRA_FTTH"] = "FIBRA FTTH";
    TechTypes["MAKE"] = "MAKE";
    TechTypes["VULA"] = "VULA";
    TechTypes["NGA"] = "NGA";
    TechTypes["ULL"] = "ULL";
    TechTypes["ULL_SHDSL"] = "ULL_SHDSL";
    TechTypes["BS"] = "BS";
    TechTypes["BS_SHDSL"] = "BS_SHDSL";
    TechTypes["FWA_INDOOR"] = "FWA_INDOOR";
    TechTypes["FWA_OUTDOOR"] = "FWA_OUTDOOR";
    TechTypes["FWA_OUTDOOR_5G"] = "FWA_OUTDOOR_5G";
    TechTypes["FWA_INDOOR_5G"] = "FWA_INDOOR_5G";
    TechTypes["FWA"] = "FWA";
    TechTypes["T3G4G"] = "3G 4G";
    TechTypes["FTTC"] = "FTTC";
    TechTypes["FTTH_TIM"] = "FTTH_TIM";
    TechTypes["ADSL"] = "ADSL";
})(TechTypes || (TechTypes = {}));
export var TechnologyCategories;
(function(TechnologyCategories) {
    TechnologyCategories["FTTH"] = "FTTH";
    TechnologyCategories["FTTC"] = "FTTC";
    TechnologyCategories["ADSL"] = "ADSL";
    TechnologyCategories["FWAINDOOR"] = "FWAINDOOR";
    TechnologyCategories["FWAOUTDOOR"] = "FWAOUTDOOR";
    TechnologyCategories["FWAOUTDOOR5G"] = "FWAOUTDOOR5G";
    TechnologyCategories["FWAINDOOR5G"] = "FWAINDOOR5G";
    TechnologyCategories["FTTHTIM"] = "FTTHTIM";
})(TechnologyCategories || (TechnologyCategories = {}));
export var TechnologyCategoriesAggregation;
(function(TechnologyCategoriesAggregation) {
    TechnologyCategoriesAggregation["FTTH"] = "FTTH";
    TechnologyCategoriesAggregation["NOT_DISAGGREGATED"] = "NOT_DISAGGREGATED";
    TechnologyCategoriesAggregation["DISAGGREGATED"] = "DISAGGREGATED";
    TechnologyCategoriesAggregation["FWA_INDOOR"] = "FWA_INDOOR";
    TechnologyCategoriesAggregation["FWA_OUTDOOR"] = "FWA_OUTDOOR";
    TechnologyCategoriesAggregation["FTTH_TIM"] = "FTTH_TIM";
    TechnologyCategoriesAggregation["FALLBACK"] = "FALLBACK";
})(TechnologyCategoriesAggregation || (TechnologyCategoriesAggregation = {}));
