import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  background: ",
        ";\n  animation: ",
        " 1.5s infinite;\n  min-height: ",
        ";\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { animations, pxToRem, gradients } from "@vfit/shared/themes";
export var Shadow = styled.div.withConfig({
    componentId: "sc-d748dbec-0"
})(_templateObject(), gradients.skeleton_shadow, animations.shadowLoad, function(param) {
    var height = param.height;
    return height ? pxToRem(height) : pxToRem(45);
}, function(param) {
    var width = param.width;
    return width ? "width : ".concat(width, ";") : "";
});
