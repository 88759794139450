import { useState } from "react";
export var useSwipeElement = function() {
    var minSwipeDistance = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 50;
    var ref = useState(null), touchStart = ref[0], setTouchStart = ref[1];
    var ref1 = useState(null), touchEnd = ref1[0], setTouchEnd = ref1[1];
    var ref2 = useState("none"), swipeStatus = ref2[0], setSwipeStatus = ref2[1];
    var handleSwipeStatus = function(distance) {
        if (distance > minSwipeDistance) setSwipeStatus("up");
        if (distance < -minSwipeDistance) setSwipeStatus("down");
        setTimeout(function() {
            return setSwipeStatus("none");
        }, 100);
    };
    var onTouchStart = function(e) {
        setTouchEnd(null); // otherwise, the swipe is triggered even with regular touch events
        setTouchStart(e.targetTouches[0].clientY);
    };
    var onTouchMove = function(e) {
        return setTouchEnd(e.targetTouches[0].clientY);
    };
    var onTouchEnd = function() {
        if (!touchStart || !touchEnd) return;
        handleSwipeStatus(touchStart - touchEnd);
    };
    return {
        onTouchStart: onTouchStart,
        onTouchMove: onTouchMove,
        onTouchEnd: onTouchEnd,
        swipeStatus: swipeStatus
    };
};
