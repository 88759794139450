import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  font-style: normal;\n  color: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  margin-bottom: 16px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-top: -12px;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-top: 0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  margin-bottom: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-bottom: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n\n  div {\n    margin-top: ",
        ";\n    width: 100%;\n\n    @media (min-width: ",
        ") {\n      margin-top: ",
        ";\n      width: ",
        ";\n    }\n\n    @media (min-width: ",
        ") {\n      width: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-249618a-0"
})(_templateObject(), colors.$262626);
export var Title = styled.div.withConfig({
    componentId: "sc-249618a-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Description = styled.div.withConfig({
    componentId: "sc-249618a-2"
})(_templateObject2(), fonts.regular, pxToCssFont(18, 24), pxToRem(40), breakpoints.tablet, pxToRem(26.77), breakpoints.desktop, pxToRem(40));
export var ButtonDiv = styled.div.withConfig({
    componentId: "sc-249618a-3"
})(_templateObject3(), pxToRem(37), breakpoints.tablet, pxToRem(52), pxToRem(258), breakpoints.desktop, pxToRem(296));
