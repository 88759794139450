import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
var DEFAULT_SIZE = {
    default: {
        width: 438
    },
    mobile: {
        width: 332
    },
    tablet: {
        width: 353
    }
};
/**
 * Return the size of slide
 * @param slideIndex
 * @param isMobile
 * @param isTablet
 */ var getSlideSize = function(slideIndex, isMobile, isTablet) {
    if (isTablet && DEFAULT_SIZE.tablet) return DEFAULT_SIZE.tablet;
    if (isMobile) return DEFAULT_SIZE.mobile;
    return DEFAULT_SIZE.default;
};
var updateAllHeight = function(ref) {
    var _Math;
    var pagination = ref.getElementsByClassName("swiper-pagination");
    pagination[0].style.marginBottom = "42px";
    var allSlides = ref.getElementsByClassName("swiper-slide");
    var allHeight = [];
    // eslint-disable-next-line no-plusplus
    for(var i = 0; i < allSlides.length; i++){
        var bottomCard = allSlides[i].getElementsByClassName("bottom-container");
        if (bottomCard === null || bottomCard === void 0 ? void 0 : bottomCard[0]) {
            allHeight.push(bottomCard[0].clientHeight);
        }
    }
    var maxH = (_Math = Math).max.apply(_Math, _to_consumable_array(allHeight));
    if (maxH) {
        for(var i1 = 0; i1 < allSlides.length; i1 += 1){
            var bottomCard1 = allSlides[i1].getElementsByClassName("bottom-container");
            if (bottomCard1 === null || bottomCard1 === void 0 ? void 0 : bottomCard1[0]) {
                bottomCard1[0].style.height = "".concat(maxH, "px");
            }
        }
    }
};
export { getSlideSize, updateAllHeight, DEFAULT_SIZE };
