import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  animation: fadein 2s;\n  color: ",
        ";\n  ",
        "\n  font-family: ",
        ";\n  text-align: left;\n\n  @keyframes fadein {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  margin: 0;\n  margin-top: 9.008px;\n  padding: 18px 16px 18px 20px;\n  display: flex;\n  transition: border 2s;\n  border-radius: 5.008px;\n  background: transparent;\n  ",
        ";\n\n  .wrapper {\n    display: inline-flex;\n    width: 100%;\n    margin: 0;\n    padding: 0;\n  }\n\n  .wrapper-icon {\n    display: flex;\n\n    @media (min-width: ",
        ") {\n      align-self: center;\n    }\n\n    @media (min-width: ",
        ") {\n      align-self: center;\n    }\n  }\n\n  .icon {\n    margin: auto;\n    display: flex;\n    flex-direction: column;\n    appearance: none;\n    background: none;\n    border: none;\n    font-family: inherit;\n    font-size: inherit;\n    padding: 0;\n\n    &:hover {\n      cursor: ",
        ";\n    }\n  }\n\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  resize: none;\n  height: 25.008px;\n  margin: auto;\n  width: 100%;\n  outline: 0;\n  border: 0;\n  text-overflow: ellipsis;\n  ",
        "\n  text-align: left;\n\n  &:disabled {\n    background-color: transparent;\n    cursor: not-allowed;\n  }\n\n  &:focus {\n    outline: none;\n  }\n\n  &::placeholder {\n    color: ",
        ";\n  }\n\n  &:-webkit-autofill,\n  &:-webkit-autofill:hover,\n  &:-webkit-autofill:focus,\n  &:-webkit-autofill:active {\n    box-shadow: 0 0 0 30px white inset !important;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: auto;\n    height: 28px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n  height: 18px;\n  padding: 0 8px;\n  margin-left: -5.008px;\n  margin-top: 3.008px;\n  transition: 0.2s;\n  background: white;\n  ",
        "\n  font-family: ",
        ";\n  text-align: left;\n  position: absolute;\n\n  ",
        ":not(:placeholder-shown) ~ &,\n  ",
        ":focus ~ & {\n    transition: 0.2s;\n    top: -13.008px;\n    ",
        "\n    z-index: 2;\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var ErrorMessage = styled.p.withConfig({
    componentId: "sc-2d482c3c-0"
})(_templateObject(), colors.$e60000, pxToCssFont(14, 0), fonts.regular);
export var Fieldset = styled.div.withConfig({
    componentId: "sc-2d482c3c-1"
})(_templateObject1(), function(props) {
    if (props.error) {
        return "border: 1.008px solid ".concat(colors.$e60000);
    }
    return props.isDisabled ? "border: 1.008px solid #BEBEBE" : "border: 1.008px solid #BEBEBE";
}, breakpoints.mobile, breakpoints.tablet, function(props) {
    return props.isDisabled ? "not-allowed" : "pointer";
}, function(props) {
    return props.isDisabled ? "&:hover {cursor: not-allowed}" : "";
});
// div for inputs
export var Field = styled.textarea.withConfig({
    componentId: "sc-2d482c3c-2"
})(_templateObject2(), pxToCssFont(18, 24), colors.$262626, breakpoints.tablet);
export var Legend = styled.label.withConfig({
    componentId: "sc-2d482c3c-3"
})(_templateObject3(), function(props) {
    return props.error ? "color: ".concat(colors.$e60000, ";") : "color: ".concat(colors.$7e7e7e);
}, pxToCssFont(18, 24), fonts.regular, Field, Field, pxToCssFont(14, 18), colors.$262626);
