import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\nto {\n  background-position: 315px 0, 0 0, 0 190px, 50px 195px;\n}\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { keyframes } from "styled-components";
export var shadowLoad = keyframes(_templateObject());
