import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    font-family: ",
        ";\n    font-weight: 300;\n  "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    font-family: ",
        ";\n    font-weight: 400;\n  "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n    font-family: ",
        ";\n    font-weight: 700;\n  "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n    font-family: ",
        ";\n    font-weight: 800;\n  "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import { css } from "styled-components";
import { fonts } from "../../fonts";
export var cssfonts = {
    light: css(_templateObject(), fonts.vodafone),
    regular: css(_templateObject1(), fonts.vodafone),
    bold: css(_templateObject2(), fonts.vodafone),
    exbold: css(_templateObject3(), fonts.vodafone)
};
