import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  from {\n    opacity: 1;\n    transform: scale3d(1, 1, 1);\n    transform-origin: top center;\n  }\n  \n  to {\n    opacity: 0;\n    transform: scale3d(1, 0, 1);\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { keyframes } from "styled-components";
export var stretchOutUp = keyframes(_templateObject());
