import { useEffect, useLayoutEffect, useState } from "react";
import { useSwipeElement } from "@vfit/shared/hooks";
export var useAppDrawer = function(param) {
    var onCloseModal = param.onCloseModal;
    var ref = useState(true), show = ref[0], setShow = ref[1];
    var ref1 = useState(false), isBgClickable = ref1[0], setIsBgClickable = ref1[1];
    var ref2 = useState(false), colorTheBg = ref2[0], setColorTheBg = ref2[1];
    var bgProps = {
        animationTime: 700,
        colorTheBg: colorTheBg,
        show: show
    };
    useLayoutEffect(function() {
        setTimeout(function() {
            return setIsBgClickable(true);
        }, 100);
        setTimeout(function() {
            return setColorTheBg(true);
        }, bgProps.animationTime);
        var el = document.querySelector("#sticky-offer-summary");
        if (el) el.style.visibility = "visible";
    }, []);
    var closeModalAfterAnimation = function() {
        setTimeout(function() {
            return onCloseModal === null || onCloseModal === void 0 ? void 0 : onCloseModal();
        }, bgProps.animationTime - 10);
        setColorTheBg(false);
        setShow(false);
    };
    var close = function() {
        return isBgClickable ? closeModalAfterAnimation() : null;
    };
    var ref3 = useSwipeElement(), onTouchStart = ref3.onTouchStart, onTouchMove = ref3.onTouchMove, onTouchEnd = ref3.onTouchEnd, swipeStatus = ref3.swipeStatus;
    useEffect(function() {
        if (swipeStatus === "down") closeModalAfterAnimation();
    }, [
        swipeStatus
    ]);
    return {
        bgProps: bgProps,
        close: close,
        onTouchStart: onTouchStart,
        onTouchMove: onTouchMove,
        onTouchEnd: onTouchEnd
    };
};
