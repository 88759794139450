import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 32px 20px 36px;\n\n  @media (min-width: ",
        ") {\n    padding: 60px 40px 31px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 60px 55px 0;\n  }\n\n  height: 100%;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-weight: 400;\n  color: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  text-align: center;\n  margin-bottom: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  text-align: center;\n  margin-bottom: 23px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 40px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  font-family: ",
        ";\n  background-color: transparent;\n  padding: 0;\n  border: none;\n  border-bottom: 1px solid ",
        ";\n  color: ",
        ";\n  font-weight: 700;\n  ",
        "\n\n  &:hover {\n    cursor: pointer;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  margin-bottom: 26px;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 32px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-3244ea98-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var Paragraph = styled.p.withConfig({
    componentId: "sc-3244ea98-1"
})(_templateObject1(), colors.$262626);
export var Title = styled(Paragraph).withConfig({
    componentId: "sc-3244ea98-2"
})(_templateObject2(), fonts.exbold, pxToCssFont(30, 38), pxToRem(16), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Description = styled(Paragraph).withConfig({
    componentId: "sc-3244ea98-3"
})(_templateObject3(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(20, 30), breakpoints.desktop);
export var Cta = styled.button.withConfig({
    componentId: "sc-3244ea98-4"
})(_templateObject4(), fonts.regular, colors.$262626, colors.$262626, pxToCssFont(16, 22));
export var DropDownContainer = styled.div.withConfig({
    componentId: "sc-3244ea98-5"
})(_templateObject5(), breakpoints.tablet);
