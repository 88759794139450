import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  padding: ",
        ";\n  background: ",
        ";\n  border-top: 1px solid;\n  border-color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: ",
        ";\n  font-size: ",
        ";\n  margin: 0;\n  cursor: pointer;\n  color: ",
        ";\n  line-height: ",
        ";\n\n  @media (min-width: ",
        ") {\n    font-weight: ",
        ";\n    font-style: normal;\n    font-size: ",
        ";\n    line-height: ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  ",
        "\n  font-family: ",
        ";\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  cursor: pointer;\n  transform: rotate(0deg);\n  transition: transform 0.5s;\n\n  &.showing {\n    transition: transform 0.5s;\n    transform: rotate(-180deg);\n  }\n\n  svg {\n    width: ",
        "px;\n    height: auto;\n\n    path {\n      stroke: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 8px;\n  font-weight: 700;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-weight: 700;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToFontSize, pxToLineHeight } from "@vfit/shared/themes";
export var StyledAccordion = styled.div.withConfig({
    componentId: "sc-332a861c-0"
})(_templateObject(), function(param) {
    var padding = param.padding;
    return padding || "1rem 0rem";
}, function(param) {
    var background = param.background;
    return background || colors.$ffffff;
}, function(param) {
    var borderColor = param.borderColor;
    return borderColor || "#ebebeb";
}, breakpoints.desktop, function(param) {
    var padding = param.padding, isMenu = param.isMenu;
    return isMenu && padding ? padding : "1.844rem 0";
});
export var Title = styled.div.withConfig({
    componentId: "sc-332a861c-1"
})(_templateObject1());
export var TitleText = styled.h5.withConfig({
    componentId: "sc-332a861c-2"
})(_templateObject2(), function(param) {
    var fontFamily = param.fontFamily;
    return fontFamily || fonts.regular;
}, function(param) {
    var fontWeight = param.fontWeight;
    return fontWeight || 400;
}, function(param) {
    var fontSize = param.fontSize;
    return fontSize || pxToFontSize(18);
}, function(param) {
    var color = param.color;
    return color || colors.$262626;
}, function(param) {
    var lineHeight = param.lineHeight;
    return lineHeight || pxToLineHeight(18, 28);
}, breakpoints.desktop, function(param) {
    var fontWeight = param.fontWeight;
    return fontWeight || 400;
}, function(param) {
    var isMenu = param.isMenu, fontSize = param.fontSize;
    return isMenu && fontSize ? fontSize : "24px";
}, function(param) {
    var lineHeight = param.lineHeight, isMenu = param.isMenu;
    return isMenu && lineHeight ? lineHeight : "30px";
});
export var Content = styled.div.withConfig({
    componentId: "sc-332a861c-3"
})(_templateObject3(), pxToCssFont(16, 22), fonts.regular);
export var IconSpan = styled.span.withConfig({
    componentId: "sc-332a861c-4"
})(_templateObject4(), function(param) {
    var size = param.size;
    return size || 24;
}, function(param) {
    var color = param.color;
    return color || "#333";
});
export var SubtitleText = styled.p.withConfig({
    componentId: "sc-332a861c-5"
})(_templateObject5());
export var ContentText = styled.p.withConfig({
    componentId: "sc-332a861c-6"
})(_templateObject6());
