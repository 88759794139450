import { useId } from "react";
export var useInputs = function(param) {
    var _disabled = param._disabled, _error = param._error, loading = param.loading, notDisableIfLoading = param.notDisableIfLoading;
    var id = useId();
    var disabled = !notDisableIfLoading && loading || !!_disabled;
    var error = _error && !disabled ? _error : "";
    return {
        disabled: disabled,
        error: error,
        id: id
    };
};
