import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  list-style: none;\n  padding: 8px 20px;\n  color: ",
        ";\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  text-align: left;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n\n  &:hover {\n    cursor: pointer;\n    background-color: #ccc;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { colors, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var SuggestionItem = styled.li.withConfig({
    componentId: "sc-34de744a-0"
})(_templateObject(), colors.$7e7e7e, fonts.regular, pxToCssFont(18, 24));
