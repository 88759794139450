import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 16px 0;\n  padding-left: 16px;\n  padding-right: 19px;\n  height: 63px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  @media (min-width: ",
        ") {\n    height: 56px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: 30px;\n\n  @media (min-width: ",
        ") {\n    width: 32px;\n    height: 32px;\n  }\n\n  a {\n    display: flex;\n    height: 100%;\n    width: 100%;\n  }\n\n  svg {\n    width: 32px;\n    height: auto;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: transparent;\n  padding: 0;\n  border: 0;\n  width: 24px;\n  height: 24px;\n\n  @media (min-width: ",
        ") {\n    width: 32px;\n    height: 32px;\n  }\n\n  svg {\n    width: 24px;\n    height: auto;\n\n    @media (min-width: ",
        ") {\n      width: 19px;\n    }\n\n    @media (min-width: ",
        ") {\n      width: 25px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  list-style: none;\n  width: 24px;\n  height: 24px;\n\n  @media (min-width: ",
        ") {\n    width: 20px;\n    height: 23px;\n  }\n\n  a {\n    justify-content: center;\n  }\n\n  a svg {\n    width: 15px;\n\n    @media (min-width: ",
        ") {\n      width: 100%;\n    }\n  }\n\n  a:link {\n    display: flex;\n    height: 100%;\n  }\n\n  a:visited {\n    fill: ",
        ";\n  }\n\n  a:hover svg path.stroke {\n    stroke: ",
        ";\n  }\n\n  a:hover svg path.fill,\n  a:active {\n    fill: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  padding: 0;\n  margin: 0;\n  gap: 16px;\n\n  @media (min-width: ",
        ") {\n    gap: 25px;\n  }\n\n  .searchbox-icon {\n    svg {\n      width: 19px;\n\n      @media (min-width: ",
        ") {\n        width: 25px;\n      }\n\n      @media (min-width: ",
        ") {\n        width: 100%;\n      }\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors } from "@vfit/shared/themes";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-86a93c6b-0"
})(_templateObject(), breakpoints.tablet);
export var LogoWrapper = styled.div.withConfig({
    componentId: "sc-86a93c6b-1"
})(_templateObject1(), breakpoints.tablet);
export var IconButton = styled.button.withConfig({
    componentId: "sc-86a93c6b-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.mobile, breakpoints.tablet);
export var ActionsMenuList = styled.li.withConfig({
    componentId: "sc-86a93c6b-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.tablet, colors.$0d0d0d, colors.$e60000, colors.$e60000);
export var ActionsMenu = styled.ul.withConfig({
    componentId: "sc-86a93c6b-4"
})(_templateObject4(), breakpoints.desktop, breakpoints.tablet, breakpoints.desktop);
