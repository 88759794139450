export var ECodeIdentification;
(function(ECodeIdentification) {
    ECodeIdentification["NA"] = "NA";
    ECodeIdentification["CO"] = "CO";
    ECodeIdentification["VR"] = "VR";
    ECodeIdentification["DI"] = "DI";
})(ECodeIdentification || (ECodeIdentification = {}));
export var IAddressType;
(function(IAddressType) {
    IAddressType["S"] = "S";
    IAddressType["PO"] = "PO";
    IAddressType["PP"] = "PP";
    IAddressType["P"] = "PP";
})(IAddressType || (IAddressType = {}));
