import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: sticky;\n  bottom: 0;\n  z-index: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  bottom: 23px;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  pointer-events: none;\n\n  @media (min-width: ",
        ") {\n    bottom: 52px;\n  }\n\n  @media (min-width: ",
        ") {\n    bottom: 64px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  justify-content: center;\n  display: flex;\n  pointer-events: all;\n  transform: scale(0.7);\n\n  @media (min-width: ",
        ") {\n    transform: scale(1);\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  bottom: 35px;\n  right: 21px;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n\n  @media (min-width: ",
        ") {\n    bottom: 64px;\n    right: 27px;\n  }\n\n  @media (min-width: ",
        ") {\n    right: 35px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var ScrollButtonAndStickWrapper = styled.div.withConfig({
    componentId: "sc-5a1d5f0c-0"
})(_templateObject(), function(param) {
    var slidesLenght = param.slidesLenght;
    return slidesLenght;
});
export var ScrollButtonContainer = styled.div.withConfig({
    componentId: "sc-5a1d5f0c-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.desktop);
export var ButtonScroll = styled.div.withConfig({
    componentId: "sc-5a1d5f0c-2"
})(_templateObject2(), breakpoints.desktop);
export var ScrollStickContainer = styled.div.withConfig({
    componentId: "sc-5a1d5f0c-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.desktop);
