import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  &.error-enter {\n    animation: ",
        " 0.3s forwards;\n  }\n\n  &.error-exit {\n    animation: ",
        " 0.3s forwards;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      height: ",
        ";\n    "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      height: ",
        ";\n    "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      height: ",
        ";\n    "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  align-items: center;\n  bottom: 0;\n  color: ",
        ";\n  column-gap: ",
        ";\n  display: flex;\n  left: 0;\n  margin: 0;\n  position: absolute;\n  ",
        "\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { animations, colors, getTypography, pxToRem } from "@vfit/shared/themes";
var fadeAnimation = css(_templateObject(), animations.fadeIn, animations.fadeOut);
var variants = {
    errorMessage: {
        animated: css(_templateObject1(), getTypography("footnote2.regular"), pxToRem(24)),
        "mva:animated": css(_templateObject2(), getTypography("footnote1.regular"), pxToRem(18)),
        "mva:static": css(_templateObject3(), getTypography("footnote1.regular"), pxToRem(18))
    }
};
export var ErrorMessage = styled.p.withConfig({
    componentId: "sc-3eadc189-0"
})(_templateObject4(), fadeAnimation, colors.$bd0000, pxToRem(4), function(param) {
    var variant = param.variant;
    return variant ? variants.errorMessage[variant] : "";
});
