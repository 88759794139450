export var numericBreakpoints = {
    mobile: 0,
    tablet: 766.88,
    desktop: 1024,
    bigDesktop: 1312,
    largeDesktop: 1440
};
export var breakpoints = {
    mobile: 0,
    tablet: "47.93rem",
    desktop: "64rem",
    bigDesktop: "82rem",
    largeDesktop: "90rem"
};
