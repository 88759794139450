import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  margin-bottom: 8px;\n\n  svg {\n    margin-right: 16px;\n    width: 19px;\n    object-fit: contain;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n\n  div {\n    margin: 0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var ItemContainer = styled.li.withConfig({
    componentId: "sc-82c9309c-0"
})(_templateObject());
export var ItemTextContainer = styled.div.withConfig({
    componentId: "sc-82c9309c-1"
})(_templateObject1());
