import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useCallback, useMemo, useRef, useState } from "react";
import { createSyntheticEvent } from "@vfit/shared/hooks";
export var useInput = function(param) {
    var _btnProps = param._btnProps, _onClear = param._onClear, _svg = param._svg, clearable = param.clearable, disabled = param.disabled, onBlur = param.onBlur, onChange = param.onChange, onClearCallback = param.onClearCallback, ref = param.ref;
    var ref1 = useState(false), isClearable = ref1[0], setIsClearable = ref1[1];
    var innerRef = useRef(null);
    var svg = isClearable ? "close" : _svg;
    var refCallback = useCallback(function(node) {
        if (node) {
            innerRef.current = node;
            if (ref) {
                if (typeof ref === "function") {
                    ref(node);
                } else {
                    // eslint-disable-next-line no-param-reassign
                    ref.current = node;
                }
            }
        }
    }, [
        ref
    ]);
    var handleChange = useCallback(function(e) {
        if (clearable) {
            if (e.target.value && !disabled) {
                setIsClearable(true);
            } else {
                setIsClearable(false);
            }
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(e);
    }, [
        clearable,
        disabled,
        onChange
    ]);
    var handleBlur = useCallback(function(e) {
        if (clearable) {
            if (e.target.value) {
                setIsClearable(true);
            } else {
                setIsClearable(false);
            }
        }
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
    }, [
        clearable,
        onBlur
    ]);
    var onClear = useCallback(function(e) {
        _onClear === null || _onClear === void 0 ? void 0 : _onClear();
        e.preventDefault();
        if (clearable && innerRef.current) {
            // eslint-disable-next-line no-param-reassign
            innerRef.current.value = "";
            var syntheticEvent = createSyntheticEvent(innerRef.current, "change");
            if (syntheticEvent) {
                handleChange === null || handleChange === void 0 ? void 0 : handleChange(syntheticEvent);
            }
            onClearCallback === null || onClearCallback === void 0 ? void 0 : onClearCallback();
            // This is a trick to force an update in the input
            setTimeout(function() {
                var ref, ref1;
                innerRef === null || innerRef === void 0 ? void 0 : (ref = innerRef.current) === null || ref === void 0 ? void 0 : ref.focus();
                innerRef === null || innerRef === void 0 ? void 0 : (ref1 = innerRef.current) === null || ref1 === void 0 ? void 0 : ref1.blur();
            }, 1);
        }
    }, [
        _onClear,
        clearable,
        handleChange,
        onClearCallback
    ]);
    var btnProps = useMemo(function() {
        if (!clearable || svg !== "close") {
            return _btnProps;
        }
        return _object_spread_props(_object_spread({}, _btnProps), {
            onClick: onClear
        });
    }, [
        _btnProps,
        clearable,
        onClear,
        svg
    ]);
    return {
        btnProps: btnProps,
        handleBlur: handleBlur,
        handleChange: handleChange,
        isClearable: isClearable,
        refCallback: refCallback,
        svg: svg
    };
};
