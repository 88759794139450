import { useEffect } from "react";
export var useOnClickOutside = function(el, handler) {
    useEffect(function() {
        var listener = function(e) {
            if (!el.contains(e.target) || !e.composedPath().includes(el)) {
                handler();
            }
        };
        if (el) {
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
        }
        return function() {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [
        el,
        handler
    ]);
};
