import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
var DEFAULT_SIZE = {
    default: {
        width: 404
    },
    mobile: {
        width: 269
    },
    tablet: {
        width: 328
    }
};
/**
 * Return the size of slide
 * @param isMobile
 * @param isTablet
 */ var getSlideSize = function(isMobile, isTablet) {
    if (isMobile) return DEFAULT_SIZE.mobile;
    if (isTablet) return DEFAULT_SIZE.tablet || DEFAULT_SIZE.default;
    return DEFAULT_SIZE.default;
};
var updateAllHeight = function(ref) {
    var _Math;
    var pagination = ref.getElementsByClassName("swiper-pagination");
    pagination[0].style.marginBottom = "42px";
    var allSlides = ref.getElementsByClassName("swiper-slide");
    var allHeight = [];
    // eslint-disable-next-line no-plusplus
    for(var i = 0; i < allSlides.length; i++){
        var allCardsHeight = allSlides[i].getElementsByClassName("bottom-container-card-info");
        if (allCardsHeight === null || allCardsHeight === void 0 ? void 0 : allCardsHeight[0]) {
            var _$ref, _$ref1, _$ref2, _$ref3, _$ref4, _$ref5;
            var icon = 50;
            var marginTop = 20;
            var title = (_$ref1 = allCardsHeight === null || allCardsHeight === void 0 ? void 0 : (_$ref = allCardsHeight[0]) === null || _$ref === void 0 ? void 0 : _$ref.getElementsByClassName("card-info-title")) === null || _$ref1 === void 0 ? void 0 : _$ref1[0];
            var description = (_$ref3 = allCardsHeight === null || allCardsHeight === void 0 ? void 0 : (_$ref2 = allCardsHeight[0]) === null || _$ref2 === void 0 ? void 0 : _$ref2.getElementsByTagName("div")) === null || _$ref3 === void 0 ? void 0 : _$ref3[0];
            var button = (_$ref5 = allCardsHeight === null || allCardsHeight === void 0 ? void 0 : (_$ref4 = allCardsHeight[0]) === null || _$ref4 === void 0 ? void 0 : _$ref4.getElementsByTagName("button")) === null || _$ref5 === void 0 ? void 0 : _$ref5[0];
            var height = icon + ((title === null || title === void 0 ? void 0 : title.clientHeight) || 0) + ((description === null || description === void 0 ? void 0 : description.clientHeight) || 0) + ((button === null || button === void 0 ? void 0 : button.clientHeight) + marginTop || 0);
            if (height) {
                allHeight.push(height);
            }
        }
    }
    var maxH = (_Math = Math).max.apply(_Math, _to_consumable_array(allHeight));
    if (maxH) {
        for(var i1 = 0; i1 < allSlides.length; i1 += 1){
            var allCardsHeight1 = allSlides[i1].getElementsByClassName("bottom-container-card-info");
            if (allCardsHeight1 === null || allCardsHeight1 === void 0 ? void 0 : allCardsHeight1[0]) {
                allCardsHeight1[0].style.height = "".concat(maxH, "px");
            }
        }
    }
};
export { getSlideSize, DEFAULT_SIZE, updateAllHeight };
