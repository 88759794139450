import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
var getMaxHeight = function(ref) {
    var _Math;
    var allDocumentItems = ref.getElementsByClassName("document-item") || [];
    var allHeight = [];
    for(var i = 0; i < allDocumentItems.length; i++){
        allHeight.push(allDocumentItems[i].clientHeight);
    }
    return (_Math = Math).max.apply(_Math, _to_consumable_array(allHeight));
};
export { getMaxHeight };
