import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: ",
        ";\n  width: 100%;\n\n  @media (min-width: ",
        ") {\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: flex-end;\n\n  div {\n    margin: 20px 36px 0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  overflow: hidden;\n  margin: 64px 32px 0;\n  background: ",
        ";\n  border: 1px solid ",
        ";\n  border-radius: 20px;\n\n  @media (min-width: ",
        ") {\n    div:first-child {\n      height: inherit;\n    }\n\n    margin: inherit;\n    border: none;\n    border-radius: inherit;\n    width: 147%;\n    transform: translate(-32%);\n  }\n\n  * {\n    margin: 0;\n  }\n\n  .gmnoprint {\n    display: none;\n  }\n\n  del {\n    font-size: 1.5rem;\n    margin-bottom: 2px;\n\n    @media (max-width: ",
        ") {\n      font-size: 1.25rem;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  height: ",
        "px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin-top: 8px;\n\n  div {\n    ",
        ";\n    font-family: ",
        ";\n    font-weight: 400;\n\n    @media (max-width: ",
        ") {\n      ",
        ";\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  max-width: 515px;\n  background: ",
        ";\n  border: 1px solid ",
        ";\n  border-radius: 20px;\n  position: absolute;\n  right: 9%;\n  top: ",
        ";\n  display: flex;\n  flex-direction: column;\n\n  .cta {\n    div {\n      text-decoration: underline;\n      font-family: ",
        ";\n      ",
        ";\n      font-weight: 700;\n      margin: 32px 36px 0;\n    }\n  }\n\n  .title {\n    div {\n      font-family: ",
        ";\n      ",
        ";\n      margin: 0 36px;\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  border-top: 1px solid ",
        ";\n  min-height: 200px;\n  min-width: 500px;\n\n  @media (max-width: ",
        ") {\n    min-width: unset;\n  }\n\n  .bottomTitle {\n    div {\n      font-family: ",
        ";\n      text-transform: uppercase;\n      ",
        ";\n      font-weight: 700;\n      margin: 24px 36px 0;\n    }\n  }\n\n  .subDescTitle {\n    div {\n      font-family: ",
        ";\n      text-transform: uppercase;\n      ",
        ";\n      font-weight: 700;\n      color: ",
        ";\n      margin: 10px 36px 0;\n    }\n  }\n\n  .subDescription {\n    div {\n      font-family: ",
        ";\n      font-weight: 400;\n      ",
        ";\n\n      @media (max-width: ",
        ") {\n        ",
        ";\n      }\n    }\n  }\n\n  .description {\n    div {\n      font-family: ",
        ";\n      ",
        ";\n      margin: 16px 0;\n      font-weight: 400;\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n\n  .promoTitle div {\n    font-weight: 700;\n    ",
        ";\n    font-family: ",
        ";\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  .cta {\n    div {\n      font-family: ",
        ";\n      text-decoration-line: underline;\n      font-weight: 700;\n      ",
        ";\n    }\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  ",
        ";\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  width: fit-content;\n  text-decoration-line: underline;\n  cursor: pointer;\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n\n  @media (max-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-size: 2.25rem;\n  line-height: unset;\n  margin-left: 4px;\n  font-family: ",
        ";\n\n  @media (max-width: ",
        ") {\n    font-size: 1.875rem;\n  }\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  height: 24px;\n  margin-top: 0;\n  display: flex;\n  align-items: center;\n  ",
        ";\n\n  @media (max-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n  margin-top: 16px;\n  ",
        ";\n  text-decoration: underline;\n  cursor: pointer;\n\n  @media (max-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin-top: 0;\n"
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
function _templateObject16() {
    var data = _tagged_template_literal([
        "\n  .div {\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 400;\n    color: ",
        ";\n    margin: 0;\n    max-width: none;\n  }\n"
    ]);
    _templateObject16 = function _templateObject16() {
        return data;
    };
    return data;
}
function _templateObject17() {
    var data = _tagged_template_literal([
        "\n  .div {\n    margin: 0;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject17 = function _templateObject17() {
        return data;
    };
    return data;
}
function _templateObject18() {
    var data = _tagged_template_literal([
        "\n  .div {\n    margin: 0;\n  }\n"
    ]);
    _templateObject18 = function _templateObject18() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Justify = styled.div.withConfig({
    componentId: "sc-833f8b89-0"
})(_templateObject(), function(param) {
    var bottomPart = param.bottomPart;
    return bottomPart ? "16px 24px 24px" : "16px 24px";
}, breakpoints.desktop, function(param) {
    var bottomPart = param.bottomPart;
    return bottomPart ? "24px 36px 32px" : "32px 36px 24px";
});
export var Prices = styled.div.withConfig({
    componentId: "sc-833f8b89-1"
})(_templateObject1());
export var OfferMapContainer = styled.div.withConfig({
    componentId: "sc-833f8b89-2"
})(_templateObject2(), colors.$ffffff, colors.$bebebe, breakpoints.desktop, breakpoints.tablet);
export var ManageMapHeight = styled.header.withConfig({
    componentId: "sc-833f8b89-3"
})(_templateObject3(), function(p) {
    return p.height ? p.height : 160;
});
export var Title = styled.h3.withConfig({
    componentId: "sc-833f8b89-4"
})(_templateObject4(), pxToCssFont(36, 45), fonts.exbold, breakpoints.tablet, pxToCssFont(24, 30));
export var OfferContainer = styled.div.withConfig({
    componentId: "sc-833f8b89-5"
})(_templateObject5(), colors.$ffffff, colors.$bebebe, function(p) {
    return "".concat(p.top, "px");
}, fonts.regular, pxToCssFont(16, 22), fonts.exbold, pxToCssFont(36, 45));
export var SubCard = styled.div.withConfig({
    componentId: "sc-833f8b89-6"
})(_templateObject6(), colors.$bebebe, breakpoints.tablet, fonts.regular, pxToCssFont(18, 24), fonts.regular, pxToCssFont(18, 24), colors.$7e7e7e, fonts.regular, pxToCssFont(18, 24), breakpoints.tablet, pxToCssFont(16, 22), fonts.regular, pxToCssFont(20, 30));
export var PromoContainer = styled.div.withConfig({
    componentId: "sc-833f8b89-7"
})(_templateObject7(), pxToCssFont(18, 24), fonts.regular);
export var OfferContainerMobile = styled.div.withConfig({
    componentId: "sc-833f8b89-8"
})(_templateObject8(), fonts.regular, pxToCssFont(16, 22));
export var Text = styled.p.withConfig({
    componentId: "sc-833f8b89-9"
})(_templateObject9(), fonts.regular, pxToCssFont(16, 22));
export var ModalText = styled(Text).withConfig({
    componentId: "sc-833f8b89-10"
})(_templateObject10());
export var BigText = styled(Text).withConfig({
    componentId: "sc-833f8b89-11"
})(_templateObject11(), pxToCssFont(18, 24), breakpoints.tablet, pxToCssFont(14, 18));
export var HugeText = styled(Text).withConfig({
    componentId: "sc-833f8b89-12"
})(_templateObject12(), fonts.exbold, breakpoints.tablet);
export var LightText = styled(BigText).withConfig({
    componentId: "sc-833f8b89-13"
})(_templateObject13(), colors.$7e7e7e, pxToCssFont(18, 24), breakpoints.tablet, pxToCssFont(16, 22));
export var BottomText = styled(Text).withConfig({
    componentId: "sc-833f8b89-14"
})(_templateObject14(), pxToCssFont(18, 24), breakpoints.tablet, pxToCssFont(16, 22));
export var ModalDetailTitle = styled.h3.withConfig({
    componentId: "sc-833f8b89-15"
})(_templateObject15(), pxToCssFont(30, 38));
export var ModalDetailText = styled.div.withConfig({
    componentId: "sc-833f8b89-16"
})(_templateObject16(), fonts.regular, pxToCssFont(18, 24), colors.$262626);
export var ModalTitle = styled.div.withConfig({
    componentId: "sc-833f8b89-17"
})(_templateObject17(), pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var ModalContent = styled.div.withConfig({
    componentId: "sc-833f8b89-18"
})(_templateObject18());
