import { useEffect, useState } from "react";
export var ScrollDirection;
(function(ScrollDirection) {
    ScrollDirection["up"] = "up";
    ScrollDirection["down"] = "down";
})(ScrollDirection || (ScrollDirection = {}));
export var useScrollDirection = function() {
    var threshold = 100;
    var ref = useState(ScrollDirection.up), scrollDir = ref[0], setScrollDir = ref[1];
    useEffect(function() {
        var previousScrollYPosition = window.scrollY;
        var scrolledMoreThanThreshold = function(currentScrollYPosition) {
            return Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;
        };
        var isScrollingUp = function(currentScrollYPosition) {
            return currentScrollYPosition > previousScrollYPosition && !(previousScrollYPosition > 0 && currentScrollYPosition === 0) && !(currentScrollYPosition > 0 && previousScrollYPosition === 0);
        };
        var updateScrollDirection = function() {
            var currentScrollYPosition = window.scrollY;
            if (scrolledMoreThanThreshold(currentScrollYPosition)) {
                var newScrollDirection = isScrollingUp(currentScrollYPosition) ? ScrollDirection.down : ScrollDirection.up;
                setScrollDir(newScrollDirection);
                previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
            }
        };
        var onScroll = function() {
            return window.requestAnimationFrame(updateScrollDirection);
        };
        window.addEventListener("scroll", onScroll);
        return function() {
            return window.removeEventListener("scroll", onScroll);
        };
    }, []);
    return scrollDir;
};
