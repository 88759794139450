import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: ",
        ";\n  border-bottom: 1px solid #cccccc80;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: 63px;\n  display: flex;\n  align-items: center;\n  padding-left: 80px;\n  img {\n    height: 63px !important;\n  }\n  @media (max-width: ",
        ") {\n    height: 56px;\n    padding-left: 16px;\n    img {\n      height: 56px !important;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { breakpoints, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var MainContainer = styled.header.withConfig({
    componentId: "sc-e4ec7c46-0"
})(_templateObject(), pxToRem(72));
export var LogoWrapper = styled.div.withConfig({
    componentId: "sc-e4ec7c46-1"
})(_templateObject1(), breakpoints.desktop);
