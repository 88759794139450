import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: inherit;\n\n  @media (min-width: ",
        ") {\n    margin: 0 auto;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: ",
        ";\n  background-color: ",
        ";\n  margin: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin-top: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-top: ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  color: ",
        ";\n  ",
        "\n  margin-bottom: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-bottom: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  color: ",
        ";\n  ",
        "\n  margin-bottom: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: ",
        ";\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n\n  div {\n    width: 100%;\n\n    @media (min-width: ",
        ") {\n      width: ",
        ";\n    }\n\n    @media (min-width: ",
        ") {\n      width: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-7df8fd7f-0"
})(_templateObject(), breakpoints.tablet);
export var Divider = styled.div.withConfig({
    componentId: "sc-7df8fd7f-1"
})(_templateObject1(), pxToRem(1), colors.$bebebe, pxToRem(36, 0, 32), breakpoints.desktop, pxToRem(45, 0, 40));
export var DetailButton = styled.div.withConfig({
    componentId: "sc-7df8fd7f-2"
})(_templateObject2(), pxToRem(24), breakpoints.tablet, pxToRem(32));
export var DetailTitle = styled.div.withConfig({
    componentId: "sc-7df8fd7f-3"
})(_templateObject3(), fonts.exbold, colors.$262626, pxToCssFont(30, 38), pxToRem(32), breakpoints.tablet, pxToRem(24), breakpoints.desktop, pxToCssFont(36, 45), pxToRem(32));
export var DetailDesc = styled.div.withConfig({
    componentId: "sc-7df8fd7f-4"
})(_templateObject4(), fonts.regular, colors.$262626, pxToCssFont(20, 26), pxToRem(32), breakpoints.tablet, pxToCssFont(18, 24), pxToRem(60));
export var ButtonDiv = styled.div.withConfig({
    componentId: "sc-7df8fd7f-5"
})(_templateObject5(), breakpoints.tablet, pxToRem(258), breakpoints.desktop, pxToRem(296));
