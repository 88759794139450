import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    display: flex;\n    width: 100%;\n    border-radius: 10px;\n    margin: ",
        "; \n    border: ",
        ";\n    cursor: pointer;\n    position: relative;\n\n    &:hover {\n        margin: 1px;\n        border: ",
        "\n    }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: ",
        ";\n    border-radius: 10px;\n    opacity: 0.6;\n    z-index: 1;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { colors } from "@vfit/shared/themes";
import styled from "styled-components";
export var ClickBoxContainer = styled.div.withConfig({
    componentId: "sc-f792fdc4-0"
})(_templateObject(), function(param) {
    var selected = param.selected;
    return selected ? "1px" : "2px";
}, function(param) {
    var selected = param.selected, color = param.color;
    return selected ? "2px solid ".concat(color) : "1px solid ".concat(colors.$bebebe);
}, function(param) {
    var color = param.color;
    return "2px solid ".concat(color);
});
export var FullCoverContainer = styled.div.withConfig({
    componentId: "sc-f792fdc4-1"
})(_templateObject1(), colors.$ffffff);
