import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 64px 24px;\n  gap: 32px;\n\n  @media (min-width: ",
        ") {\n    flex-flow: row wrap;\n    justify-content: space-between;\n    padding: 80px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n\n    * {\n      margin: 0;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 0;\n  gap: 32px;\n\n  @media (min-width: ",
        ") {\n    display: flex;\n    flex-flow: row wrap;\n    justify-content: center;\n    align-items: center;\n    padding: 0;\n    gap: 0;\n    flex: none;\n    order: 1;\n    flex-grow: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    grid-template-columns: repeat(auto-fill, 405px);\n    justify-content: flex-start;\n    display: grid;\n    gap: 32px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import { breakpoints } from "@vfit/shared/themes";
import styled from "styled-components";
export var DocumentsContainer = styled.div.withConfig({
    componentId: "sc-26114f40-0"
})(_templateObject(), breakpoints.desktop);
export var CustomTextLabelWrapper = styled.div.withConfig({
    componentId: "sc-26114f40-1"
})(_templateObject1());
export var DocumentsItemsWrapper = styled.div.withConfig({
    componentId: "sc-26114f40-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.desktop);
export var CustomTextDesctopLabelWrapper = styled.div.withConfig({
    componentId: "sc-26114f40-3"
})(_templateObject3());
