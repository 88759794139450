import { LoggerInstance } from "@vfit/shared/providers";
import MUNICIPALITIES from "./italian-municipalities.json";
export function normalizeString(str) {
    return str.trim()// eslint-disable-next-line
    .replace(new RegExp(/[àá]/g), "a'")// eslint-disable-next-line
    .replace(new RegExp(/[èé]/g), "e'")// eslint-disable-next-line
    .replace(new RegExp(/[ìí]/g), "i'")// eslint-disable-next-line
    .replace(new RegExp(/[òó]/g), "o'")// eslint-disable-next-line
    .replace(new RegExp(/[ùú]/g), "u'").toUpperCase();
}
export function searchByNameAndProvince(name, prov) {
    var qNome = normalizeString(name);
    var qProv = prov && normalizeString(prov);
    var results = MUNICIPALITIES.filter(function(c) {
        return qProv ? c[1] === qProv && c[2] === qNome : c[2] === qNome;
    }).map(function(c) {
        return {
            cc: c[0],
            prov: c[1],
            name: c[2],
            active: c[3] === 1
        };
    });
    // One results: no problem!
    if (results.length === 1) {
        return results[0];
    }
    // if many results look for the active one
    results = results.filter(function(c) {
        return c.active;
    });
    if (results.length === 1) return results[0];
    if (prov) throw new Error("Comune with name of ".concat(name, " and prov ").concat(prov, " doesn't exists"));
    else if (name.length === 4 && name.toUpperCase() === name) // eslint-disable-next-line
    return GetByCC(name);
    else throw new Error("Comune with name of ".concat(name, " is found in more than one province. Please specify the province code"));
}
export function searchByCC(cc) {
    var result;
    try {
        // eslint-disable-next-line
        result = GetByCC(cc);
    } catch (error) {
        LoggerInstance.debug("searchByCC error", error);
    }
    if (result !== undefined) {
        return result;
    }
    return null;
}
export function getMunicipality(name, prov, cc) {
    var check = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : true;
    if (check || cc === undefined || prov === undefined) {
        var comune = searchByNameAndProvince(name, prov);
        if (comune === undefined && name.length === 4) {
            comune = searchByCC(name);
        }
        if (comune === undefined) {
            throw new Error("Comune with name ".concat(name, " doesn't exist"));
        } else if (cc !== undefined && (comune === null || comune === void 0 ? void 0 : comune.cc) !== cc) {
            throw new Error("Comune with cc ".concat(cc, " doesn't exist"));
        } else {
            return {
                name: name,
                prov: prov,
                cc: cc
            };
        }
    } else {
        return {
            name: name,
            prov: prov,
            cc: cc
        };
    }
}
export function GetByCC(municipality) {
    var result;
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        // eslint-disable-next-line
        for(var _iterator = MUNICIPALITIES[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var item = _step.value;
            if (item.code === municipality) {
                result = item;
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    if (result !== undefined) {
        return getMunicipality(result.municipality, result.province, result.code, false);
    }
    throw new Error("Comune with cc ".concat(municipality, " doesn't exist"));
}
