import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 350px;\n  width: 269px;\n  display: inline-flex;\n  flex-direction: column;\n  flex-shrink: 0;\n  background-position: center;\n  border-radius: 15px;\n  transform: translateZ(0);\n  margin-right: 24px;\n  white-space: normal;\n  position: relative;\n  cursor: pointer;\n\n  @media (min-width: ",
        ") {\n    border-radius: 17px;\n    overflow: hidden;\n    height: 320px;\n    width: 100%;\n    max-width: 296px;\n    display: flex;\n    margin-right: 0;\n    margin-bottom: 24px;\n    flex-shrink: 0;\n\n    &:first-child {\n      margin-left: 0;\n    }\n\n    &:last-child {\n      margin: 0;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  border-radius: 18px;\n  z-index: 1;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n\n  img {\n    border-radius: 18px;\n  }\n\n  @media (min-width: ",
        ") {\n    border-radius: 0;\n    transform: translateZ(0) scale(1);\n    transition: transform 0.9s ease-in-out;\n\n    &:hover {\n      transform: scale(1.5);\n      transition: transform 0.9s ease-in-out;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-style: normal;\n  font-weight: 700;\n  ",
        "\n  margin-bottom: 2px;\n\n  @media (min-width: ",
        ") {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    margin-bottom: 8px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  min-height: 44px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin: auto 0 0;\n  backdrop-filter: blur(40px);\n  background-color: rgba(53, 53, 53, 40%);\n  color: white;\n  border-radius: 15px;\n  font-style: normal;\n  font-weight: 400;\n  ",
        "\n  z-index: 2;\n  min-height: 124px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  padding: 24px;\n  @media (min-width: ",
        ") {\n    padding: 20px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  flex-shrink: 0;\n  margin-top: 11px;\n  margin-left: 16px;\n\n  @media (min-width: ",
        ") {\n    margin-top: 16px;\n  }\n\n  img {\n    max-width: ",
        "px;\n    object-fit: contain;\n    max-height: ",
        "px;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, pxToCssFont } from "@vfit/shared/themes";
export var ExperienceCard = styled.div.withConfig({
    componentId: "sc-bad6441b-0"
})(_templateObject(), breakpoints.bigDesktop);
export var ExperienceCardArt = styled.div.withConfig({
    componentId: "sc-bad6441b-1"
})(_templateObject1(), breakpoints.bigDesktop);
export var ExperienceCardTitle = styled.div.withConfig({
    componentId: "sc-bad6441b-2"
})(_templateObject2(), fonts.regular, pxToCssFont(20, 26), breakpoints.bigDesktop, fonts.exbold, pxToCssFont(24, 30));
export var ExperienceCardDesc = styled.div.withConfig({
    componentId: "sc-bad6441b-3"
})(_templateObject3());
export var ExperienceCardFooter = styled.div.withConfig({
    componentId: "sc-bad6441b-4"
})(_templateObject4(), pxToCssFont(16, 22));
export var ExperienceCardInternalFooter = styled.div.withConfig({
    componentId: "sc-bad6441b-5"
})(_templateObject5(), breakpoints.desktop);
export var ExperienceCardFooterImgContainer = styled.div.withConfig({
    componentId: "sc-bad6441b-6"
})(_templateObject6(), breakpoints.bigDesktop, function(param) {
    var maskIconSize = param.maskIconSize;
    return maskIconSize;
}, function(param) {
    var maskIconSize = param.maskIconSize;
    return maskIconSize;
});
