import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  width: ",
        ";\n  height: ",
        ";\n  border-radius: 20px;\n  z-index: 0;\n\n  span {\n    border-radius: 20px;\n  }\n\n  overflow: hidden;\n  cursor: pointer;\n  transition: transform 0.9s ease-in-out;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  width: ",
        ";\n  height: ",
        ";\n  border-radius: 20px;\n  transform: translateZ(0);\n  background: linear-gradient(0deg, rgba(0, 0, 0, 1%), rgba(0, 0, 0, 1%)),\n    linear-gradient(178.32deg, rgba(0, 0, 0, 5%) 9.21%, rgba(0, 0, 0, 0%) 56.26%);\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n\n  span {\n    border-radius: 20px;\n  }\n\n  div {\n    position: relative;\n    width: 100%;\n    height: 100%;\n  }\n\n  div::after {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    content: '';\n    background-image: linear-gradient(0deg, rgba(0, 0, 0, 1%), rgba(0, 0, 0, 1%)),\n      linear-gradient(178.32deg, rgba(0, 0, 0, 5%) 9.21%, rgba(0, 0, 0, 0%) 56.26%);\n  }\n\n  video {\n    object-fit: cover;\n    width: 100%;\n    height: 100%;\n  }\n\n  overflow: hidden;\n  cursor: pointer;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  flex: 0.8;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  padding-left: 32px;\n  padding-top: 40px;\n  max-width: 84%;\n  cursor: pointer;\n\n  h2 {\n    font-family: ",
        ";\n    ",
        "\n    color: #ececec;\n    font-weight: 400;\n    margin-top: 0;\n    margin-bottom: 10px;\n  }\n\n  h3 {\n    font-family: ",
        ";\n    ",
        "\n    color: ",
        ";\n    font-weight: 400;\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n\n  p {\n    font-family: ",
        ";\n    margin-top: 8px;\n    margin-bottom: 0;\n    ",
        "\n    color: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    h3 {\n      ",
        "\n    }\n\n    p {\n      margin-top: 8px;\n      margin-bottom: 0;\n      font-family: ",
        ";\n      ",
        "\n      color: ",
        ";\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    h2 {\n      ",
        "\n    }\n\n    h3 {\n      ",
        "\n    }\n\n    p {\n      font-family: ",
        ";\n      margin-top: 16px;\n      margin-bottom: 0;\n      ",
        "\n      color: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  position: absolute;\n  bottom: 40px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  align-items: end;\n  cursor: pointer;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  max-width: 80%;\n  margin-left: 38px;\n  margin-right: 10px;\n  flex-direction: column;\n  cursor: pointer;\n\n  .title-emotionalCard {\n    margin-bottom: ",
        ";\n    @media (min-width: ",
        ") {\n      margin-bottom: ",
        ";\n    }\n    @media (min-width: ",
        ") {\n      margin-bottom: ",
        ";\n    }\n  }\n\n  h2 {\n    font-family: ",
        ";\n    ",
        "\n    color: #ececec;\n    font-weight: 400;\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n\n  h3 {\n    font-family: ",
        ";\n    ",
        "\n    color: ",
        ";\n    font-weight: 400;\n    margin-top: 10px;\n    margin-bottom: 0;\n  }\n\n  p {\n    margin-top: 8px;\n    margin-bottom: 21px;\n    font-family: ",
        ";\n    ",
        "\n    font-weight: 400;\n    color: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    p {\n      margin-bottom: 24px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    h2 {\n      ",
        "\n    }\n\n    h3 {\n      ",
        "\n    }\n\n    p {\n      margin-top: 16px;\n      margin-bottom: 32px;\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 32px;\n  margin-left: 32px;\n  object-fit: contain;\n  cursor: pointer;\n\n  img {\n    object-fit: cover !important;\n    width: inherit !important;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  text-align: center;\n  margin-left: ",
        ";\n  margin-right: 32px;\n\n  @media (min-width: ",
        ") {\n    margin-left: 32px;\n    margin-right: 32px;\n    text-align: inherit;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  flex: 0.75;\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  min-height: 112px;\n  background: rgba(53, 53, 53, 40%);\n  position: absolute;\n  bottom: 0;\n  border-top-right-radius: 15px;\n  border-top-left-radius: 15px;\n  backdrop-filter: blur(35.0897px);\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  margin: auto;\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  flex: ",
        ";\n  cursor: pointer;\n  align-self: center;\n  margin-bottom: 20px;\n\n  h3 {\n    ",
        "\n    font-family: ",
        ";\n    margin: 20px 0 0 20px;\n    color: ",
        ";\n    font-weight: 700;\n  }\n\n  p {\n    font-family: ",
        ";\n    font-weight: 400;\n    margin: 6px 3px 0 20px;\n    ",
        "\n    color: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    h3 {\n      font-family: ",
        ";\n      font-weight: 400;\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  flex: 0.26;\n  align-self: center;\n  text-align: center;\n\n  img {\n    object-fit: contain;\n  }\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  width: 100%;\n  min-height: 100%;\n  min-width: 100%;\n  overflow: hidden;\n  z-index: -1;\n  object-fit: cover;\n  object-position: center;\n  border-radius: 15px 15px 0 0;\n  position: ",
        ";\n  top: ",
        ";\n  left: ",
        ";\n\n  img {\n    transition: transform 0.9s ease-in-out;\n  }\n\n  &:hover {\n    img {\n      transform: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var SlideCarouselContainerImage = styled.div.withConfig({
    componentId: "sc-3784f97-0"
})(_templateObject(), function(param) {
    var width = param.width;
    return "".concat(width ? "".concat(width, "px") : "100%");
}, function(param) {
    var height = param.height;
    return "".concat(height ? "".concat(height, "px") : "100%");
});
export var SlideCarouselContainerVideo = styled.div.withConfig({
    componentId: "sc-3784f97-1"
})(_templateObject1(), function(param) {
    var width = param.width;
    return "".concat(width ? "".concat(width, "px") : "100%");
}, function(param) {
    var height = param.height;
    return "".concat(height ? "".concat(height, "px") : "100%");
});
export var SlideCarouselNoMaskTop = styled.div.withConfig({
    componentId: "sc-3784f97-2"
})(_templateObject2());
export var SlideCarouselNoMaskTopChild = styled.div.withConfig({
    componentId: "sc-3784f97-3"
})(_templateObject3(), fonts.regular, pxToCssFont(18, 24), fonts.exbold, pxToCssFont(35, 38), colors.$ffffff, fonts.light, pxToCssFont(20, 26), colors.$ffffff, breakpoints.tablet, pxToCssFont(35, 38), fonts.light, pxToCssFont(20, 26), colors.$ffffff, breakpoints.desktop, pxToCssFont(18, 24), pxToCssFont(42, 52), fonts.light, pxToCssFont(24, 30), colors.$ffffff);
export var SlideCarouselNoMaskBottom = styled.div.withConfig({
    componentId: "sc-3784f97-4"
})(_templateObject4());
export var SlideCarouselNoMaskBottomChild = styled.div.withConfig({
    componentId: "sc-3784f97-5"
})(_templateObject5());
export var SlideCarouselNoMaskBottomChildText = styled.div.withConfig({
    componentId: "sc-3784f97-6"
})(_templateObject6(), function(param) {
    var paddingBottom = param.paddingBottom;
    return paddingBottom ? "21px" : 0;
}, breakpoints.tablet, function(param) {
    var paddingBottom = param.paddingBottom;
    return paddingBottom ? "24px" : 0;
}, breakpoints.desktop, function(param) {
    var paddingBottom = param.paddingBottom;
    return paddingBottom ? "32px" : 0;
}, fonts.regular, pxToCssFont(18, 24), fonts.exbold, pxToCssFont(35, 38), colors.$ffffff, fonts.light, pxToCssFont(20, 26), colors.$ffffff, breakpoints.tablet, breakpoints.desktop, pxToCssFont(18, 24), function(param) {
    var coveredText = param.coveredText;
    return !coveredText ? pxToCssFont(42, 52) : pxToCssFont(36, 50);
});
export var SlideCarouselOverlayImage = styled.div.withConfig({
    componentId: "sc-3784f97-7"
})(_templateObject7());
export var SlideCarouselNoMaskBottomButton = styled.div.withConfig({
    componentId: "sc-3784f97-8"
})(_templateObject8(), function(param) {
    var isSmall = param.isSmall;
    return isSmall ? "38px" : "32px";
}, breakpoints.desktop);
export var SlideCarouselMaskTop = styled.div.withConfig({
    componentId: "sc-3784f97-9"
})(_templateObject9());
export var SlideCarouselMaskBottom = styled.div.withConfig({
    componentId: "sc-3784f97-10"
})(_templateObject10());
export var SlideCarouselMaskBottomChild = styled.div.withConfig({
    componentId: "sc-3784f97-11"
})(_templateObject11());
export var SlideCarouselMaskBottomChildText = styled.div.withConfig({
    componentId: "sc-3784f97-12"
})(_templateObject12(), function(param) {
    var withImage = param.withImage;
    return withImage ? 0.74 : "inherit";
}, pxToCssFont(20, 26), fonts.regular, colors.$ffffff, fonts.regular, pxToCssFont(16, 22), colors.$ffffff, breakpoints.desktop, fonts.exbold, pxToCssFont(24, 30));
export var SlideCarouselMaskBottomChildIcon = styled.div.withConfig({
    componentId: "sc-3784f97-13"
})(_templateObject13());
export var DivImgContainer = styled.div.withConfig({
    componentId: "sc-3784f97-14"
})(_templateObject14(), function(props) {
    return props.absolute ? "absolute" : "relative";
}, function(props) {
    return props.absolute ? 0 : "";
}, function(props) {
    return props.absolute ? 0 : "";
}, function(param) {
    var zoomIn = param.zoomIn;
    return zoomIn ? "scale(1.5)" : "inherit";
});
