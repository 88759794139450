import { LoggerInstance } from "@vfit/shared/providers";
import { CHECK_CODE_CHARS, CHECK_CODE_EVEN, CHECK_CODE_ODD, MONTH_CODES, OMOCODIA_TABLE_INVERSE } from "./fiscal-code.constants";
import { GetByCC } from "./municipalities.utils";
export function toNumberIfOmocodia(input) {
    //  Number.isNaN(Number(input)) TODO??
    // eslint-disable-next-line
    if (isNaN(input)) {
        var res = "";
        var tokens = input.split("");
        // eslint-disable-next-line
        for(var i = 0; i < tokens.length; i++){
            var e = tokens[i];
            // eslint-disable-next-line
            res += isNaN(e) ? OMOCODIA_TABLE_INVERSE[e] : e;
        }
        return res;
    }
    return input;
}
export function normalizeString(str) {
    return str.trim()// eslint-disable-next-line prefer-regex-literals
    .replace(new RegExp(/[àá]/g), "a'")// eslint-disable-next-line prefer-regex-literals
    .replace(new RegExp(/[èé]/g), "e'")// eslint-disable-next-line prefer-regex-literals
    .replace(new RegExp(/[ìí]/g), "i'")// eslint-disable-next-line prefer-regex-literals
    .replace(new RegExp(/[òó]/g), "o'")// eslint-disable-next-line prefer-regex-literals
    .replace(new RegExp(/[ùú]/g), "u'").toUpperCase();
}
export function daysInMonth(m, y) {
    switch(m){
        case 1:
            return y % 4 === 0 && y % 100 !== 0 || y % 400 === 0 ? 29 : 28;
        case 8:
        case 3:
        case 5:
        case 10:
            return 30;
        default:
            return 31;
    }
}
export function isValidDate(d, m, y) {
    var month = m - 1;
    return month >= 0 && month < 12 && d > 0 && d <= daysInMonth(month, y);
}
export function getValidDate(d, m, y) {
    if (typeof d === "string" && m === undefined && y === undefined) {
        return new Date(d);
    }
    if (isValidDate(d, m, y)) {
        return new Date(y, m - 1, d, 0, 0, 0, 0);
    }
    throw new Error("The date ".concat(y, "/").concat(m, "/").concat(d, " is not a valid date"));
}
export function extractVowels(str) {
    return str.replace(/[^AEIOU]/gi, "");
}
export function extractConsonants(str) {
    return str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, "");
}
export function dateCode(day, month, year, gender) {
    var yearToReturn = "0".concat(year);
    yearToReturn = yearToReturn.substr(yearToReturn.length - 2, 2);
    var monthToReturn = MONTH_CODES[month - 1];
    var daysToReturn = day;
    if (gender.toUpperCase() === "F") {
        daysToReturn = (+daysToReturn + 40).toString();
    }
    return "".concat(yearToReturn).concat(monthToReturn).concat(daysToReturn);
}
export function getCheckCode(cf) {
    var cFToReturn = cf.toUpperCase();
    var val = 0;
    for(var i = 0; i < 15; i += 1){
        var c = cFToReturn[i];
        val += i % 2 !== 0 ? CHECK_CODE_EVEN[c] : CHECK_CODE_ODD[c];
    }
    val %= 26;
    return CHECK_CODE_CHARS.charAt(val);
}
export function check(fiscalCode) {
    if (typeof fiscalCode !== "string") {
        return false;
    }
    var cf = fiscalCode.toUpperCase();
    if (cf.length !== 16) {
        return false;
    }
    if (!/^[A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1}$/.test(cf)) {
        return false;
    }
    var expectedCheckCode = fiscalCode.charAt(15);
    cf = fiscalCode.slice(0, 15);
    return getCheckCode(cf).toUpperCase() === expectedCheckCode.toUpperCase();
}
export function surnameCode(surname) {
    var codeSurname = "".concat(extractConsonants(surname)).concat(extractVowels(surname), "XXX");
    return codeSurname.substr(0, 3).toUpperCase();
}
export function nameCode(name) {
    var codName = extractConsonants(name);
    if (codName.length >= 4) {
        codName = codName.charAt(0) + codName.charAt(2) + codName.charAt(3);
    } else {
        codName += "".concat(extractVowels(name), "XXX");
        codName = codName.substr(0, 3);
    }
    return codName.toUpperCase();
}
export function reverse(fiscalCode) {
    var name = fiscalCode.substr(3, 3);
    var surname = fiscalCode.substr(0, 3);
    var yearCode = fiscalCode.substr(6, 2);
    yearCode = toNumberIfOmocodia(yearCode);
    var year19XX = parseInt("19".concat(yearCode), 10);
    var year20XX = parseInt("20".concat(yearCode), 10);
    var currentYear20XX = new Date().getFullYear();
    var year = year20XX > currentYear20XX ? year19XX : year20XX;
    var monthChar = fiscalCode.substr(8, 1);
    var month = MONTH_CODES.indexOf(monthChar);
    var gender = "M";
    var dayString = fiscalCode.substr(9, 2);
    dayString = toNumberIfOmocodia(dayString);
    var day = parseInt(dayString, 10);
    if (day > 31) {
        gender = "F";
        day -= 40;
    }
    var birthday = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
    var cc = fiscalCode.substr(11, 4);
    var ccNumbers = toNumberIfOmocodia(cc.substr(1, 3));
    cc = cc.charAt(0) + ccNumbers;
    var birthplace = GetByCC(cc);
    return {
        name: name,
        surname: surname,
        gender: gender,
        day: birthday.getDate(),
        year: birthday.getFullYear(),
        month: birthday.getMonth() + 1,
        birthday: birthday.toISOString().slice(0, 10),
        birthplace: (birthplace === null || birthplace === void 0 ? void 0 : birthplace.name) || "",
        birthplaceProvincia: (birthplace === null || birthplace === void 0 ? void 0 : birthplace.prov) || "",
        cf: fiscalCode
    };
}
export function computeInverse(fiscalCode) {
    if (typeof fiscalCode !== "string") return null;
    var fiscalCodeToReturn = fiscalCode.toUpperCase();
    if (check(fiscalCodeToReturn)) {
        return reverse(fiscalCodeToReturn);
    }
    LoggerInstance.error("Provided input is not a valid Codice Fiscale");
    return null;
}
