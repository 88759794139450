import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 500px;\n  margin-bottom: 20px;\n  position: relative;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") {\n    margin-bottom: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 120px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
// TODO: [refactoring] height
export var CoveredFirstSlide = styled.div.withConfig({
    componentId: "sc-726c2bd1-0"
})(_templateObject());
export var SliderCarouselContainer = styled.div.withConfig({
    componentId: "sc-726c2bd1-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.desktop);
