import { useCallback, useMemo, useState } from "react";
import { AppMobile } from "@vfit/shared/data-access";
export var useCta = function(param) {
    var _href = param._href, _onClick = param._onClick, _onMouseEnter = param._onMouseEnter, _onMouseLeave = param._onMouseLeave, disabled = param.disabled, disableAppMobileOpenInBrowser = param.disableAppMobileOpenInBrowser;
    var ref = useState(false), isHover = ref[0], setIsHover = ref[1];
    var href = useMemo(function() {
        return _href && !disabled ? _href : undefined;
    }, [
        _href,
        disabled
    ]);
    var isApp = useMemo(function() {
        return AppMobile.checkIsApp();
    }, []);
    var toggleIsHover = useCallback(function() {
        setIsHover(function(prev) {
            return !prev;
        });
    }, []);
    var onClick = useCallback(function(e) {
        if (disabled || _href === "") {
            return;
        }
        if (isApp && href && !disableAppMobileOpenInBrowser) {
            e.preventDefault();
            AppMobile.openInBrowser(href);
        }
        _onClick === null || _onClick === void 0 ? void 0 : _onClick(e);
    }, [
        _onClick,
        disableAppMobileOpenInBrowser,
        disabled,
        href,
        isApp
    ]);
    var onMouseEnter = useCallback(function(e) {
        toggleIsHover();
        _onMouseEnter === null || _onMouseEnter === void 0 ? void 0 : _onMouseEnter(e);
    }, [
        _onMouseEnter,
        toggleIsHover
    ]);
    var onMouseLeave = useCallback(function(e) {
        toggleIsHover();
        _onMouseLeave === null || _onMouseLeave === void 0 ? void 0 : _onMouseLeave(e);
    }, [
        _onMouseLeave,
        toggleIsHover
    ]);
    return {
        href: href,
        isHover: isHover,
        onClick: onClick,
        onMouseEnter: onMouseEnter,
        onMouseLeave: onMouseLeave,
        toggleIsHover: toggleIsHover
    };
};
