import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 380px;\n  overflow: hidden;\n  position: relative;\n\n  iframe {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n  }\n\n  @media (min-width: ",
        ") {\n    height: 380px;\n    margin-left: 100px;\n    margin-right: 100px;\n  }\n\n  @media (min-width: ",
        ") {\n    height: 620px;\n    margin-left: 150px;\n    margin-right: 150px;\n\n    iframe {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      left: 0;\n      position: absolute;\n      top: 0;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 700;\n  color: ",
        ";\n\n  img {\n    width: 18px;\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      margin-left: 81px;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin-bottom: 32px;\n\n    p {\n      margin-block: 0;\n    }\n\n    @media (min-width: ",
        ") {\n      margin-bottom: 48px;\n    }\n\n    @media (min-width: ",
        ") {\n      margin-top: 30px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts } from "@vfit/shared/themes";
export var VideoContainer = styled.div.withConfig({
    componentId: "sc-b99d9556-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-b99d9556-1"
})(_templateObject1(), fonts.regular, colors.$7e7e7e, breakpoints.desktop);
export var Title = styled.div.withConfig({
    componentId: "sc-b99d9556-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.desktop);
