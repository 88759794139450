export var SECONDS_TIMING_ANIMATION = 1.2;
export var ICardDirectionType;
(function(ICardDirectionType) {
    ICardDirectionType[ICardDirectionType["NONE"] = 0] = "NONE";
    ICardDirectionType[ICardDirectionType["PREV"] = 1] = "PREV";
    ICardDirectionType[ICardDirectionType["NEXT"] = 2] = "NEXT";
})(ICardDirectionType || (ICardDirectionType = {}));
export var ICardStyleType;
(function(ICardStyleType) {
    ICardStyleType[ICardStyleType["HIDDEN"] = 0] = "HIDDEN";
    ICardStyleType[ICardStyleType["FIRST"] = 1] = "FIRST";
    ICardStyleType[ICardStyleType["SECOND"] = 2] = "SECOND";
    ICardStyleType[ICardStyleType["THIRD"] = 3] = "THIRD";
})(ICardStyleType || (ICardStyleType = {}));
