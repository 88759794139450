export var Path;
(function(Path) {
    Path["URL_BACK"] = "mode=urlback";
    Path["URL_FORWARD"] = "mode=urldoneonbillcapture";
})(Path || (Path = {}));
export var FLOW_TYPE_DYNAMIC_SHOPPING_CART;
(function(FLOW_TYPE_DYNAMIC_SHOPPING_CART) {
    FLOW_TYPE_DYNAMIC_SHOPPING_CART["SHOPPING_CART_FIXED"] = "1";
    FLOW_TYPE_DYNAMIC_SHOPPING_CART["SHOPPING_CART_MOBILE"] = "2";
})(FLOW_TYPE_DYNAMIC_SHOPPING_CART || (FLOW_TYPE_DYNAMIC_SHOPPING_CART = {}));
export var ERROR_CODES_DYNAMIC_SHOPPING_CART;
(function(ERROR_CODES_DYNAMIC_SHOPPING_CART) {
    ERROR_CODES_DYNAMIC_SHOPPING_CART[ERROR_CODES_DYNAMIC_SHOPPING_CART["KO"] = 0] = "KO";
    ERROR_CODES_DYNAMIC_SHOPPING_CART[ERROR_CODES_DYNAMIC_SHOPPING_CART["FWA"] = 1] = "FWA";
    ERROR_CODES_DYNAMIC_SHOPPING_CART[ERROR_CODES_DYNAMIC_SHOPPING_CART["VALIDATE_ADDRESS_ERROR"] = 2] = "VALIDATE_ADDRESS_ERROR";
    ERROR_CODES_DYNAMIC_SHOPPING_CART[ERROR_CODES_DYNAMIC_SHOPPING_CART["CREATED_ADDRESS_ERROR"] = 3] = "CREATED_ADDRESS_ERROR";
    ERROR_CODES_DYNAMIC_SHOPPING_CART[ERROR_CODES_DYNAMIC_SHOPPING_CART["SERVICE_ABILITY_ERROR"] = 4] = "SERVICE_ABILITY_ERROR";
    ERROR_CODES_DYNAMIC_SHOPPING_CART[ERROR_CODES_DYNAMIC_SHOPPING_CART["ERROR_DXL_CAMPAIGNS"] = 5] = "ERROR_DXL_CAMPAIGNS";
})(ERROR_CODES_DYNAMIC_SHOPPING_CART || (ERROR_CODES_DYNAMIC_SHOPPING_CART = {}));
