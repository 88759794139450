import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  gap: 8px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: inline-block;\n  text-align: center;\n  font-family: ",
        ";\n  ",
        "\n  height: 30px;\n  width: 24px;\n  border-top: none;\n  border-left: none;\n  border-right: none;\n  border-bottom: solid 1px;\n  border-color: ",
        ";\n  padding: 0;\n\n  &[value=''] {\n    border-color: ",
        ";\n  }\n\n  :focus {\n    outline: none;\n    border-color: ",
        ";\n  }\n\n  :disabled {\n    background-color: transparent;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  animation: fadein 2s;\n  color: ",
        ";\n  ",
        "\n  font-family: ",
        ";\n  text-align: center;\n\n  @keyframes fadein {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var InputContainer = styled.div.withConfig({
    componentId: "sc-36864afb-0"
})(_templateObject());
export var Input = styled.input.withConfig({
    componentId: "sc-36864afb-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(24, 28), colors.$262626, colors.$bebebe, colors.$262626);
export var ErrorMessage = styled.p.withConfig({
    componentId: "sc-36864afb-2"
})(_templateObject2(), colors.$e60000, pxToCssFont(14, 0), fonts.regular);
