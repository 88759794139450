import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin-bottom: -16px;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: -24px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: -32px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  margin-bottom: 16px;\n\n  :last-child {\n    margin-bottom: 0;\n  }\n\n  @media (min-width: ",
        ") {\n    width: calc(50% - 12px);\n    margin-bottom: 24px;\n\n    :nth-last-child(2) {\n      margin-bottom: 0;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    width: calc(50% - 16px);\n    margin-bottom: 32px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var Parent = styled.div.withConfig({
    componentId: "sc-19fa94c7-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var CheckboxDiv = styled.div.withConfig({
    componentId: "sc-19fa94c7-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.desktop);
