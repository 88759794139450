import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background-color: #000;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  width: 100%;\n  min-height: 100%;\n  min-width: 100%;\n  overflow: hidden;\n  z-index: -1;\n  object-fit: cover;\n  object-position: center;\n  position: absolute;\n  filter: blur(60px);\n  top: 0;\n  left: 0;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  overflow-y: scroll;\n  -ms-overflow-style: none; /* for Internet Explorer, Edge */\n  scrollbar-width: none;\n\n  ::-webkit-scrollbar {\n    display: none; /* for Chrome, Safari, and Opera */\n  }\n\n  animation: ",
        ";\n  height: calc(\n    100vh - ",
        "\n  );\n  align-items: center;\n  justify-content: center;\n  display: flex;\n\n  @keyframes slide-up {\n    from {\n      padding-top: 800px;\n    }\n\n    to {\n      padding-top: 0;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  height: 100%;\n  width: 100%;\n  position: relative;\n  z-index: 0;\n\n  @media (min-width: ",
        ") {\n    width: 70%;\n    zoom: 0.85;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 70%;\n    zoom: 0.75;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  transition: all 400ms ease-in;\n  position: absolute;\n  list-style: none;\n  left: 0;\n  right: 0;\n  margin: 0 auto;\n  text-align: center;\n  background: ",
        ";\n  background-size: cover;\n  border-radius: 20px;\n  animation: ",
        ";\n  top: ",
        ";\n  width: ",
        ";\n  min-height: ",
        ";\n  opacity: ",
        ";\n  z-index: ",
        ";\n\n  &:last-child::after {\n    content: ' ';\n    display: block;\n    background: transparent;\n    height: 1px;\n    width: 100%;\n    position: absolute;\n    bottom: -85px;\n  }\n\n  @keyframes scale-up {\n    from {\n      opacity: 0;\n      transform: translate3d(-57px, 15px, 0) rotate(-4deg);\n    }\n\n    to {\n      opacity: 1;\n      transform: translate3d(0, 0, 0) rotate(0deg);\n    }\n  }\n\n  @keyframes scale-down {\n    from {\n      opacity: 1;\n      transform: translate3d(0, 0, 0) rotate(0deg);\n    }\n\n    to {\n      opacity: 0;\n      transform: translate3d(-57px, 15px, 0) rotate(-4deg);\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  padding: 65px 32px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  gap: 60px;\n  min-height: ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  z-index: 2;\n  position: fixed;\n  margin-top: 27px;\n  margin-left: 13px;\n  display: flex;\n  align-items: center;\n\n  div {\n    transform: rotate(180deg);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    svg {\n      width: 9px;\n      height: 16px;\n    }\n  }\n\n  span {\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: white;\n    margin-left: 15px;\n    z-index: -1;\n  }\n\n  @media (min-width: ",
        ") {\n    cursor: pointer;\n    margin-top: 37px;\n    margin-left: 50px;\n    padding-left: 0;\n\n    span {\n      ",
        "\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    z-index: 2;\n    margin-left: 98px;\n    padding-left: 0;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFontSize } from "@vfit/shared/themes";
import { SECONDS_TIMING_ANIMATION } from "@vfit/shared/models";
export var DefaultBackStyle = {
    color: "white",
    visibility: "visible",
    cursor: "pointer"
};
export var IntersectedBackStyle = {
    color: "black",
    visibility: "hidden",
    cursor: "none"
};
export var StepperCardsBlurred = styled.div.withConfig({
    componentId: "sc-a72487d0-0"
})(_templateObject());
export var DivImgContainer = styled.div.withConfig({
    componentId: "sc-a72487d0-1"
})(_templateObject1());
export var StepperCardsContainer = styled.div.withConfig({
    componentId: "sc-a72487d0-2"
})(_templateObject2(), function() {
    return "".concat(SECONDS_TIMING_ANIMATION, "s slide-up");
}, function(param) {
    var footerHeight = param.footerHeight;
    return footerHeight !== undefined ? "".concat(footerHeight, "px") : "20px";
});
export var StepperCardListContainer = styled.div.withConfig({
    componentId: "sc-a72487d0-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.desktop);
export var StepperCardList = styled.ul.withConfig({
    componentId: "sc-a72487d0-4"
})(_templateObject4());
export var StepperCardListItem = styled.li.withConfig({
    componentId: "sc-a72487d0-5"
})(_templateObject5(), colors.$ffffff, function(param) {
    var transformThis = param.transformThis, transformPrev = param.transformPrev;
    if (!transformPrev && !transformThis) return "inherit";
    if (transformThis) return "scale-down 500ms";
    return "scale-up 500ms";
}, function(param) {
    var top = param.top;
    return "".concat(top, "px");
}, function(param) {
    var width = param.width;
    return "".concat(width, "px");
}, function(param) {
    var height = param.height;
    return "".concat(height, "px");
}, function(param) {
    var opacity = param.opacity;
    return "".concat(opacity);
}, function(param) {
    var zIndex = param.zIndex;
    return "".concat(zIndex);
});
export var Skeleton = styled.div.withConfig({
    componentId: "sc-a72487d0-6"
})(_templateObject6(), function(param) {
    var height = param.height;
    return "".concat(height, "px");
});
export var StepperCardNavigation = styled.div.withConfig({
    componentId: "sc-a72487d0-7"
})(_templateObject7(), fonts.regular, pxToCssFontSize(14), breakpoints.tablet, pxToCssFontSize(16), breakpoints.desktop);
