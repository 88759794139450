import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  left: 0;\n  overflow: hidden;\n  top: 0;\n  width: 100%;\n  z-index: 1;\n  ",
        "\n  ",
        "\n  opacity: ",
        ";\n  animation: ",
        " 0.5s ease-in forwards\n    ",
        ";\n\n  @keyframes slide-left-to-right {\n    from {\n      transform: translateX(-100%);\n    }\n\n    to {\n      transform: translateX(0);\n    }\n  }\n\n  @keyframes slide-down {\n    from {\n      transform: translateY(-100%);\n    }\n\n    to {\n      transform: translateY(0);\n    }\n  }\n\n  @keyframes slide-up {\n    from {\n      transform: translateY(100%);\n      opacity: 0;\n    }\n\n    to {\n      transform: translateY(0);\n      opacity: 1;\n    }\n  }\n\n  @keyframes fade-in {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var HTMLWrapper = styled.div.withConfig({
    componentId: "sc-3163380e-0"
})(_templateObject());
export var BannerStyle = styled.section.withConfig({
    componentId: "sc-3163380e-1"
})(_templateObject1(), function(p) {
    return p.animationType === "slide-left-to-right" ? "transform: translateX(-100%);" : "";
}, function(p) {
    return p.animationType === "slide-down" ? "transform: translateY(-100%);" : "";
}, function(p) {
    return p.animationType === "fade-in" ? 0 : 1;
}, function(p) {
    return p.animationType;
}, function(p) {
    return p.delay ? "".concat(p.delay, "s") : "";
});
