import { checkWindow } from "@vfit/shared/data-access";
import { handleEnv } from "@vfit/shared/providers";
var getLivePreviewMedia = function(fileName, folderName) {
    var isInLivePreview = checkWindow() && (localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem("isLP"));
    var appType = handleEnv("NX_APP_TYPE") || "consumer";
    var cmsUrl = handleEnv("NX_CMS");
    if (isInLivePreview && appType && cmsUrl) {
        var baseUrl = new URL(cmsUrl).origin;
        switch(appType){
            case "consumer":
                baseUrl = "".concat(baseUrl, "/content/dam/webaem/vodafone/consumer");
                break;
            case "business-homepage":
                baseUrl = "".concat(baseUrl, "/content/dam/webaem/vodafone/business");
                break;
            case "grandiaziende-homepage":
                baseUrl = "".concat(baseUrl, "/content/dam/webaem/vodafone/grandiaziende");
                break;
            default:
                break;
        }
        var url = "".concat(baseUrl, "/").concat(folderName, "/").concat(fileName);
        if (folderName === "images") {
            return {
                src: url
            };
        }
        return url;
    }
    return false;
};
export { getLivePreviewMedia };
