import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: 3px;\n  background: transparent;\n  border-left: 1px solid ",
        ";\n  border-right: 1px solid ",
        ";\n  margin-top: -3px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  background-color: ",
        ";\n  border-radius: 6px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  z-index: 3;\n  overflow: hidden;\n  position: absolute;\n  top: 0;\n  margin: 0;\n  width: 100%;\n  width: fill-available;\n  background-color: ",
        ";\n  padding: 0;\n  border-left: 1px solid ",
        ";\n  border-right: 1px solid ",
        ";\n  border-bottom: 1px solid ",
        ";\n  border-radius: 0 0 5px 5px;\n\n  > div::-webkit-scrollbar {\n    width: 5px;\n    height: 5px;\n  }\n\n  > div::-webkit-scrollbar-thumb {\n    background: #d8d8d8;\n    border-radius: 10px;\n  }\n\n  > div::-webkit-scrollbar-track {\n    background: ",
        ";\n    border-radius: 10px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import { colors } from "@vfit/shared/themes";
import styled from "styled-components";
export var FillBorder = styled.div.withConfig({
    componentId: "sc-8cd35ff0-0"
})(_templateObject(), colors.$bebebe, colors.$bebebe);
export var SuggestionsWrapper = styled.div.withConfig({
    componentId: "sc-8cd35ff0-1"
})(_templateObject1(), colors.$ffffff);
export var Suggestions = styled.ul.withConfig({
    componentId: "sc-8cd35ff0-2"
})(_templateObject2(), colors.$ffffff, colors.$bebebe, colors.$bebebe, colors.$bebebe, colors.$ffffff);
