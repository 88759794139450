import { useState, useEffect, useLayoutEffect } from "react";
var checkWindow = function() {
    return "object" !== "undefined";
};
// TODO: export in global utils
var useIsomorphicLayoutEffect = checkWindow() ? useLayoutEffect : useEffect;
export var getSystemEnvironment = function() {
    if (checkWindow() && window.localStorage.getItem("isApp") === "true") return "App";
    if (checkWindow() && (typeof window.BWB_JS !== "undefined" || window.location.search.includes("isApp=true"))) {
        window.localStorage.setItem("isApp", "true");
        return "App";
    }
    if (checkWindow()) return "Web";
    return undefined;
};
export function useSystemEnvironment() {
    var ref = useState(), env = ref[0], setEnv = ref[1];
    useIsomorphicLayoutEffect(function() {
        var value = getSystemEnvironment();
        if (value) setEnv(value);
    }, []);
    return env;
}
